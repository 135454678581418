import { RatingTemplatesTypes } from "../../components/funnel/ratingType/RatingTypeTemplates";

export const funnelDataBackend = {
  site_attributes: {
    slug: "puppy-bubbles",
    base_url: "funnel.com",
    display_url: "funnel.com/puppy-bubbles",
    full_url: "https://www.funnel.com/puppy-bubbles",
    logo_url: "https://image.pngaaa.com/708/227708-middle.png",
    logo_present: true,
    seal: `More Reviews For Your Business\n|\nPowered by\n<a href='http://www.gradelocal.com:5000/home' target='_blank' title='More Reviews For Your Business'>\n  <img alt="Grade US Logo" src="http://gradelocal.com:5000/themes/minimal/gu_logo_grey.svg" />\n</a>\n`,
    uuid: "1234-abcd",
    switch_use_captcha: true,
    domain_status: "none",
    domain: null,
    domain_is_shared: false,
    custom_field1: "puppy-bubbles",
    theme_template: RatingTemplatesTypes.STARS,
    custom_css: "",
    logo_width: "156",
    logo_height: "156",
    max_logo_width: 450,
  },
  page_attributes: {
    id: 1,
    uuid: "uuid",
    title: "Puppy Bubbles",
    is_mail_enabled: true,
    is_phone_enabled: true,
    active_review_sites: ["google"],
    share_timezone: "Pacific Time (US & Canada)",
    timezone_options: [
      "Abu Dhabi",
      "Adelaide",
      "Alaska",
      "Almaty",
      "American Samoa",
      "Amsterdam",
      "Arizona",
      "Astana",
      "Athens",
      "Atlantic Time (Canada)",
      "Auckland",
      "Azores",
      "Baghdad",
      "Baku",
      "Bangkok",
      "Beijing",
      "Belgrade",
      "Berlin",
      "Bern",
      "Bogota",
      "Brasilia",
      "Bratislava",
      "Brisbane",
      "Brussels",
      "Bucharest",
      "Budapest",
      "Buenos Aires",
      "Cairo",
      "Canberra",
      "Cape Verde Is.",
      "Caracas",
      "Casablanca",
      "Central America",
      "Central Time (US & Canada)",
      "Chatham Is.",
      "Chennai",
      "Chihuahua",
      "Chongqing",
      "Copenhagen",
      "Darwin",
      "Dhaka",
      "Dublin",
      "Eastern Time (US & Canada)",
      "Edinburgh",
      "Ekaterinburg",
      "Fiji",
      "Georgetown",
      "Greenland",
      "Guadalajara",
      "Guam",
      "Hanoi",
      "Harare",
      "Hawaii",
      "Helsinki",
      "Hobart",
      "Hong Kong",
      "Indiana (East)",
      "International Date Line West",
      "Irkutsk",
      "Islamabad",
      "Istanbul",
      "Jakarta",
      "Jerusalem",
      "Kabul",
      "Kaliningrad",
      "Kamchatka",
      "Karachi",
      "Kathmandu",
      "Kolkata",
      "Krasnoyarsk",
      "Kuala Lumpur",
      "Kuwait",
      "Kyiv",
      "La Paz",
      "Lima",
      "Lisbon",
      "Ljubljana",
      "London",
      "Madrid",
      "Magadan",
      "Marshall Is.",
      "Mazatlan",
      "Melbourne",
      "Mexico City",
      "Mid-Atlantic",
      "Midway Island",
      "Minsk",
      "Monrovia",
      "Monterrey",
      "Montevideo",
      "Moscow",
      "Mountain Time (US & Canada)",
      "Mumbai",
      "Muscat",
      "Nairobi",
      "New Caledonia",
      "New Delhi",
      "Newfoundland",
      "Novosibirsk",
      "Nuku'alofa",
      "Osaka",
      "Pacific Time (US & Canada)",
      "Paris",
      "Perth",
      "Port Moresby",
      "Prague",
      "Pretoria",
      "Quito",
      "Rangoon",
      "Riga",
      "Riyadh",
      "Rome",
      "Samara",
      "Samoa",
      "Santiago",
      "Sapporo",
      "Sarajevo",
      "Saskatchewan",
      "Seoul",
      "Singapore",
      "Skopje",
      "Sofia",
      "Solomon Is.",
      "Srednekolymsk",
      "Sri Jayawardenepura",
      "St. Petersburg",
      "Stockholm",
      "Sydney",
      "Taipei",
      "Tallinn",
      "Tashkent",
      "Tbilisi",
      "Tehran",
      "Tijuana",
      "Tokelau Is.",
      "Tokyo",
      "UTC",
      "Ulaanbaatar",
      "Urumqi",
      "Vienna",
      "Vilnius",
      "Vladivostok",
      "Volgograd",
      "Warsaw",
      "Wellington",
      "West Central Africa",
      "Yakutsk",
      "Yerevan",
      "Zagreb",
      "Zurich",
    ],
  },
  location_attributes: {
    uuid: "uuid",
    address1: "2448 76th Avenue Southeast 108",
    address2: null,
    locality: "Mercer Island",
    region: "WA",
    country: "US",
    postal_code: "98040",
    phone: "+1 206-275-3647",
    email: "contact@puppybubbles.com",
    website: "https://yourpuppybubbles.com/"
  },
  content_modules_attributes: {
    id: 3,
    preface: null,
    body: "<p>Please take a moment to review your experience with us. Your feedback not only helps us, it helps other potential customers.</p>",
    action_prompt: null,
    action_label: null,
    action_body: null,
    action_aside: null,
    feedback_prompt:
      "<p>If you have concerns you wish to address in private, please get in touch.</p>",
    feedback_label: "Contact Us",
    feedback_body:
      "<p>Please take a moment to review your experience with us. Your feedback not only helps us, it helps other potential customers.</p>",
    negative_feedback_body:
      "<p>If you have concerns you wish to address in private, please get in touch.</p>",
    negative_feedback_prompt:
      "<p>Otherwise, click a link below to add a public review.</p>",
    feedback_aside: null,
    offer_prompt:
      "<h2>How does your business compare?</h2><p>Use our free Review Scan to generate an instant reputation report and see how your business appears on local review sites.</p>",
    offer_label: "Scan My Reviews Free",
    offer_body: null,
    offer_aside: null,
    offer_download_file_name: null,
    offer_download_content_type: null,
    offer_download_file_size: null,
    offer_download_updated_at: null,
    choice_0_label: null,
    choice_0_prompt:
      "<p>We strive for 100% customer satisfaction. If we fell short, please tell us more so we can address your concerns.</p>",
    choice_1_label: "You rated 5 out of 5 stars",
    choice_1_prompt:
      "Thank you! We need your help. Would you share your experience on one of these sites?",
    rating_0_label: null,
    rating_1_label: "Not At All",
    rating_2_label: "Not Likely",
    rating_3_label: "Not Likely",
    rating_4_label: "Not Likely",
    rating_5_label: "Not Likely",
    locale: "en",
    ternary_choice_positive_label: null,
    ternary_choice_neutral_label: null,
    ternary_choice_negative_label: null,
    on_submit_feedback_body: null,
    on_submit_action_body: null,
    on_submit_offer_body: null,
    on_submit_review_body: null,
    on_submit_review_alt_body: null,
    review_rating_label: null,
    review_content_label: null,
    review_attribution_label: null,
    review_email_label: null,
    review_phone_label: null,
    index_prompt: null,
    invite_title: null,
    invite_body: null,
    invite_csv: null,
    optin_title: null,
    optin_body: null,
    optin_call_to_action: null,
    recipient_custom_field_invite: "",
    recipient_custom_field_optin: "",
    rating_6_label: "Neutral",
    rating_7_label: "Likely",
    rating_8_label: "Very Likely",
    rating_9_label: "Very Likely",
    rating_10_label: "Extremely Likely",
    business_name_html: "<p><strong>Puppy Bubbles</strong></p>",
    public_review_sites_title_html:
      '<p class="ql-align-center"><strong>Public Review Sites</strong></p>',
    direct_feedback_title_html:
      '<p class="ql-align-center"><strong>Direct Feedback</strong></p>',
  },
  config_modules_attributes: {
    id: 1,
    action_own: true,
    action_template: "social_linkbar",
    action_switch_show: false,
    feedback_own: true,
    feedback_template: "contact",
    feedback_switch_show: true,
    feedback_to: null,
    feedback_cc: null,
    feedback_bcc: null,
    offer_own: true,
    offer_template: "review_scan",
    offer_switch_show: true,
    facebook: null,
    twitter: null,
    template: RatingTemplatesTypes.STARS,
    stream_switch_show: false,
    stream_review_limit: 5,
    intent_threshold: 3,
    stream_include_aggregate_rating: true,
    switch_send_email: false,
    on_submit_review_redirect: "none",
    on_submit_review_redirect_url: null,
    on_submit_review_alt_redirect: "none",
    on_submit_review_alt_redirect_url: null,
    switch_show_review_email: true,
    switch_show_review_phone: true,
    switch_require_review_email: true,
    switch_require_review_phone: false,
    stream_include_reviews: true,
    instagram: null,
    invite_css: null,
    optin_css: null,
    segmenting_threshold: 6.0,
    use_profile_logo_on_invite: false,
    use_custom_field_optin: false,
    use_custom_field_invite: false,
    autofill_and_hide_email: true,
    allow_any_recipient_via_optin: false,
    use_smart_autodirect: false,
    stream_reviewer_last_initial: false,
    use_language_picker_optin: false,
    positive_threshold: null,
    negative_threshold: null,
    show_direct_feedback_phone_input: true,
    show_direct_feedback_email_input: true,
    custom_css_acknowledgement: false,
    button_color: "#006DFF",
  },
  links_attributes: [
    {
      uuid: "1",
      title: "Google",
      url: "http://search.google.com/local/writereview?placeid=ChIJ99agCqM-kFQRNov6Mj8hP-Y&lsig=0",
      category: "google",
      logo_url:
        "http://s3.amazonaws.com/grade.us.dev/images/google/logos/sm.png",
      sort_order: 0,
      switch_show: true,
      hide_funnel_cta: false,
    },
    {
      uuid: "2",
      title: "Facebook",
      url: "http://www.facebook.com/PausAsianKitchen",
      category: "facebook",
      logo_url:
        "http://s3.amazonaws.com/grade.us.dev/images/facebook/logos/sm.png",
      sort_order: 1,
      switch_show: false,
      hide_funnel_cta: false,
    },
    {
      uuid: "3",
      title: "Yelp",
      url: "http://www.yelp.com/biz/zr2w6zfmCp7F_dmmQs-44w",
      category: "yelp",
      logo_url: "http://s3.amazonaws.com/grade.us.dev/images/yelp/logos/sm.png",
      sort_order: 2,
      switch_show: true,
      hide_funnel_cta: false,
    },
    {
      uuid: "5",
      title: "BBB",
      url: "http://www.yelp.com/biz/zr2w6zfmCp7F_dmmQs-44w",
      category: "bbb",
      logo_url: "http://s3.amazonaws.com/grade.us.dev/images/yelp/logos/sm.png",
      sort_order: 3,
      switch_show: false,
      hide_funnel_cta: false,
    },
  ],
};
