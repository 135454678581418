import * as React from "react";
import { FunctionComponent as FC } from "react";
import { RatingTemplatesTypes } from "../RatingTypeTemplates";
import NPSConfirm from "./NPSConfirm";
import ReviewFirstConfirm from "./ReviewFirstConfirm";
import ReviewSitesConfirm from "./ReviewSitesConfirm";
import StarsConfirm from "./StarsConfirm";
import ThumbsConfirm from "./ThumbsConfirm";
export interface IConfirmationProps {
  onConfirm: (data) => void;
  backFxn: () => void;
}
export interface IRatingLabelsProps {
  template: string;
  onConfirm: (labels) => void;
  backFxn: () => void;
}

const RatingLabels: FC<IRatingLabelsProps> = ({
  template,
  onConfirm,
  backFxn,
}) => {
  const renderLabels = () => {
    switch (template) {
      case RatingTemplatesTypes.STARS:
        return (
          <StarsConfirm
            onConfirm={(labels) => onConfirm(labels)}
            backFxn={backFxn}
          />
        );
      case RatingTemplatesTypes.THUMBS:
        return (
          <ThumbsConfirm
            onConfirm={(labels) => onConfirm(labels)}
            backFxn={backFxn}
          />
        );
      case RatingTemplatesTypes.NPS:
        return (
          <NPSConfirm
            onConfirm={(labels) => onConfirm(labels)}
            backFxn={backFxn}
          />
        );
      case RatingTemplatesTypes.REVIEW_FIRST:
        return (
          <ReviewFirstConfirm
            onConfirm={(labels) => onConfirm(labels)}
            backFxn={backFxn}
          />
        );
      case RatingTemplatesTypes.REVIEW_SITES_LARGE:
        return (
          <ReviewSitesConfirm
            onConfirm={(labels) => onConfirm(labels)}
            backFxn={backFxn}
          />
        );
    }
  };
  return <>{renderLabels()}</>;
};

export default RatingLabels;
