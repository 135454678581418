import styled from "styled-components";
import { colors } from "@styles/colors";

interface FunnelPageContainerProps {
  mobileMode?: boolean;
  backgroundColor?: string;
  cssAttributes?: string;
}

export const $FunnelPageContainer = styled.div<FunnelPageContainerProps>`
  font-family: "Open Sans";
  padding: 64px 125px;
  @media (max-width: 600px) {
    padding: 10px;
  }

  ${(props) =>
    props.mobileMode &&
    `
    width: 411px;
    height: 783px;
    margin: 64px auto;
    border: 10px solid ${colors.darkBlue};
    padding: 10px 25px;
    border-radius: 24px;
    overflow-y: scroll;
    background-color: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.theme.colors.baseLightest};
      ${(props) => props.cssAttributes && props.cssAttributes}
  `}
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.baseLightest};
  ${(props) => props.cssAttributes && props.cssAttributes}
`;
