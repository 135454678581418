import styled from "styled-components";
import { colors } from "@styles/colors";

export const $FunnelPageTitle = styled.div<{
  mobileMode?: boolean;
  fontFamily?: string;
  color?: string;
}>`
  text-align: center;
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "Open Sans"};
  margin: auto;
  font-size: ${(props) => (props.mobileMode ? "24px" : "26px")};
  color: ${(props) => (props.color ? props.color : colors.darkBlue)};
`;
