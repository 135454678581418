import styled from "styled-components";

export type ILogoStyledProps = {
  size?: "sm" | "md" | "lg";
};

const getLogoSize = (size) => {
  switch (size) {
    case "md":
      return 36;
    case "sm":
      return 25;
    case "lg":
    default:
      return 48;
  }
};

export const $LogoStyled = styled.img<ILogoStyledProps>`
  cursor: pointer;
  display: flex;
  object-fit: contain;
  height: ${({ size }) => getLogoSize(size)}px;
`;
