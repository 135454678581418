import styled from "styled-components";
import { colors } from "@styles/colors";

type RatingNPSProps = {
  mobileMode: boolean;
  hoverBackgroundColor?: string;
};

export const $RatingNPSButton = styled.div<RatingNPSProps>`
  width: ${(props) => (props.mobileMode ? "30px" : "38px")};
  height: ${(props) => (props.mobileMode ? "30px" : "38px")};

  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${colors.alto};
  font-family: "Open Sans";
  font-size: ${(props) => (props.mobileMode ? "16px" : "24px")};
  color: ${colors.white};
  font-weight: 800;
  text-align: center;
  vertical-align: middle;
  line-height: ${(props) => (props.mobileMode ? "30px" : "38px")};

  &:hover {
    background-color: ${(props) =>
      props.hoverBackgroundColor || colors.brightSun};
  }

  &:not(:last-child) {
    margin-right: ${(props) => (props.mobileMode ? "1px" : "8px")};
  }
  @media only screen and (max-width: 600px) {
    width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 30px;
    &:not(:last-child) {
      margin-right: 1px;
    }
  }
`;
