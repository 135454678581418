import React, { FunctionComponent as FC, useContext } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import FunnelNavButton from "@components/funnel/builder/FunnelNavButton";
import {
  $FunnelNavButtonGroup,
  $ButtonSpacer,
  $FunnelSettingsButton,
} from "../FunnelNav.sc";
import { FunnelPageType } from "@components/funnel/builder/MainContainer";
import P from "@atoms/Typography/Text";
import H from "@atoms/Typography/Header";
import Button from "@traject/reviewspod.molecules.button";
import FunnelNavPanel from "@components/funnel/builder/FunnelNavPanel";
import {
  $DescriptionContainer,
  $FunnelNavContainer,
  $PrimaryNavContainer,
} from "@components/funnel/navigation/PrimaryNav/PrimaryNav.sc";

interface INavProps {
  openNavigation: () => void;
}
const PrimaryFunnelNav: FC<INavProps> = ({ openNavigation }) => {
  const { funnelData } = useContext(FunnelContext);
  const { backend, user_permissions } = funnelData;

  const disableStep3 =
    backend.config_modules_attributes.template == "review" ||
    backend.config_modules_attributes.template == "buttons-lg";
  const disableStep2 =
    backend.config_modules_attributes.template == "buttons-lg";

  return (
    <$PrimaryNavContainer>
      <$FunnelNavContainer>
        <H size="1" margin="115px 0 32px 0" color="baseDarker">
          Review Funnel
        </H>
        <$DescriptionContainer>
          <P color="baseDarker" margin="0 16px 40px 0">
            The Review Funnel is where your customers can leave a rating on the
            landing page, be directed to a public review site, or give you
            direct feedback. Edit each page of these steps below.
          </P>
        </$DescriptionContainer>
        <$FunnelNavButtonGroup>
          <P size="4" color="baseDark" margin="0 0 8px 0" fontWeight={700}>
            STEP 1
          </P>
          <FunnelNavButton type={FunnelPageType.LandingPage} disabled={false} />
          <$ButtonSpacer />
          <P size="4" color="baseDark" margin="0 0 8px 0" fontWeight={700}>
            STEP 2
          </P>
          <FunnelNavPanel
            type={FunnelPageType.PositiveReviewPage}
            disabled={disableStep2}
          />
          <FunnelNavPanel
            type={FunnelPageType.NegativeReviewPage}
            disabled={disableStep2}
          />
          <P size="4" color="baseDark" margin="32px 0 8px 0" fontWeight={700}>
            STEP 3
          </P>
          <FunnelNavButton
            type={FunnelPageType.DirectFeedback}
            disabled={disableStep3}
          />
        </$FunnelNavButtonGroup>
      </$FunnelNavContainer>
      <$FunnelSettingsButton funnel_v3={user_permissions.includes("funnel_v3")}>
        <Button
          onClick={() => openNavigation()}
          color="dark"
          variant="text"
          leftIcon="gearshape"
        >
          Settings
        </Button>
      </$FunnelSettingsButton>
    </$PrimaryNavContainer>
  );
};

export default PrimaryFunnelNav;
