import React, { FunctionComponent as FC, useContext, useEffect } from "react";
import { FunnelPageType } from "@components/funnel/builder/MainContainer";
import {
  $FunnelThresholdColumn,
  $FunnelThresholdContainer,
  $FunnelThresholdGroup,
} from "./FunnelThreshold.sc";
import Radio from "@components/atoms/Radio";
import {
  chooseThresholdLabel,
  handleOnClick,
  loadDefaultValues,
  thresholdChecked,
} from "./utils";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";

export const POSITIVE_THRESHOLD_KEYS = [5, 3, 4, 2]; // 5..2
export const NEGATIVE_THRESHOLD_KEYS = [4, 2, 3, 1]; // 4..1
export const DEFAULT_POSITIVE_THRESHOLD = "4+";
export const DEFAULT_NEGATIVE_THRESHOLD = "3-";
export const DEFAULT_SEGMENTING_THRESHOLD = 8.0;
const THRESHOLD_UPDATES = {
  "5": "4-",
  "4+": "3-",
  "3+": "2-",
  "2+": "1",
};

export interface IStarsFunnelThresholdProps {
  type: FunnelPageType;
  disabled: boolean;
}

const starThresholdName = (type: FunnelPageType) =>
  `${
    type === FunnelPageType.PositiveReviewPage ? "positive" : "negative"
  }StarThreshold`;

const starsThresholdData = (type: FunnelPageType) => {
  const thresholdKeys =
    type === FunnelPageType.PositiveReviewPage
      ? POSITIVE_THRESHOLD_KEYS
      : NEGATIVE_THRESHOLD_KEYS;

  const data = thresholdKeys.map((thresholdKey) => {
    let operator;
    let description;
    if ([5, 1].includes(thresholdKey)) {
      operator = "";
      description = `${thresholdKey === 1 ? "star" : "stars"} only`;
    } else if (type === FunnelPageType.PositiveReviewPage) {
      operator = "+";
      description = "stars or more";
    } else {
      operator = "-";
      description = "stars or less";
    }
    return [
      `${thresholdKey}${operator}`,
      `${thresholdKey} ${description}`,
      `${thresholdKey}`,
    ];
  });
  return data;
};

const StarsFunnelThreshold: FC<IStarsFunnelThresholdProps> = ({
  type,
  disabled,
}) => {
  const thresholdName = starThresholdName(type);
  const { funnelData, updateBackend } = useContext(FunnelContext);

  useEffect(() => {
    loadDefaultValues(
      type,
      funnelData,
      updateBackend,
      DEFAULT_POSITIVE_THRESHOLD,
      DEFAULT_NEGATIVE_THRESHOLD,
      DEFAULT_SEGMENTING_THRESHOLD
    );
  }, []);

  const onClick = (thresholdValue, thresholdKey) => {
    handleOnClick(
      thresholdValue,
      thresholdKey,
      type,
      funnelData,
      updateBackend,
      THRESHOLD_UPDATES,
      2
    );
  };

  return (
    <$FunnelThresholdContainer>
      <div>{chooseThresholdLabel(type)}</div>
      <$FunnelThresholdGroup
        key={`stars-funnel-threshold-group-${type}`}
        disabled={disabled}
      >
        {starsThresholdData(type).map(
          ([thresholdValue, thresholdLabel, thresholdKey]) => (
            <$FunnelThresholdColumn
              key={`stars-funnel-threshold-column-${type}-${thresholdKey}`}
            >
              <Radio
                key={`stars-radio-${type}-${thresholdKey}`}
                testId={`stars-radio-${type}-${thresholdKey}`}
                name={thresholdName}
                size="20"
                labelSize="13"
                value={thresholdValue}
                label={thresholdLabel}
                checked={thresholdChecked(type, funnelData, thresholdValue)}
                onClick={() => onClick(thresholdValue, thresholdKey)}
              />
            </$FunnelThresholdColumn>
          )
        )}
      </$FunnelThresholdGroup>
    </$FunnelThresholdContainer>
  );
};

export default StarsFunnelThreshold;
