import React, { FunctionComponent as FC, useContext, useState } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import P from "@traject/reviewspod.atoms.typography.text";
import Transactional from "@traject/reviewspod.molecules.modals.transactional";
import {
  $Container,
  $TitleContainer,
} from "@components/funnel/settings/MuliLocationConversion/MultiLocationConversion.sc";
import ToggleTip from "@traject/reviewspod.molecules.toggle-tip";
import Button from "@traject/reviewspod.molecules.button";
import useUserAttributes from "@components/funnel/hooks/useUserAttributes";
import useConvertMultiLocation from "@app/javascript/components/funnel/hooks/useConvertToMultiLocation";
import {
  IPageUpdateParams,
  updatePage,
} from "@components/funnel/hooks/usePageUpdate";
import { useMutation } from "react-query";

const MultiLocationConversion: FC = () => {
  const { funnelData } = useContext(FunnelContext);
  const pageTitle = funnelData.backend.page_attributes.title;
  const toggleTipContext = (
    <>
      A Multi-Location profile gives you the ability to create a set of review
      management profiles that mirror the organizational structure of a
      multi-location business.
      <br />
      <br />
      Reasons you may want to create a Multi-Location Profile include:
      <ul>
        <li>
          You manage a franchise and offer profiles for each of your locations.
        </li>
        <li>
          Your business has multiple practitioners, eg- Doctors Office, Dentist,
          Salon, Real-estate.
        </li>
      </ul>
      <br />
      <Button
        size="sm"
        variant="text"
        rightIcon="arrowRight"
        onClick={() =>
          window.open("https://help.grade.us/s/article/Multi-Location-Business")
        }
        className="multi-location-conversion"
      >
        Learn More
      </Button>
    </>
  );
  const [convertModal, setConvertModal] = useState(false);
  const [profilesModal, setProfilesModal] = useState(false);
  const { data, isSuccess } = useUserAttributes();
  const pageMutation = useConvertMultiLocation(
    funnelData.backend.page_attributes.uuid
  );
  const funnelMutation = useMutation((params: IPageUpdateParams) =>
    updatePage(params)
  );

  function checkPermissionsAndPageCount() {
    if (isSuccess) {
      let userHasPermissions = data.permissions.includes("admin" || "owner");

      userHasPermissions && data.page_limit > data.page_count
        ? setConvertModal(true)
        : setProfilesModal(true);
    }
  }

  function confirmProfileConversion() {
    pageMutation.mutate(
      {
        site_uuid: funnelData.backend.site_attributes.uuid,
        page_uuid: funnelData.backend.page_attributes.uuid,
      },
      {
        onSuccess: () => {
          // Remove after MultiLocation Feature work is complete.
          setConvertModal(false);
          funnelMutation.mutate({
            site_uuid: funnelData.backend.site_attributes.uuid,
            page_uuid: funnelData.backend.page_attributes.uuid,
            funnel_data: {
              funnel_v3: false,
            },
          });
          window.location.reload();
        },
      }
    );
  }

  function renderConvertMultiLocationModal() {
    return (
      <Transactional
        isOpen={convertModal}
        size="md"
        titleText="Convert to Multi-location Profile"
        closeFxn={() => setConvertModal(false)}
        primaryText="Continue"
        primaryFxn={() => confirmProfileConversion()}
        withSecondary={true}
        secondaryText="Cancel"
        secondaryFxn={() => setConvertModal(false)}
      >
        <P size="1" color="baseDarkest" margin="0 0 0 8px">
          {pageTitle} will change to a Multi-location profile. This setup will
          allow your customers to choose a location prior to leaving a review,
          and your reporting will be segmented by location.
        </P>
        <Button
          size="sm"
          variant="text"
          onClick={() =>
            window.open(
              "https://help.grade.us/s/article/Multi-Location-Business"
            )
          }
          className="multi-location-conversion"
        >
          Learn More about Multi-Location Profiles
        </Button>
      </Transactional>
    );
  }

  function renderAddProfilesModal() {
    let admin = data.permissions.includes("admin");

    return admin ? (
      <Transactional
        isOpen={profilesModal}
        iconColor="darkError"
        size="md"
        titleText="Add Profiles"
        titleIcon="exclamationPointTriangle"
        closeFxn={() => setProfilesModal(false)}
        primaryText={"Add Profiles"}
        primaryFxn={() =>
          (window.location.href =
            "https://www.grade.us/account/upgrade_account")
        }
        withSecondary={true}
        secondaryText={"No Thanks"}
        secondaryFxn={() => setProfilesModal(false)}
      >
        <P color="baseDark">
          You currently are using {data.page_count} of {data.page_limit} of your
          profiles. Add more profiles to your account before moving forward.
        </P>
      </Transactional>
    ) : (
      <Transactional
        isOpen={profilesModal}
        iconColor="darkError"
        size="md"
        titleText="Add Profiles"
        titleIcon="exclamationPointTriangle"
        closeFxn={() => setProfilesModal(false)}
        primaryText={"Got It"}
        primaryFxn={() => setProfilesModal(false)}
        withSecondary={true}
        secondaryText={"Cancel"}
        secondaryFxn={() => setProfilesModal(false)}
      >
        {" "}
        <P color="baseDark">
          All of your profiles are in use. Please contact your profile provider
          to add more profiles to move forward.
        </P>
      </Transactional>
    );
  }

  return (
    <>
      <$Container className="multi-location-container">
        <$TitleContainer>
          <P size="4" fontWeight={600} color="baseDarker">
            Multi-location Option
          </P>
          <ToggleTip
            icon="exclamationPointCircle"
            iconSize="sm"
            color="baseDark"
            className="review-site-form-toggle-tip"
            tipSize="md"
            scrollRef={funnelData.frontend.leftSectionRef}
          >
            {toggleTipContext}
          </ToggleTip>
        </$TitleContainer>
        <P size="4" fontWeight={400}>
          Do you have more locations for this brand?
        </P>
        <Button
          size="sm"
          variant="text"
          rightIcon="arrowRight"
          onClick={() => checkPermissionsAndPageCount()}
          className="multi-location-conversion"
        >
          Create Multi-location Profile
        </Button>
      </$Container>
      {convertModal && renderConvertMultiLocationModal()}
      {profilesModal && renderAddProfilesModal()}
    </>
  );
};

export default MultiLocationConversion;
