import React, { FunctionComponent as FC, useState, useContext } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";

import P from "@components/atoms/Typography/Text";
import Button from "@traject/reviewspod.molecules.button";
import Input from "@components/molecules/Input";

import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { useMutation } from "react-query";
import {
  IPageUpdateParams,
  updatePage,
} from "@components/funnel/hooks/usePageUpdate";

import SettingCard from "@molecules/SettingCard";

const BusinessName: FC = () => {
  const [editing, setEditing] = useState<boolean>(false);
  const [updatingBusinessName, setUpdatingBusinessName] =
    useState<boolean>(false);
  const alert = useAlert();
  const { funnelData, updateFrontend } = useContext(FunnelContext);

  const { handleSubmit, register, errors } = useForm({
    mode: "onChange",
  });

  const mutation = useMutation((params: IPageUpdateParams) =>
    updatePage(params)
  );

  const submitPageUpdates = async (params) => {
    mutation.mutate(
      {
        site_uuid: funnelData.backend.site_attributes.uuid,
        page_uuid: funnelData.backend.page_attributes.uuid,
        funnel_data: params,
      },
      {
        onSuccess: (responseData) => {
          setEditing(false);
          setUpdatingBusinessName(false);
          funnelData.backend = responseData;
          updateFrontend();
          alert.success(<p>Save was successful</p>);
        },
        onError: (error) => {
          setUpdatingBusinessName(false);
          //@ts-ignore
          const errorMessage = error.response?.data?.errors
            ? //@ts-ignore
              error.response.data.errors.join(", ")
            : "Please try again later.";

          alert.error(
            <>
              <h3>Something went wrong.</h3>
              <p>{errorMessage}</p>
            </>
          );
        },
      }
    );
  };

  return (
    <SettingCard
      onClick={() => setEditing(true)}
      onOutsideClick={() => setEditing(false)}
    >
      <P size="3" color="baseDark" fontWeight={600}>
        BUSINESS NAME
      </P>
      {editing ? (
        <form onSubmit={handleSubmit(submitPageUpdates)}>
          <Input
            name="title"
            register={register({
              required: "Please enter business name.",
            })}
            errors={errors.title}
            defaultValue={funnelData.backend.page_attributes.title}
          />
          <Button
            color="primary"
            type="submit"
            onClick={() => setUpdatingBusinessName(true)}
            leftIcon={updatingBusinessName ? "spinner" : null}
          >
            Save
          </Button>
        </form>
      ) : (
        funnelData.backend.page_attributes.title && (
          <P size="2" color="baseDarkest" margin="8px 0px 0px 0px">
            {funnelData.backend.page_attributes.title}
          </P>
        )
      )}
    </SettingCard>
  );
};

export default BusinessName;
