import React, { FunctionComponent as FC, ReactNode } from "react";
import { $HoverTab } from "./HoverTab.sc";

export interface ITabProps {
  fieldName: string;
  children: ReactNode;
  editMode?: boolean;
  styling?: any;
}

const HoverTab: FC<ITabProps> = ({ fieldName, children, editMode = true, styling = {} }) => {
  return (
    <$HoverTab editMode={editMode} fieldName={fieldName} style={styling}>
      {children}
    </$HoverTab>
  );
};

export default HoverTab;
