import React, {
  FunctionComponent as FC,
  useState,
  useEffect,
  useContext,
} from "react";
import { FunnelContext } from "../FunnelContext";
import EditableRichText from "@components/elements/EditableRichText";
import {
  isEditMode,
  renderTitleRichTextContent,
} from "@utils/funnel";
import { useMutation } from "react-query";
import {
  IPageUpdateParams,
  updatePage,
} from "@components/funnel/hooks/usePageUpdate";
import { useAlert } from "react-alert";

import { $FunnelPageTitle } from "./LandingPageTitle.sc";

const LandingPageTitle: FC = () => {
  const { funnelData, updateFrontend } = useContext(FunnelContext);
  const [updatingTitle, setUpdatingTitle] = useState<boolean>(false);
  const alert = useAlert();
  const {
    site_attributes,
    page_attributes,
    content_modules_attributes,
    config_modules_attributes
  } = funnelData.backend;

  const generateTitleInHtml = (): string => {
    return renderTitleRichTextContent({
      template:
        content_modules_attributes.business_name_html,
      value: page_attributes.title,
    });
  };

  const [titleInHtml, setTitleInHtml] = useState<string>(generateTitleInHtml());

  useEffect(() => {
    setTitleInHtml(generateTitleInHtml());
  }, [
    content_modules_attributes.business_name_html,
    page_attributes.title,
  ]);

  const mutation = useMutation((params: IPageUpdateParams) =>
    updatePage(params)
  );

  const onTitleChange = (html: string) => {
    setUpdatingTitle(true);

    mutation.mutate(
      {
        site_uuid: site_attributes.uuid,
        page_uuid: page_attributes.uuid,
        funnel_data: {
          content_modules_attributes: {
            id: content_modules_attributes.id,
            business_name_html: html
          }
        }
      },
      {
        onSuccess: (responseData) => {
          setUpdatingTitle(false);
          funnelData.backend = responseData;
          updateFrontend();
        },
        onError: (error) => {
          setUpdatingTitle(false);
          //@ts-ignore
          const errorMessage = error.response?.data?.errors ? error.response.data.errors.join(", ") : "Please try again later.";

          alert.error(
            <>
              <h3>Something went wrong.</h3>
              <p>{errorMessage}</p>
            </>
          );
        },
      }
    );
  };

  return (
    <$FunnelPageTitle
      className="landing-page-title"
      color={config_modules_attributes.text_color}
    >
      <EditableRichText
        tooltip="Business Name <br/> can be changed in settings"
        fieldName="Business Name"
        fullWidth={true}
        isEditable={isEditMode(funnelData)}
        text={titleInHtml}
        formatWholeText={true}
        onChange={onTitleChange}
        updating={updatingTitle}
      />
    </$FunnelPageTitle>
  );
};

export default LandingPageTitle;
