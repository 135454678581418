import styled from "styled-components";
import { colors } from "@styles/colors";

type DraggableItemActionIconProps = {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  inactive?: boolean;
};

export const $DraggableItemActionIcon = styled.img<DraggableItemActionIconProps>`
  position: absolute;
  ${(props) => props.top && `top: ${props.top}px`};
  ${(props) => props.left && `left: ${props.left}px`};
  ${(props) => props.right && `right: ${props.right}px`};
  ${(props) => props.bottom && `bottom: ${props.bottom}px`};
  ${(props) => props.inactive === false && `opacity: 0.2`};
`;

type VisibilityProps = {
  inactive?: boolean;
};

export const $ReviewSiteLink = styled.a<VisibilityProps>`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${colors.mineShaft};
  display: grid;
  ${(props) =>
    props.inactive === false &&
    `
    opacity: 0.2;
    pointer-events: none;
  `};

  &:hover {
    text-decoration: none;
    color: ${colors.mineShaft};
  }
`;

export const $ReviewSiteLinkImage = styled.img`
  height: 48px;
  margin-bottom: 6px;
`;

export const $DraggableItemContainer = styled.div<VisibilityProps>`
  width: 100%;
  height: 112px;
  background: ${colors.grayBackground};
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${(props) =>
    props.inactive === false && `background: rgba(230, 236, 240, 0.2);`};
`;
