import React, { FunctionComponent as FC, useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { ChromePicker } from "react-color";
import {
  $ColorInput,
  $ColorInputLabel,
  $ColorPickerContainer,
  $HexColorPicker,
  $HexColorPickerContainer,
} from "@atoms/ColorInput/ColorInput.sc";
import { gusTheme } from "@components/Theme";
import { ThemeProvider } from "styled-components";
import P from "@atoms/Typography/Text";

const hexColorValidation = new RegExp(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/);

export interface IColorPickerProps {
  color: string;
  onColorChange: (color: string) => void;
  size?: "small" | "large";
}

export const ColorPicker: FC<IColorPickerProps> = ({
  onColorChange,
  size = "large",
  color,
}) => {
  const [error, setError] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState(color);
  const [customColorInput, setCustomColorInput] = useState(color);

  useEffect(() => {
    onColorChange(selectedColor);
  }, [selectedColor]);

  useEffect(() => {
    if (hexColorValidation.test(customColorInput)) {
      setSelectedColor(customColorInput);
      setError(false);
    } else {
      setError(true);
    }
  }, [customColorInput]);

  const onCustomColorInput = (e) => {
    setCustomColorInput(e.target.value);
  };

  return (
    <ThemeProvider theme={gusTheme}>
      <div>
        <$ColorPickerContainer>
          <$ColorInput
            backgroundColor={selectedColor}
            onClick={() => setShowPicker(true)}
            name="color-selector"
            size={size}
          />
          {showPicker && (
            <$HexColorPickerContainer>
              <$HexColorPicker onClick={() => {}} />
              <OutsideClickHandler
                onOutsideClick={() => {
                  setShowPicker(false);
                }}
              >
                <ChromePicker
                  style={{ position: "absolute" }}
                  color={selectedColor}
                  onChangeComplete={({ hex }) => {
                    setSelectedColor(hex);
                    setCustomColorInput(hex);
                  }}
                />
              </OutsideClickHandler>
            </$HexColorPickerContainer>
          )}
          <$ColorInputLabel
            type="text"
            value={customColorInput}
            error={error}
            area={size}
            onChange={onCustomColorInput}
          />
        </$ColorPickerContainer>
        {error && (
          <P color="darkWarning" size="3">
            HEX color code is invalid, please try again.
          </P>
        )}
      </div>
    </ThemeProvider>
  );
};

export default ColorPicker;
