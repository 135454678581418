import React, { FunctionComponent as FC, useContext } from "react";
import { AddReviewSiteContext } from "./index";
import { $SearchResultContainer } from "./SearchResult.sc";
import ReviewSiteTile from "./ReviewSiteTile";
import ReviewSiteForm from "./ReviewSiteForm";

export interface ISearchResultProps {
  targetSearchResult: Array<any>;
}

const SearchResult: FC<ISearchResultProps> = ({ targetSearchResult = [] }) => {
  const { activeTarget } = useContext(AddReviewSiteContext);

  return (
    <$SearchResultContainer>
      {activeTarget ? (
        <ReviewSiteForm
          key={`review-site-form-active-${activeTarget.categoryId}`}
          categoryId={activeTarget.categoryId}
          target={activeTarget}
        />
      ) : (
        targetSearchResult.map((target) => {
          const { uuid: targetUuid, categoryId } = target;
          return (
            <ReviewSiteTile
              key={`site-logo-tile-${categoryId}-${targetUuid}`}
              target={target}
            />
          );
        })
      )}
    </$SearchResultContainer>
  );
};

export default SearchResult;
