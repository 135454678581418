import styled from "styled-components";
export const $NewReviewSiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0px;
  gap: 4px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.palette.silver300};
`;

export const $DroppableContainer = styled.div<{
  gapSize?: number;
  sites?: number;
}>`
  width: 100%;
  display: grid;
  grid-gap: ${(props) => `${props.gapSize}px`};
  ${(props) => props.sites <= 2 && "grid-template-columns: 1fr;"}
  ${(props) => props.sites == 4 && "grid-template-columns: 1fr 1fr;"}
  ${(props) => props.sites == 6 && "grid-template-columns: 1fr 1fr;"}
  ${(props) =>
    (props.sites == 5 || props.sites == 3) &&
    "grid-template-columns: 1fr 1fr; div:first-child {grid-column: span 2;}"}
`;

export const $ReviewSiteListWrapper = styled.div`
  width: 100%;
`;
