import styled from "styled-components";

export const $FunnelNavButtonGroup = styled.div`
  margin-top: 48px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 200px;
  margin: 5px;
`;

export const $ButtonSpacer = styled.div`
  height: 32px;
`;

export const $FunnelSettingsButton = styled.div<{ funnel_v3?: boolean }>`
  display: flex;
  align-items: center;
  padding: 24px 0 24px 50px;
  position: sticky;
  bottom: ${({ funnel_v3 }) => (funnel_v3 ? "103px" : "50px")};
  height: 100px;
  background-color: white;
  width: 100%;
  border-top: 1px solid rgba(5, 15, 25, 0.05);
`;
