import styled, { css } from "styled-components";
import { colors } from "@styles/colors";

export const $LandingPageLogo = styled.img<{
  width: string;
  height: string;
  mobile: boolean;
}>`
  height: ${(props) => props.height && props.height}px;
  width: ${(props) => props.width && props.width}px;
  max-width: 100%;
  ${(props) =>
    props.mobile &&
    css`
      height: auto;
    `}
  @media (max-width: 600px) {
    height: auto;
  }
`;

export const $FunnelPageEmptyPlaceholder = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.palette.silver300};
  width: ${(props) => (props.width === undefined ? "100%" : props.width)};
  cursor: pointer;
  padding: 24px;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: ${colors.darkBlue};
`;

export const $DragCorners = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
`;

export const $InnerCorner = styled.div`
  background-color: #d1d9de;
  border-radius: 50%;
  margin: auto;
  height: 12px;
  width: 12px;
`;
