import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { FunnelPageType } from "@components/funnel/builder/MainContainer";
import Collapsible from "@molecules/Collapsible";
import { RatingTemplatesTypes } from "@components/funnel/ratingType/RatingTypeTemplates";
import {$FunnelNavPanelContainer} from "./FunnelNavPanel.sc";
import {NPSFunnelThreshold, StarsFunnelThreshold, ThumbsFunnelThreshold} from "@components/funnel/builder/FunnelThreshold";

export interface IFunnelPanelProps {
  type: FunnelPageType;
  disabled: boolean;
}

type FunnelNavButtonState = {
  title: string;
  description: string;
  iconClass?: string;
  disabled?: boolean;
};

const FunnelNavPanel: FC<IFunnelPanelProps> = ({ type, disabled }) => {
  const [data, setData] = useState<FunnelNavButtonState>(null);
  const [active, setActive] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const { funnelData, updateFrontend } = useContext(FunnelContext);

  const ref = useRef({});

  useEffect(() => setData(getData()), []);

  useEffect(() => {
    const newActive = funnelData.frontend.activeFunnelPage == type && "primary2";
    const newOpen = newActive === 'primary2';
    setOpen(newOpen);
    setActive(newActive);
    // @ts-ignore
    if (ref.current.openCollapsible) ref.current?.openCollapsible(newOpen);
  }, [funnelData.frontend.activeFunnelPage])

  const getData = (): FunnelNavButtonState => {
    switch (type) {
      case FunnelPageType.PositiveReviewPage:
        return {
          iconClass: "happyFace",
          title: "Positive Reviews Page",
          description: "Response to a positive review.",
        };
      case FunnelPageType.NegativeReviewPage:
        return {
          iconClass: "sadFace",
          title: "Negative Reviews Page",
          description: "Response to a negative review.",
        };
    }
  };

  const renderFunnelThreshold = () => {
    switch (funnelData.backend.config_modules_attributes.template) {
      case RatingTemplatesTypes.STARS:
        return <StarsFunnelThreshold type={type} disabled={disabled} />
      case RatingTemplatesTypes.THUMBS:
        return <ThumbsFunnelThreshold type={type} disabled={disabled} />
      case RatingTemplatesTypes.NPS:
        return <NPSFunnelThreshold type={type} disabled={disabled} />
      default:
        return null;
    }
  }

  const onClick = () => {
    if (funnelData.frontend.activeFunnelPage == type) return;

    funnelData.frontend.activeFunnelPage = type;
    updateFrontend();
  };

  return data ? (
    <$FunnelNavPanelContainer>
      <Collapsible
        ref={ref}
        active={active}
        open={open}
        title={data.title}
        description={data.description}
        iconClass={data.iconClass}
        disabled={disabled}
        onClick={disabled ? null : onClick}
      >
      { renderFunnelThreshold() }
    </Collapsible>
    </$FunnelNavPanelContainer>
  ) : null;
};

export default FunnelNavPanel;
