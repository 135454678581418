import React, { FunctionComponent as FC, useContext, useState } from "react";
import { FunnelContext } from "../../builder/FunnelContext";

import { FunnelRatingContainer } from "@styles/components/funnel/Builder";
import { IRatingTypeProps } from "../RatingTypeTemplates";
import { $RatingNPSButton } from "./NPS.sc";
import { $DescriptionText } from "../RatingTypeDescription.sc";

const RatingTypeNPS: FC<IRatingTypeProps> = ({
  handleRatingClick,
}) => {
  const { funnelData } = useContext(FunnelContext);
  const configModules = funnelData.backend.config_modules_attributes;

  const [hoveredRating, setHoveredRating] = useState<number|null>(null);

  let label = 'Select a Rating'
  if(hoveredRating != null) {
    label = funnelData.backend.content_modules_attributes[`rating_${hoveredRating}_label`]
  }

  const renderNPS = () => {
    let nps = [];

    const sentiment = (rating: number) => {
      const isOver = rating > configModules.segmenting_threshold;
      return isOver ? "positive" : "negative";
    };

    for (let rating = 1; rating <= 10; rating++) {
      const key = `nps_${rating}`;
      const label =
        funnelData.backend.content_modules_attributes[`rating_${rating}_label`];

      nps.push(
        <$RatingNPSButton
          className="nps-button"
          mobileMode={funnelData.frontend.mobileMode}
          hoverBackgroundColor={configModules.button_color}
          data-rating={rating-1}
          data-track-name="funnel_click"
          data-track-key={key}
          data-track-text={label}
          data-track-destination="onsite"
          data-track-context="rating"
          data-track-rating={rating}
          data-track-group="nps"
          data-track-sentiment={sentiment(rating)}
          onClick={handleRatingClick}
          onMouseEnter={() => setHoveredRating(rating)}
          onMouseLeave={() => setHoveredRating(null)}
          key={`funnel-nps-rating-${rating}`}
        >
          {rating}
        </$RatingNPSButton>
      );
    }

    return nps;
  };

  return (
    <>
      <FunnelRatingContainer>{renderNPS()}</FunnelRatingContainer>
      <$DescriptionText>{label}</$DescriptionText>
    </>
  );
};

export default RatingTypeNPS;
