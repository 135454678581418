import styled from "styled-components";

export const $RatingLabelsContainer = styled.div`
  position: relative;
  width: 340px;
  margin: 0px;
  height: 703px;
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-height: 1050px) {
    height: 100%;
  }
`;
export const $BackButtonContainer = styled.div`
  position: absolute;
  top: 13px;
  left: 24px;
`;

export const $LabelText = styled.p`
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors["mineShaft"]};
  margin: 0;
`;

export const $InputWrapper = styled.div`
  margin-bottom: 24px;
`;

export const $RatingTypesTitle = styled.div`
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.colors["darkBlue"]};
  margin: 0px 0px 24px 0px;
`;

export const $RatingTypesBody = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.colors["baseCopy"]};
  margin-bottom: 24px;
`;

export const $RatingTypesFormWrapper = styled.div`
  @media only screen and (max-height: 700px) {
    min-height: 300px;
  }
  @media only screen and (min-height: 750px) {
    min-height: 345px;
  }
  @media only screen and (min-height: 750px) {
    min-height: 345px;
  }
  @media only screen and (min-height: 850px) {
    min-height: 425px;
  }
  @media only screen and (min-height: 930px) {
    min-height: 500px;
  }
`;
export const $StarsConfirmWrapper = styled.div`
  height: 480px;
`;

export const $ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
