import React, {
  FunctionComponent as FC,
  useRef,
  useEffect,
  ReactNode
} from "react";
import { $SettingCard } from "./SettingCard.sc";

const useOutsideClick = (ref, onOutsideClick) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

interface ISettingCard {
  onClick?: any;
  onOutsideClick?: any;
  children: ReactNode;
};

const SettingCard: FC<ISettingCard> = ({
  onClick,
  onOutsideClick,
  children
}) => {
  const wrapperRef = useRef(null);
  onOutsideClick && useOutsideClick(wrapperRef, onOutsideClick);

  return (
    <$SettingCard ref={wrapperRef} onClick={onClick}>
      {children}
    </$SettingCard>
  );
};

export default SettingCard;
