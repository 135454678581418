import React, { FunctionComponent as FC } from "react";
import { $RadioButton, $RadioContainer, $RadioLabel } from "./Radio.sc";

export interface IRadioProps {
  id?: string;
  name?: string;
  value: string;
  checked?: boolean;
  defaultChecked?: boolean;
  label?: string;
  onClick: any;
  disabled?: boolean;
  testId?: string;
  register?: any;
  size?: string;
  labelSize?: string;
  styling?: any;
}

const Radio: FC<IRadioProps> = ({
  id,
  name,
  value,
  checked,
  defaultChecked,
  label,
  onClick,
  disabled,
  testId,
  register,
  size,
  labelSize,
  styling = {},
}) => (
  <$RadioContainer style={styling}>
    <$RadioButton
      id={id}
      area={size && size}
      name={name}
      value={value}
      ref={register}
      data-testid={testId ? testId : "radio-button"}
      checked={disabled ? null : checked}
      defaultChecked={disabled ? null : defaultChecked}
      onClick={disabled ? null : onClick}
    />
    <$RadioLabel htmlFor={value} area={labelSize && labelSize}>
      {label}
    </$RadioLabel>
  </$RadioContainer>
);

export default Radio;
