import React, { FunctionComponent as FC } from "react";
import Logo from "@atoms/Logo";
import { $ReviewSiteLogoContainer } from "./AddReviewSite.sc";

export interface IReviewSiteLogo {
  link: any;
  logoError: any;
  style?: any;
  addLogoError?: (categoryId, targetUuid, logoError) => void;
}

const ReviewSiteLogo: FC<IReviewSiteLogo> = ({
  link,
  logoError,
  style,
  addLogoError,
}) => {
  const { categoryId, uuid: targetUuid, icon_url, logo_url } = link;

  if (!logoError?.icon) {
    return (
      <$ReviewSiteLogoContainer style={style}>
        <Logo
          logoImage={icon_url}
          size="sm"
          testId={`site-logo-${categoryId}-${targetUuid}`}
          alt={`site-logo-${categoryId}-${targetUuid}`}
          onError={() => addLogoError(categoryId, targetUuid, { icon: true })}
        />
      </$ReviewSiteLogoContainer>
    );
  } else if (!logoError?.logo) {
    return (
      <$ReviewSiteLogoContainer style={style}>
        <Logo
          logoImage={logo_url}
          size="sm"
          testId={`site-logo-${categoryId}-${targetUuid}`}
          alt={`site-logo-${categoryId}-${targetUuid}`}
          onError={() =>
            addLogoError(categoryId, targetUuid, { icon: true, logo: true })
          }
        />
      </$ReviewSiteLogoContainer>
    );
  }

  return null;
};

export default ReviewSiteLogo;
