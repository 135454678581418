import HoverTab from "@app/javascript/components/atoms/HoverTab";
import React, { FunctionComponent as FC, ReactNode } from "react";
import ReactTooltip from "react-tooltip";
import { colors } from "@styles/colors";
import {
  $EditableActionsContainer,
  $EditableActionsSpacer,
  $EditableContent,
  $EditableContentContainer,
  $EditableOverlay,
} from "./EditableComponent.sc";
export interface IEditableComponentProps {
  fieldName: string;
  isDisabled?: boolean;
  isInProgress?: boolean;
  isFocused?: boolean;
  topChildren: ReactNode;
  bottomChildren?: ReactNode;
  tooltip?: string;
  fullWidth?: boolean;
}

const EditableComponent: FC<IEditableComponentProps> = ({
  fieldName,
  isDisabled,
  isInProgress,
  isFocused,
  topChildren,
  bottomChildren,
  tooltip,
  fullWidth,
}) => {
  return (
    <HoverTab fieldName={fieldName} editMode={!isDisabled && !isInProgress}>
      <$EditableContentContainer fullWidth={fullWidth}>
        {tooltip !== undefined && !isDisabled && !isInProgress && (
          <ReactTooltip />
        )}

        <$EditableContent
          isFocused={isFocused}
          isDisabled={isDisabled}
          editMode={!isDisabled && !isInProgress}
          fullWidth={fullWidth}
          data-tip={tooltip}
          data-background-color={colors.darkBlue}
          data-arrow-color="transparent"
          data-place="left"
          data-offset="{'top': 100, 'right': 160}"
          data-effect="solid"
          data-multiline={true}
        >
          {topChildren}
          <$EditableOverlay isVisible={isInProgress} />
        </$EditableContent>

        <$EditableActionsContainer>
          <$EditableActionsSpacer />
          {bottomChildren}
        </$EditableActionsContainer>
      </$EditableContentContainer>
    </HoverTab>
  );
};

export default EditableComponent;
