import styled from "styled-components";
import { colors } from "@styles/colors";
import thumbUp from "../../../../assets/icons/thumb_up.svg";
import thumbDown from "../../../../assets/icons/thumb_down.svg";

export const $RatingThumbButton = styled.div`
  width: 80px;
  height: 80px;
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
`;

export const $RatingThumbButtonUp = styled($RatingThumbButton)`
  background-color: ${(props) => props.theme.colors.palette.green500};
  background-image: URL("${thumbUp}");
  margin-left: 60px;
  
  &:hover {
    background-color: ${(props) => props.theme.colors.palette.green700};
  }
`;

export const $RatingThumbButtonDown = styled($RatingThumbButton)`
  background-color: ${(props) => props.theme.colors.palette.red500};
  background-image: URL("${thumbDown}");
  
  &:hover {
    background-color: ${(props) => props.theme.colors.palette.red700};
  }
`;
