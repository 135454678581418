import Icon from "@atoms/Icon";
import P from "@atoms/Typography/Text";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { $NewReviewSiteContainer } from "@components/funnel/builder/ReviewSiteList/ReviewSiteList.sc";
import React, { useContext } from "react";

const ReviewSiteEmptyState = () => {
  const { funnelData } = useContext(FunnelContext);

  function openReviewSiteNavigation() {
    funnelData.frontend.openSettings = true;
    funnelData.frontend.settingsTab = 2;
  }

  return (
    <$NewReviewSiteContainer onClick={() => openReviewSiteNavigation()}>
      <Icon icon="siteArrow" size={42} color="baseDark" />
      <P size="2" fontWeight={600} margin="8px 0 4px 0">
        Add your public review site
      </P>
      <P size="3">You need to add a public review site</P>
    </$NewReviewSiteContainer>
  );
};

export default ReviewSiteEmptyState;
