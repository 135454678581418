import React, {
  FunctionComponent as FC,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useAlert } from "react-alert";
import { useMutation } from "react-query";
import { FunnelContext } from "../FunnelContext";
import {
  ImutationParams,
  updateSite,
} from "@components/funnel/hooks/useSiteUpdate";
import { isEditMode } from "@utils/funnel";
import OutsideClickHandler from "react-outside-click-handler";
import {
  $LandingPageLogo,
  $FunnelPageEmptyPlaceholder,
  $DragCorners,
  $InnerCorner,
} from "./ProfileLogo.sc";
import EditableActionsComponent from "@components/elements/EditableActionsComponent";

import P from "@atoms/Typography/Text";
import { Resizable } from "re-resizable";
import Icon from "@atoms/Icon";
const ProfileLogo: FC = () => {
  const alert = useAlert();
  const mutation = useMutation((params: ImutationParams) => updateSite(params));
  const { funnelData, updateFrontend } = useContext(FunnelContext);
  const [updatingLogo, setUpdatingLogo] = useState<boolean>(false);
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(
    !funnelData.backend.site_attributes.logo_present && isEditMode(funnelData)
  );
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const imageUploadRef = useRef<HTMLImageElement>(null);
  const maxLogoWidth = `${funnelData.backend.site_attributes.max_logo_width}px`;

  const handleFocus = (focused: boolean) => {
    setIsFocused(focused);
  };
  const presenter =
    funnelData.frontend.presentMode || funnelData.frontend.previewMode;
  const { logo_present, logo_url, logo_width, logo_height } =
    funnelData.backend.site_attributes;

  const updateSiteLogo = (attributes) => {
    setUpdatingLogo(true);
    mutation.mutate(
      {
        id: funnelData.backend.site_attributes.id,
        site_uuid: funnelData.backend.site_attributes.uuid,
        page_id: funnelData.backend.page_attributes.id,
        site: attributes,
      },
      {
        onSuccess: (responseData) => {
          funnelData.backend = responseData;
          updateFrontend();
          imageUploadRef.current.style.display = 'none';
          setUpdatingLogo(false);
        },
        onError: (error) => {
          //@ts-ignore
          const errorMessage = error.response?.data?.logo ? error.response.data.logo.join(", ") : "Please try again later.";
          alert.error(
            <>
              <h3>Unable to upload the image</h3>
              <p>{errorMessage}</p>
            </>
          );
          imageUploadRef.current.style.display = 'none';
          setUpdatingLogo(false);
        },
      }
    );
  };

  const onLogoChange = (event) => {
    const file = event.target.files[0];
    imageUploadRef.current.src = URL.createObjectURL(file);
    imageUploadRef.current.style.display = 'block';
    imageUploadRef.current.onload = function() {
      updateSiteLogo({
        logo: file,
        logo_height: imageUploadRef.current.offsetHeight,
        logo_width: imageUploadRef.current.offsetWidth,
      });
      imageUploadRef.current.style.display = 'none';
    }
  };

  const uploadLogo = () => {
    const logoUploader: HTMLElement = document.querySelector(
      "input#profile-logo-input"
    );
    logoUploader.click();
  };

  const deleteLogo = () => {
    updateSiteLogo({
      delete_logo: "1",
      logo_height: null,
      logo_width: null,
    });
  };

  useEffect(() => {
    setShowPlaceholder(!logo_present && isEditMode(funnelData));
  }, [
    funnelData.backend.site_attributes.logo_present,
    funnelData.frontend.previewMode,
    funnelData.frontend.presentMode,
  ]);

  const renderFileInput = () => {
    return (
      <input
        id="profile-logo-input"
        type="file"
        name="logo"
        accept="image/png, image/jpeg, image/gif"
        onChange={onLogoChange}
        style={{ display: "none" }}
      />
    );
  };

  const dragCorners = (
    <$DragCorners>
      <$InnerCorner />
    </$DragCorners>
  );

  const renderLogoAndInput = () => {
    return (
      <>
        {logo_present && presenter && (
          <$LandingPageLogo
            src={logo_url}
            className="profile-logo"
            height={logo_height}
            width={logo_width}
            mobile={funnelData.frontend.mobileMode}
          />
        )}
        {logo_present && !presenter && (
          <>
            {renderFileInput()}
            <Resizable
              className="resize-logo"
              style={{ border: "2px dashed #D1D9DE" }}
              lockAspectRatio={true}
              handleComponent={{
                topLeft: dragCorners,
                topRight: dragCorners,
                bottomLeft: dragCorners,
                bottomRight: dragCorners,
              }}
              minHeight={30}
              minWidth={30}
              maxWidth={funnelData.frontend.mobileMode ? "100%" : maxLogoWidth}
              size={{ width: `${logo_width}px`, height: "auto" }}
              bounds="parent"
              defaultSize={{ width: `${logo_width}px`, height: "auto" }}
              onResizeStop={(e, direction, ref, d) => {
                funnelData.backend.site_attributes.logo_width = ref.clientWidth;
                funnelData.backend.site_attributes.logo_height =
                  ref.clientHeight;
                updateSiteLogo({
                  logo_width: ref.clientWidth,
                  logo_height: ref.clientHeight,
                });
              }}
            >
              <img src={funnelData.backend.site_attributes.logo_url} style={{
                width: `${logo_width}px`,
                height: `${funnelData.frontend.mobileMode ? "auto" : `${logo_height}px`}`,
                maxWidth: `${funnelData.frontend.mobileMode ? "100%" : maxLogoWidth}`,
              }} />
            </Resizable>
          </>
        )}
      </>
    );
  };

  const renderEmptyPlaceholder = () => {
    return (
      <>
        {renderFileInput()}
        <$FunnelPageEmptyPlaceholder width="156px">
          <Icon icon="image" size={42} color="baseDark" />
          <P size="2" fontWeight={600} margin="8px 0">
            Add your logo
          </P>
        </$FunnelPageEmptyPlaceholder>
      </>
    );
  };

  const mobileStyle = { width: "150px", padding: "10px" };

  return (
    <OutsideClickHandler onOutsideClick={() => { handleFocus(false); }} >
      <div onClick={() => handleFocus(true)}>
        <img ref={imageUploadRef} style={{ maxWidth: maxLogoWidth, height: "auto", display: "none" }} />
        {showPlaceholder ? (
          <EditableActionsComponent
            fieldName="Logo"
            topChildren={renderEmptyPlaceholder()}
            actions={[
              {
                title: funnelData.frontend.mobileMode
                  ? "Upload"
                  : "Upload an Image",
                logo: <Icon icon="image" size={24} viewBox="0 0 24 24" />,
                onClick: uploadLogo,
                style: funnelData.frontend.mobileMode && mobileStyle,
              },
            ]}
            isDisabled={!isEditMode(funnelData)}
            isFocused={isFocused}
            isInProgress={updatingLogo}
          />
        ) : (
          <EditableActionsComponent
            fieldName="Logo"
            topChildren={renderLogoAndInput()}
            actions={[
              {
                title: funnelData.frontend.mobileMode
                  ? "Upload"
                  : "Upload an Image",
                logo: <Icon icon="image" size={24} viewBox="0 0 24 24" />,
                onClick: uploadLogo,
                style: funnelData.frontend.mobileMode && mobileStyle,
              },
              {
                title: funnelData.frontend.mobileMode
                  ? "Remove"
                  : "Remove Image",
                logo: <Icon icon="trash" size={24} viewBox="0 0 24 24" />,
                onClick: deleteLogo,
                style: funnelData.frontend.mobileMode && mobileStyle,
              },
            ]}
            isDisabled={!isEditMode(funnelData)}
            isFocused={isFocused}
            isInProgress={updatingLogo}
          />
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default ProfileLogo;
