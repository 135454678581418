import styled from "styled-components";
import { colors } from "@styles/colors";

export const $SettingCard = styled.div`
  font-family: "Open Sans";
  width: 100%;
  border-radius: 4px;
  cursor: pointer;

  background-color: ${colors.baseLightest};
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
