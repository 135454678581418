import axios from "axios";
import { getCSRFToken } from "@utils/utils";

interface MailRecipientParams {
  phone_number: string;
  email_address: string;
  first_name: string;
  last_name: string;
  is_test: boolean;
  react: "react";
}

export interface ImutationParams {
  site_uuid: string;
  page_uuid: string;
  mail_recipient: MailRecipientParams;
  "g-recaptcha-response": string;
}

export async function sendFunnelTest(params) {
  const { data } = await axios.post("/mail_recipients/send_test_react.js", params, {
    headers: { "X-CSRF-TOKEN": getCSRFToken() },
  });

  return data;
}
