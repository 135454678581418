import styled from "styled-components";
import { colors } from "@styles/colors";

type FunnelNavButtonContainerProps = {
  active: boolean;
  disabled?: boolean;
};

export const $FunnelNavButtonContainer = styled.div<FunnelNavButtonContainerProps>`
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  height: 67px;
  border-radius: 8px;
  ${(props) => (props.disabled ? "cursor: not-allowed;" : "cursor: pointer;")}

  background-color: ${(props) =>
    props.disabled ? colors.darkGrayBackground : colors.grayBackground};
  padding: 16.5px 18px;

  &:hover {
    background-color: ${(props) =>
      !props.active && !props.disabled && colors.lightBlueButtonHover};
  }

  ${(props) =>
    props.active &&
    `
    {
      background-color: ${colors.blueButtonHoverPale};
    }
  `}
`;
