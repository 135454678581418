import React, {
  FunctionComponent as FC,
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { FunnelPageType } from "@components/funnel/builder/MainContainer";
import SendFunnelTestModal from "@components/elements/SendFunnelTestModal";
import {
  $FunnelHeaderContainer,
  $FunnelURLEditor,
  $EditURLWrapper,
  $FunnelHeaderButton,
  $FunnelHeaderPillButtonContainer,
} from "./FunnelHeader.sc";
import Button from "@traject/reviewspod.molecules.button";
import SlugEditor from "./SlugEditor";
import PreviewLink from "./PreviewLink";
import Icon from "@components/atoms/Icon";
import { colors } from "@styles/colors";
import ReactTooltip from "react-tooltip";

const FunnelHeader: FC = () => {
  const { funnelData, updateFrontend } = useContext(FunnelContext);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { previewMode, mobileMode } = funnelData.frontend;
  const { site_attributes, page_attributes } = funnelData.backend;

  const toggleMobileMode = () => {
    funnelData.frontend.mobileMode = !funnelData.frontend.mobileMode;
    updateFrontend();
  };

  const togglePreviewMode = () => {
    funnelData.frontend.previewMode = !funnelData.frontend.previewMode;
    funnelData.frontend.activeFunnelPage = FunnelPageType.LandingPage;
    updateFrontend();
  };

  const previewModeIcon = previewMode ? "close" : "eye";
  const mobileModeIcon = mobileMode ? "deskTop" : "smartPhone";
  const isEmailOrPhoneEnabled =
    page_attributes.is_mail_enabled || (page_attributes.is_phone_enabled && page_attributes.twilio_verified);
  const showSendTestButton = previewMode && isEmailOrPhoneEnabled;

  const calculateUrlBarWidth = (): string => {
    const urlBarWidth = document.querySelector(
      ".funnel-header-container"
    ).clientWidth;
    return `${urlBarWidth - 280}px`;
  };

  const [urlBarMaxWidth, setUrlBarMaxWidth] = useState<string>("0px");

  useEffect(() => {
    setUrlBarMaxWidth(calculateUrlBarWidth);
  }, [previewMode]);

  useLayoutEffect(() => {
    function updateSize() {
      setUrlBarMaxWidth(calculateUrlBarWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <$FunnelHeaderContainer className="funnel-header-container">
      {openModal && (
        <SendFunnelTestModal
          siteUuid={site_attributes.uuid}
          pageUuid={page_attributes.uuid}
          email={page_attributes.is_mail_enabled}
          sms={page_attributes.is_phone_enabled && page_attributes.twilio_verified}
          isWhiteLabeledUser={false}
          onClose={() => {
            setOpenModal(false);
          }}
        />
      )}
      <$FunnelURLEditor>
        <$EditURLWrapper className="edit-url-wrapper">
          {previewMode ? (
            <PreviewLink urlBarMaxWidth={urlBarMaxWidth} />
          ) : (
            <SlugEditor />
          )}
        </$EditURLWrapper>
      </$FunnelURLEditor>
      <$FunnelHeaderButton
        className="funnel-header-button"
        onClick={togglePreviewMode}
        data-tip={previewMode ? "Exit Preview" : "Preview"}
        data-place="bottom"
        data-effect="solid"
        data-offset={`{'left': ${previewMode ? "60" : "30"}, 'bottom': -20}`}
        data-background-color={colors.darkBlue}
        data-testid={previewMode ? "Return" : "Preview"}
        data-arrow-color="transparent"
      >
        <Icon icon={previewModeIcon} size={24} color="primary2" />
      </$FunnelHeaderButton>
      <$FunnelHeaderButton
        className="funnel-header-button"
        onClick={toggleMobileMode}
        data-tip={mobileMode ? "View Desktop" : "View Mobile"}
        data-place="bottom"
        data-effect="solid"
        data-offset="{'left': 10, 'bottom': -20}"
        data-background-color={colors.darkBlue}
        data-arrow-color="transparent"
      >
        <Icon icon={mobileModeIcon} size={24} color="primary2" />
      </$FunnelHeaderButton>
      {showSendTestButton && (
        <$FunnelHeaderPillButtonContainer className="funnel-header-pill-button-container">
          <Button color="primary" onClick={() => setOpenModal(true)}>
            Send Test
          </Button>
        </$FunnelHeaderPillButtonContainer>
      )}
      <ReactTooltip />
    </$FunnelHeaderContainer>
  );
};

export default FunnelHeader;
