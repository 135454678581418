import React, { FunctionComponent, useContext, useState } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";

import { $RatingThumbButtonDown, $RatingThumbButtonUp } from "./Thumb.sc";
import { IRatingTypeProps } from "../RatingTypeTemplates";
import { FunnelRatingContainer } from "@styles/components/funnel/Builder";
import { $DescriptionText } from "../RatingTypeDescription.sc";

const ratingLabelTo10Scale = {
  binary_choice_negative: 2,
  binary_choice_positive: 10,
};

enum ThumbType {
  Up = 1,
  Down = 0,
}

const RatingTypeThumbs: FunctionComponent<IRatingTypeProps> = ({
  handleRatingClick,
}) => {
  const { funnelData } = useContext(FunnelContext);

  const [hoveredRating, setHoveredRating] = useState<ThumbType|null>(null);

  let label = 'Select a Rating'
  if(hoveredRating != null) {
    label = funnelData.backend.content_modules_attributes[`choice_${hoveredRating}_label`]
  }

  const renderThumb = (thumbType: ThumbType) => {
    const key =
      thumbType == ThumbType.Up
        ? "binary_choice_positive"
        : "binary_choice_negative";
    const label = thumbType == ThumbType.Up ? "choice_1_label" : "choice_0_label";
    const RatingThumbButton =
      thumbType == ThumbType.Up ? $RatingThumbButtonUp : $RatingThumbButtonDown;

    return (
      <RatingThumbButton
        className="thumb-button"
        data-rating={ratingLabelTo10Scale[key]}
        data-track-name="funnel_click"
        data-track-key={key}
        data-track-text={funnelData.backend.content_modules_attributes[label]}
        data-track-destination="onsite"
        data-track-context="rating"
        data-track-group="binary"
        data-track-sentiment={thumbType == ThumbType.Up ? "positive" : "negative"}
        onClick={handleRatingClick}
        onMouseEnter={() => setHoveredRating(thumbType)}
        onMouseLeave={() => setHoveredRating(null)}
      />
    );
  };

  return (
    <FunnelRatingContainer>
      {renderThumb(ThumbType.Down)}
      {renderThumb(ThumbType.Up)}
      <$DescriptionText>{label}</$DescriptionText>
    </FunnelRatingContainer>
  );
};

export default RatingTypeThumbs;
