import styled from "styled-components";
import { lighten } from "polished";
export const $FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  button.feedback-button {
    ${({ color }) =>
      color &&
      color !== "#006DFF" &&
      `background-color: ${color};
    &:hover {
      background-color: ${lighten(0.1, color)};
    }`}
  }
`;
