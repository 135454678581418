import styled from "styled-components";

export const $LeftSection = styled.div`
  width: 25%;
  margin: 16px 8px;
`;

export const $HeaderBackButtonContainer = styled($LeftSection)`
  padding-left: 8px;
`;

export const $HeaderButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: ${(props) => props.theme.border};
  height: 80px;
`;

export const $BackButtonContainer = styled.div`
  width: 30%;
  padding-left: 24px;
`;

export const $SettingButtonContainer = styled($LeftSection)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

export const $SubNavContainer = styled.div`
  width: 75%;
  padding: 12px 12px 64px 12px;
  @media only screen and (max-width: 1530px) {
    max-width: 340px;
  }
`;

export const $SettingsNavContainer = styled.div`
  display: flex;
  width: 100%;
  button.connect-to-business-button {
    margin-top: 8px;
    width: 100%;
    justify-content: center;
  }
`;

export const $SettingsAnimations = styled.div`
  min-width: 435px;
  ${(props) => props.theme.animation.slideLeft};
  @media only screen and (min-width: 1900px) {
    width: 510px;
  }
`;
