import React, { FunctionComponent as FC, useState } from "react";

import CurrentlyMonitoring from "@components/funnel/settings/CurrentlyMonitoring";
import AddAReviewSite from "@components/funnel/settings/AddAReviewSite";

export interface IReviewMonitoringProps {}

const ReviewMonitoring: FC = () => {
  const [showCurrent, setShowCurrent] = useState(true);
  return showCurrent ? (
    <CurrentlyMonitoring setShowCurrent={setShowCurrent} />
  ) : (
    <AddAReviewSite setShowCurrent={setShowCurrent} />
  );
};

export default ReviewMonitoring;
