import React, { FunctionComponent as FC, useState, useContext } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { ReviewSiteLinkType } from "../FunnelContext";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { trackAhoyEvent } from "@utils/funnel";

import itemMoveIcon from "../../../../assets/icons/funnel/move-icon.svg";
import {
  $DraggableItemContainer,
  $ReviewSiteLink,
  $ReviewSiteLinkImage,
  $DraggableItemActionIcon,
} from "./ReviewSiteDraggableListItem.sc";

export interface IReviewSiteDraggableListItemProps {
  link: ReviewSiteLinkType;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  isEditable: boolean;
}

const ReviewSiteDraggableListItem: FC<IReviewSiteDraggableListItemProps> = ({
  link,
  provided,
  isEditable,
}: IReviewSiteDraggableListItemProps) => {
  const { funnelData } = useContext(FunnelContext);
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const handleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleSiteClick = (event) => {
    const { frontend } = funnelData;
    if (frontend.presentMode) { trackAhoyEvent(event); }
  };

  return (
    <$DraggableItemContainer
      ref={provided.innerRef}
      {...provided.draggableProps}
      inactive={isVisible}
    >
      <$ReviewSiteLink
        key={link.uuid}
        target={link.switch_opens_in_new_win ? "_blank" : null}
        id={link.category}
        href={link.url}
        data-track="yes"
        data-track-name="funnel_click"
        data-track-key={link.category}
        data-track-text={link.title}
        data-track-destination="outbound"
        data-track-context="channel_selection"
        data-track-sort-order={link.sort_order}
        inactive={isVisible}
        onClick={handleSiteClick}
      >
        <$ReviewSiteLinkImage
          src={link.logo_url}
          data-track="yes"
          data-track-name="funnel_click"
          data-track-key={link.category}
          data-track-text={link.title}
          data-track-destination="outbound"
          data-track-context="channel_selection"
          data-track-sort-order={link.sort_order}
        />
        {link.title}
      </$ReviewSiteLink>

      {isEditable && (
        <>
          <$DraggableItemActionIcon
            src={itemMoveIcon}
            top={16}
            left={20}
            {...provided.dragHandleProps}
            inactive={isVisible}
          />
        </>
      )}
    </$DraggableItemContainer>
  );
};

export default ReviewSiteDraggableListItem;
