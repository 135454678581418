import styled from "styled-components";
import { colors } from "@styles/colors";

import checkCircleChecked from "../../../assets/icons/checkboxes/check-circle-checked.svg";
import checkCircleUnchecked from "../../../assets/icons/checkboxes/check-circle-unchecked.svg";

export const $CheckboxPanelCheckbox = styled.div<{
  isChecked?: boolean;
  icon: any;
}>`
  position: relative;
  width: 162px;
  height: 140px;
  display: inline-block;

  background: ${colors.grayBackground};
  box-sizing: border-box;
  border-radius: 4px;

  border: ${(props) =>
    props.isChecked ? `2px solid ${colors.primaryBase}` : null};
  border: 2px solid transparent;

  &:hover {
    border: 2px solid ${colors.primaryBase};
    background-color: ${colors.lightBlueButtonHover};
  }

  background-image: ${(props) => props.icon && `url(${props.icon})`},
    ${(props) =>
      props.isChecked
        ? `url(${checkCircleChecked})`
        : `url(${checkCircleUnchecked})`};
  background-repeat: no-repeat;
  background-position: 50% 30%, 95% 5%;
  background-origin: border-box;
`;

export const $CheckboxPanelText = styled.div`
  position: absolute;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.mineShaft};
  bottom: 25px;
  width: 100%;
  text-align: center;
`;

export const $CheckboxPanelContainer = styled.div`
  width: 400px;
  line-height: 0px;

  ${$CheckboxPanelCheckbox} {
    margin-bottom: 15px;
  }
  ${$CheckboxPanelCheckbox}:nth-child(even) {
    margin-left: 15px;
  }
`;
