import styled from "styled-components";

export const $CardContainer = styled.div<{
  backgroundColor: string;
  padding: string;
  justify?: string;
  margin?: string;
}>`
  display: flex;
  justify-content: ${(props) => props.justify};
  align-items: center;
  padding: ${(props) => props.padding};
  border-radius: 10px;
  margin: ${(props) => props.margin};
  background-color: ${(props) => props.backgroundColor};
`;
