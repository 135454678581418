import React, { FunctionComponent as FC, useContext } from "react";
import { $LogoContainer, $SelectedOverlay } from "./AddReviewSite.sc";
import { AddReviewSiteContext } from "@components/funnel/settings/AddAReviewSite/index";
import ReviewSiteLogo from "@components/funnel/settings/AddAReviewSite/ReviewSiteLogo";

type IReviewSiteTileProps = {
  target: any;
};

const ReviewSiteTile: FC<IReviewSiteTileProps> = ({ target }) => {
  const {
    toggleSelectedTarget,
    currentActiveTarget,
    addLogoError,
    getLogoError,
  } = useContext(AddReviewSiteContext);

  const handleLogoClick = (categoryId, targetUuid) => {
    toggleSelectedTarget(categoryId, targetUuid);
    currentActiveTarget(categoryId, targetUuid);
  };

  const { categoryId, uuid: targetUuid, title } = target;

  return (
    <$LogoContainer
      key={`site-logo-container-${categoryId}-${targetUuid}`}
      data-testid={`site-logo-container-${categoryId}-${targetUuid}`}
      onClick={() => handleLogoClick(categoryId, targetUuid)}
    >
      <ReviewSiteLogo
        link={target}
        logoError={getLogoError(categoryId, targetUuid)}
        addLogoError={addLogoError}
      />
      <div className="site-logo-title">{title}</div>
    </$LogoContainer>
  );
};

export default ReviewSiteTile;
