import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useState,
} from "react";

import PrimaryNav from "./PrimaryNav";
import SettingsNav from "./SettingsNav";
const FunnelNav: FC = () => {
  const { funnelData } = useContext(FunnelContext);
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    setShowSettings(funnelData.frontend.openSettings);
  }, [funnelData.frontend.openSettings]);

  return (
    <div className="navigation" style={{ height: "inherit" }}>
      {showSettings ? (
        <SettingsNav closeNavigation={() => setShowSettings(false)} />
      ) : (
        <PrimaryNav openNavigation={() => setShowSettings(true)} />
      )}
    </div>
  );
};

export default FunnelNav;
