import React, { FunctionComponent as FC, useContext } from "react";
import { $FunnelPageFooterClickable } from "./FunnelPageFooterInteractive.sc";
import { FunnelPageType } from "../MainContainer";
import { FunnelContext } from "../FunnelContext";

interface IFunnelFooterProps {
  clickablePrefix: string;
  middle: string;
  clickableSuffix: string;
}

const FunnelPageFooterInteractive: FC<IFunnelFooterProps> = ({
  clickablePrefix,
  middle,
  clickableSuffix,
}) => {
  const { funnelData, updateFrontend } = useContext(FunnelContext);

  const onShowLandingPage = () => {
    funnelData.frontend.activeFunnelPage = FunnelPageType.LandingPage;
    updateFrontend();
  };

  const onShowDirectFeedback = () => {
    funnelData.frontend.activeFunnelPage = FunnelPageType.NegativeReviewPage;
    updateFrontend();
  };

  return (
    <>
      <$FunnelPageFooterClickable
        className="footer-interactive"
        onClick={onShowLandingPage}
      >
        {clickablePrefix}
      </$FunnelPageFooterClickable>
      {middle}
      <$FunnelPageFooterClickable
        className="footer-interactive"
        onClick={onShowDirectFeedback}
      >
        {clickableSuffix}
      </$FunnelPageFooterClickable>
    </>
  );
};

export default FunnelPageFooterInteractive;
