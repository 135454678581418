import styled from "styled-components";
import circleChecked from "@atoms/Logo/circle-checked.svg";

export const $AddReviewSiteContainer = styled.div`
  .m-accordion__container {
    margin-bottom: 16px;
  }

  .add-custom-link-btn {
    box-shadow: none;
    font-weight: normal;
    justify-content: center;
    width: 100%;
  }

  .add-review-site-separator {
    height: 52px;
  }
`;

type ILogoProps = { defaultSelected?: boolean };

export const $LogoContainer = styled.div<ILogoProps>`
  padding: 0px 6px 6px 0px;
  background: ${({ theme }) => theme.colors["baseWhite"]};
  border: ${({ defaultSelected, theme }) =>
    defaultSelected
      ? `2px solid ${theme.colors["darkSuccess"]}`
      : `1px solid ${theme.colors["baseLighter"]}`};
  border-radius: 4px;
  height: 140px;
  width: 140px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0px 5px 5px 0px;

  @media only screen and (max-width: 1840px) {
    width: 45%;
  }

  .logo-title {
    font-size: 16px;
    font-family: "Open Sans";
    color: ${({ theme }) => theme.colors["mineShaft"]};
  }

  .site-logo-title {
    color: ${({ theme }) => theme.colors["baseDark"]};
    display: inline-block;
    inline-size: 112px;
    justify-content: center;
    overflow: hidden;
    overflow-wrap: break-word;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media only screen and (max-width: 1840px) {
      inline-size: 90%;
    }
  }
`;

export const $SelectedOverlay = styled.div`
  width: 20px;
  height: 20px;
  background-image: url("${circleChecked}");
  background-repeat: no-repeat;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const $ReviewSiteFormContainer = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.colors.baseWhite};
  border: 1px solid ${({ theme }) => theme.colors.baseLighter};
  border-radius: 8px;
  width: 304px;
  margin: 0px 5px 5px 0px;

  .review-site-form-header {
    display: flex;
    padding-bottom: 12px;

    .review-site-form-title {
      align-items: center;
      display: flex;
      flex-direction: row;
      white-space: nowrap;
    }
    .review-site-form-close {
      margin-left: auto;
    }
  }

  form {
    .review-site-form-label {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 0 0 8px 0;
    }

    .review-site-form-toggle-tip {
    }

    .review-site-form-toggle-tip-warning {
      color: ${({ theme }) => theme.colors.darkError};
      font-weight: 700;
    }

    .review-site-form-checkbox {
      margin: 0 0 10px 0;
    }
  }
`;

export const $SearchBarContainer = styled.div`
  margin-top: 17px;
`;

export const $ReviewSiteLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 60px;
`;

export const $TransactionalContainer = styled.div`
  padding: 8px 0px;

  .custom-link__field {
    padding-top: 24px;

    input[type="text"] {
      color: ${({ theme }) => theme.colors.palette.gray900};
    }

    input[type="file"] {
      display: none !important;
    }
  }
`;
