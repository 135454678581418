import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  FunctionComponent as FC,
  ReactNode,
  forwardRef,
  ComponentPropsWithoutRef,
} from "react";
import Icon from "@components/atoms/Icon";
import P from "@components/atoms/Typography/Text";
import {
  $CollapsibleButton,
  $Collapsible,
  $CollapsibleContainer,
} from "./Collapsible.sc";

export interface ICollapsibleProps extends ComponentPropsWithoutRef<any> {
  active: string;
  title: string;
  description: string;
  iconClass: string;
  onClick: any;
  disabled?: boolean;
  open: boolean;
  children: ReactNode;
}

const Collapsible: FC<ICollapsibleProps> = forwardRef<
  HTMLDivElement,
  ICollapsibleProps
>(
  (
    {
      active,
      title,
      description,
      iconClass,
      disabled,
      onClick,
      open,
      children,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(open);
    const [height, setHeight] = useState(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (isOpen)
        setHeight(containerRef.current?.getBoundingClientRect().height);
      else setHeight(0);
    }, [isOpen]);

    // @ts-ignore
    useImperativeHandle(ref, () => ({ openCollapsible }));

    const titleColor = disabled ? "baseDark" : "baseDarker";
    const descColor = disabled ? "baseDark" : "baseDarker";
    const iconColor = disabled ? "baseDark" : "primary2";

    const toggleOpen = () => {
      setIsOpen(!isOpen);
      onClick();
    };

    const openCollapsible = (isOpen: boolean) => {
      setIsOpen(isOpen);
    };

    return (
      <$Collapsible>
        <$CollapsibleButton
          active={active}
          disabled={disabled}
          data-testid={`${iconClass}-collapsible-button`}
          onClick={disabled ? null : toggleOpen}
        >
          <Icon
            size={26}
            viewBox="0 0 21 21"
            icon={iconClass}
            color={iconColor}
          />
          <div style={{ marginLeft: "24px" }}>
            <P color={active || (!active && titleColor)} fontWeight={600}>
              {title}
            </P>
            <P size="3" color={descColor}>
              {description}
            </P>
          </div>
          <Icon
            className="toggle-icon"
            size={25}
            viewBox="0 0 20 20"
            icon={isOpen ? "chevronUp" : "chevronDown"}
            color={iconColor}
          />
        </$CollapsibleButton>
        <$CollapsibleContainer
          data-testid={`${iconClass}-collapsible-container`}
          active={active}
          disabled={disabled}
          className={
            isOpen
              ? `${iconClass}-collapsible-open`
              : `${iconClass}-collapsible-close`
          }
          style={{ height }}
        >
          {children && (
            <div ref={containerRef} className="container-ref">
              {children}
            </div>
          )}
        </$CollapsibleContainer>
      </$Collapsible>
    );
  }
);

export default Collapsible;
