import styled from "styled-components";
import { colors } from "@styles/colors";

export const $FunnelPageFooter = styled.div<{ mobileMode?: boolean }>`
  text-align: center;
  font-family: "Open Sans";
  margin: auto;
  font-size: 12px;
  line-height: 21px;
  color: ${colors.baseCopy};
  width: ${(props) => (props.mobileMode ? "80%" : "70%")};
  margin-top: 15px;
  a > img {
    vertical-align: middle;
  }
`;
