import React, { FC, useContext } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import Input from "@molecules/Input";

export interface IPhoneInputProps {
  register: any;
  errors: any;
}

const FeedbackFormPhoneInput: FC<IPhoneInputProps> = ({ register, errors }) => {
  const { funnelData } = useContext(FunnelContext);

  const { recipient_attributes, config_modules_attributes } =
    funnelData.backend;
  const { autofill_and_hide_email, show_direct_feedback_phone_input } =
    config_modules_attributes;

  const isHidden = () =>
    autofill_and_hide_email && recipient_attributes?.phone_number;

  return isHidden() ? (
    <Input
      name="phone"
      id="phone"
      type="hidden"
      value={recipient_attributes.phone_number}
    />
  ) : (
    <Input
      label="Phone"
      name="phone"
      placeholder="555-555-5555"
      errors={errors.phone}
      defaultValue={recipient_attributes?.phone_number}
      register={register({
        pattern: {
          value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
          message:
            "There was an error with your phone number. Please re-enter.",
        },
      })}
      disabled={!show_direct_feedback_phone_input}
    />
  );
};

export default FeedbackFormPhoneInput;
