import React, { FunctionComponent as FC, useContext } from "react";
import { $FunnelPageContainer } from "./FunnelPageEditor.sc";
import { FunnelContext } from "../FunnelContext";
import FunnelFlow from "../../presenter/FunnelFlow";

const FunnelPageEditor: FC = () => {
  const { funnelData } = useContext(FunnelContext);

  return (
    <$FunnelPageContainer
      mobileMode={funnelData.frontend.mobileMode}
      className="funnel-page-container"
      backgroundColor={
        funnelData.backend.config_modules_attributes.background_color
      }
      cssAttributes={funnelData.backend.site_attributes.custom_css}
    >
      <FunnelFlow />
    </$FunnelPageContainer>
  );
};

export default FunnelPageEditor;
