import styled from "styled-components";
import { colors } from "../../../colors";
import { BasePillButton } from "../../elements/Buttons";

export const ConfirmButton = styled(BasePillButton)`
  width: 100px;
  height: 30px;
  font-weight: 500;
`;

export const CancelButton = styled.a`
  font-family: "Open Sans";
  margin-right: 20px;
  font-size: 14px;
  font-weight: 300;
  color: ${colors.pastelDarkBlue};

  &:hover {
    text-decoration: none;
    color: ${colors.steelBlue};
  }

  &:active {
    text-decoration: none;
    color: ${colors.steelBlue};
  }
`;

export const ButtonsContainer = styled.div`
  float: right;
  margin-right: 25px;
  margin-top: 20px;
`;
