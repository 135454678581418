import React, { FunctionComponent as FC, useContext } from "react";
import { FunnelContext } from "../FunnelContext";
import ProfileLogo from "../ProfileLogo";
import LandingPageTitle from "../LandingPageTitle";
import FunnelBodyCopy from "../FunnelBodyCopy";
import ReviewSiteList from "../ReviewSiteList";
import { CardContainer } from "@components/atoms/CardContainer";
import Button from "@traject/reviewspod.molecules.button";
import HorizontalBreak from "@components/atoms/HorizontalBreak";
import { $FeedbackContainer } from "./PositiveReviewPage.sc";
import { FunnelPageType } from "../MainContainer";
import Theme from "@traject/reviewspod.base_ui.theme";
import { $FunnelPageFooter } from "../LandingPage/LandingPage.sc";

const PositiveReviewPage: FC = () => {
  const { funnelData, updateFrontend } = useContext(FunnelContext);

  function handleClick() {
    funnelData.frontend.activeFunnelPage = FunnelPageType.DirectFeedback;
    updateFrontend();
  }
  return (
    <Theme theme="gus">
      <div data-testid="positive-review-page" className="positive-review-page">
        <ProfileLogo />
        <div style={{ height: "16px" }} />
        <LandingPageTitle />
        <FunnelBodyCopy contentKey="feedback_body" />
        <ReviewSiteList />
        <HorizontalBreak />
        <CardContainer padding="64px">
          <$FeedbackContainer
            className="positive-feedback-container"
            color={funnelData.backend.config_modules_attributes.button_color}
          >
            <FunnelBodyCopy contentKey="feedback_prompt" />
            <Button
              className="feedback-button"
              onClick={handleClick}
              color="primary"
            >
              Leave Feedback
            </Button>
          </$FeedbackContainer>
        </CardContainer>
        <$FunnelPageFooter
          className="positive-feedback-footer"
          mobileMode={funnelData.frontend.mobileMode}
          dangerouslySetInnerHTML={{
            __html: funnelData.backend.site_attributes.seal,
          }}
        />
      </div>
    </Theme>
  );
};

export default PositiveReviewPage;
