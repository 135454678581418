import styled from "styled-components";
export const $PrimaryNavContainer = styled.div`
  min-width: 435px;
  height: inherit;
  ${(props) => props.theme.animation.slideRight}
  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 5%;
    }
  }
`;
export const $FunnelNavContainer = styled.div`
  width: 340px;
  margin: 0 auto;
  height: inherit;
`;

export const $DescriptionContainer = styled.div`
  width: 320px;
`;
