import axios from "axios";
import { getCSRFToken } from "@utils/utils";

export interface ICreateLinkMutationParams {
  site_id: string;
  page_id: string;
  // single link attributes
  title: string;
  url: string;
  category: string;
  switch_opens_in_new_win: boolean;
  switch_show: boolean;
  monitor_url?: string;
  custom_header?: string;
  category_id?: string;
  image?: File;
}

export async function useLinkCreate(params: ICreateLinkMutationParams) {
  let postParams: any = params;
  if (postParams.image) {
    const formData = new FormData();
    formData.append("link[site_id]", params.site_id);
    formData.append("link[page_id]", params.page_id);
    formData.append("link[title]", params.title);
    formData.append("link[url]", params.url);
    formData.append("link[category]", params.category);
    formData.append(
      "link[switch_opens_in_new_win]",
      `${params.switch_opens_in_new_win}`
    );
    formData.append("link[switch_show]", `${params.switch_show}`);
    formData.append("link[monitor_url]", params.monitor_url || null);
    formData.append("link[custom_header]", params.custom_header || null);
    formData.append("link[category_id]", params.category_id || null);
    formData.append("link[image]", params.image);
    postParams = formData;
  }
  const { data } = await axios.post("/builder/links.js", postParams, {
    headers: { "X-CSRF-TOKEN": getCSRFToken() },
  });
  return data;
}
