import React from "react";
import { transitions, positions } from "react-alert";
import { colors } from "@styles/colors";
import Icon from "@components/atoms/Icon";
import { $AlertContainer, $MessageContainer } from "./Alert.sc";
export const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  // timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

export const AlertTemplate = ({ style, options, message, close }) => (
  <div style={style}>
    <$AlertContainer type={options.type}>
      {options.type === "info" && "!"}
      {options.type === "success" && (
        <Icon icon={options.type} size={48} color={colors.darkSuccess} />
      )}
      {options.type === "error" && (
        <Icon icon={options.type} size={48} color={colors.darkError} />
      )}
      <$MessageContainer style={{ flexDirection: "column" }}>
        {message}
      </$MessageContainer>
      <a onClick={close}>
        <Icon icon="closeCircle" size={20} color={colors.black} />
      </a>
    </$AlertContainer>
  </div>
);
