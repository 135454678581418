import React from "react";
import { $Label } from "./Label.sc";

export interface ILabel {
  children: React.ReactNode;
};

const Label: React.FC<ILabel> = ({ children }) => {
  return <$Label>{children}</$Label>;
};

export default Label;
