import React, { FunctionComponent as FC } from "react";
import { $SearchInput } from "./SearchInput.sc";

export interface ISearchInputProps {
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  onChange: any;
};

const SearchInput: FC<ISearchInputProps> = ({
  placeholder,
  defaultValue,
  disabled = false,
  width = "100%",
  height = "100%",
  onChange
}) => {
  return (
    <$SearchInput
      width={width}
      height={height}
      placeholder={placeholder}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default SearchInput;
