import * as React from "react";
import { FunctionComponent as FC, useContext } from "react";
import { useForm } from "react-hook-form";
import { IConfirmationProps } from ".";
import CheckMarkCircle from "@app/javascript/assets/icons/checkmark/checkmark-fill.svg";
import { BaseInput } from "@styles/components/elements/Inputs";
import BackButton from "@components/elements/BackButton";
import Button from "@components/atoms/Button";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { errorFields, starInputs, IStarsConfirm } from "./utils";
import {
  $BackButtonContainer,
  $ButtonContainer,
  $InputWrapper,
  $LabelText,
  $RatingLabelsContainer,
  $RatingTypesBody,
  $RatingTypesFormWrapper,
  $RatingTypesTitle,
} from "./RatingLabels.sc";

const ReviewFirstConfirm: FC<IConfirmationProps> = ({ onConfirm, backFxn }) => {
  const { funnelData } = useContext(FunnelContext);
  const { handleSubmit, register, errors } = useForm<IStarsConfirm>({
    mode: "onChange",
  });

  const defaultValues = (name, holder) => {
    if (funnelData.backend.config_modules_attributes.template == "review") {
      return funnelData.backend.content_modules_attributes[name];
    } else {
      return holder;
    }
  };
  const onSubmit = (data: IStarsConfirm) => {
    onConfirm(data);
  };

  return (
    <>
      <$BackButtonContainer>
        <BackButton text="Back to Rating Types" backFxn={() => backFxn()} />
      </$BackButtonContainer>
      <$RatingLabelsContainer>
        <$RatingTypesTitle>Review First</$RatingTypesTitle>
        <$RatingTypesBody>
          Combine the Landing Page and Direct Feedback.
          <br />
          <br />
          First, label the stars that appear above the Direct Feedback below.
          <$RatingTypesFormWrapper>
            {starInputs.map(({ label, name, holder }) => {
              return (
                <$InputWrapper key={name}>
                  <$LabelText>{label}</$LabelText>
                  <BaseInput
                    name={name}
                    type="text"
                    defaultValue={defaultValues(name, holder)}
                    placeholder={holder}
                    errorMessage={!!errors[name]}
                    ref={register({
                      required: "Description Required",
                      maxLength: {
                        value: 26,
                        message: "Cannot exceed 26 characters",
                      },
                    })}
                  />
                  {errorFields(errors[name])}
                </$InputWrapper>
              );
            })}
          </$RatingTypesFormWrapper>
        </$RatingTypesBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <$ButtonContainer>
            <Button onClick={() => onSubmit} leftIcon={CheckMarkCircle}>
              Confirm
            </Button>
          </$ButtonContainer>
        </form>
      </$RatingLabelsContainer>
    </>
  );
};

export default ReviewFirstConfirm;
