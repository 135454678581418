import axios from "axios";
import { getCSRFToken } from "@utils/utils";

export interface IUpdateLocationParams {
  uuid: string;
  address1?: string;
  address2?: string;
  locality?: string;
  region?: string;
  country?: string;
  postal_code?: string;
  phone?: string;
  email?: string;
  website?: string;
}

export async function updateLocation(params) {
  const { data } = await axios.patch("/funnel/builder/update_location", { uuid: params.uuid, location: params }, {
    headers: { "X-CSRF-TOKEN": getCSRFToken() },
  });
  return data;
}
