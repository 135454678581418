import React, { FunctionComponent as FC, useContext, useState } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { IRatingTypeProps } from "../RatingTypeTemplates";
import DirectFeedbackForm from "@components/funnel/builder/DirectFeedbackForm";
import { FunnelPageType } from "@components/funnel/builder/MainContainer";

const ReviewFirstForm: FC<IRatingTypeProps> = (props: IRatingTypeProps) => {
  const { funnelData, updateFrontend } = useContext(FunnelContext);

  function funnelFlowFrontEndUpdate() {
    if (
      props.starRating * 2 >
      funnelData.backend.config_modules_attributes.segmenting_threshold
    ) {
      funnelData.frontend.activeFunnelPage = FunnelPageType.PositiveReviewPage;
    } else {
      funnelData.frontend.activeFunnelPage = FunnelPageType.NegativeReviewPage;
    }
    updateFrontend();
  }

  return (
    <div data-testid="funnel-review-first-template">
      <DirectFeedbackForm
        className="funnel-review-first"
        prelude={`rating_${props.starRating}`}
        requireStarRating={true}
        starRating={props.starRating}
      />
    </div>
  );
};

export default ReviewFirstForm;
