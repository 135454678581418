import React, {
  FunctionComponent as FC,
  useState,
  createContext,
  useContext,
  useEffect,
} from "react";
import Modal from "react-modal";
import Button from "@traject/reviewspod.molecules.button";
import {
  $AddReviewSiteContainer,
  $SearchBarContainer,
} from "./AddReviewSite.sc";
import { IAddReviewSiteType, ISelectedTarget } from "./utils";
import useCategories from "@components/funnel/hooks/useCategories";
import Skeleton from "react-loading-skeleton";
import Accordion from "@traject/reviewspod.molecules.accordion";
import AddReviewSiteItem from "./AddReviewSiteItem";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { getLink } from "@components/funnel/settings/AddAReviewSite/utils";
import SearchInput from "@molecules/SearchInput";
import SearchResult from "./SearchResult";
import CustomLinkModal from "@components/funnel/settings/AddAReviewSite/CustomLinkModal";

export interface IAddReviewSiteProps {
  setShowCurrent: (data) => void;
}

export const AddReviewSiteContext = createContext<IAddReviewSiteType>(null);

Modal.setAppElement("body");

const AddAReviewSite: FC<IAddReviewSiteProps> = ({ setShowCurrent }) => {
  const { data, isLoading, isSuccess } = useCategories();
  const { funnelData } = useContext(FunnelContext);

  const [selectedTargets, setSelectedTargets] = useState<ISelectedTarget[]>([]);
  const [activeTarget, setActiveTarget] = useState(null);
  const [links, _] = useState(funnelData.backend.links_attributes);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [targets, setTargets] = useState([]);
  const [targetSearchResult, setTargetSearchResult] = useState([]);
  const [logoErrors, setLogoErrors] = useState({});
  const [openLinkModal, setOpenLinkModal] = useState(false);

  useEffect(() => {
    if (data) {
      const slugs = Object.fromEntries(
        links.map(
          ({ category: slug, uuid: linkUuid, category_id: categoryId }) => {
            return [slug, { linkUuid, categoryId }];
          }
        )
      );
      const allTargets = data
        .map(({ id: categoryId, targets_attributes }) =>
          targets_attributes.map((t) => {
            return { categoryId, ...t };
          })
        )
        .flat();
      setTargets(allTargets);
      const filteredTargets = allTargets
        .filter(
          ({ categoryId, slug }) => slugs[slug]?.categoryId === categoryId
        )
        .map(({ categoryId, uuid: targetUuid, slug }) => {
          const { linkUuid } = slugs[slug];
          return { categoryId, targetUuid, linkUuid };
        });
      setSelectedTargets(filteredTargets);
    }
  }, [data]);

  const isTargetSelected = (categoryId, targetUuid) => {
    return selectedTargets.some(
      (st) => st.categoryId === categoryId && st.targetUuid === targetUuid
    );
  };

  const getSelectedTarget = (categoryId, uuid) => {
    const category = data.filter(({ id }) => categoryId === id)[0];
    const target = category.targets_attributes.filter(
      (t) => t.uuid === uuid
    )[0];
    return { categoryId, ...target };
  };

  const removeSelectedTarget = (categoryId, targetUuid) => {
    setSelectedTargets(
      selectedTargets.filter(
        (st) => st.categoryId !== categoryId || st.targetUuid !== targetUuid
      )
    );
  };

  const clearActiveTarget = () => {
    setActiveTarget(null);

    if (activeTarget === null) return;
    const { categoryId, uuid: targetUuid, slug } = activeTarget;
    const newLink = getLink(funnelData, categoryId, slug);
    if (newLink === null) removeSelectedTarget(categoryId, targetUuid);
  };

  const updateSelectedTarget = (newSelectedTarget: ISelectedTarget) => {
    for (const st of selectedTargets) {
      if (
        st.categoryId === newSelectedTarget.categoryId &&
        st.targetUuid === newSelectedTarget.targetUuid
      ) {
        st.linkUuid = newSelectedTarget.linkUuid;
      }
    }
    setSelectedTargets([...selectedTargets]);
  };

  const currentActiveTarget = (categoryId, targetUuid, linkUuid?) => {
    const st = getSelectedTarget(categoryId, targetUuid);
    setActiveTarget({ linkUuid, ...st });
  };

  const toggleSelectedTarget = (categoryId, targetUuid) => {
    if (!isTargetSelected(categoryId, targetUuid)) {
      setSelectedTargets([...selectedTargets, { categoryId, targetUuid }]);
      currentActiveTarget(categoryId, targetUuid);
    }
  };

  const addLogoError = (categoryId, targetUuid, logoError) => {
    setLogoErrors({
      ...logoErrors,
      [`${categoryId}-${targetUuid}`]: logoError,
    });
  };

  const getLogoError = (categoryId, targetUuid) => {
    return logoErrors[`${categoryId}-${targetUuid}`];
  };

  const handleKeywordChange = (event) => {
    clearActiveTarget();
    setSearchKeyword(event.target.value);
    const filteredTargets = targets.filter((target) =>
      target.title.toLowerCase().includes(event.target.value.toLowerCase())
    );
    const uniq = {};
    const uniqueTargets = filteredTargets.filter(
      (obj) => !uniq[obj.slug] && (uniq[obj.slug] = true)
    );
    setTargetSearchResult(uniqueTargets);
  };

  return (
    <$AddReviewSiteContainer>
      <Button
        variant="text"
        leftIcon="arrowLeft"
        color="dark"
        onClick={() => setShowCurrent(true)}
        id="BackToCurrentlyMonitoring"
      >
        Back to Currently Monitoring
      </Button>

      <AddReviewSiteContext.Provider
        value={{
          activeTarget,
          isTargetSelected,
          toggleSelectedTarget,
          removeSelectedTarget,
          clearActiveTarget,
          updateSelectedTarget,
          currentActiveTarget,
          addLogoError,
          getLogoError,
        }}
      >
        <$SearchBarContainer>
          <SearchInput
            placeholder="Search for review sites"
            defaultValue={searchKeyword}
            disabled={targets.length == 0}
            onChange={handleKeywordChange}
          />
        </$SearchBarContainer>
        {searchKeyword != "" ? (
          <SearchResult targetSearchResult={targetSearchResult} />
        ) : (
          <>
            <Accordion
              active="primary2"
              overflow="auto"
              maxHeight="100%"
              maxWidth="326px"
              singleOpen={true}
            >
              {isLoading && <Skeleton height="655px" />}
              {isSuccess &&
                data.map(({ id: categoryId, title, targets_attributes }) => (
                  <AddReviewSiteItem
                    key={`review-site-item-${categoryId}`}
                    categoryId={categoryId}
                    title={title}
                    targets_attributes={targets_attributes}
                  />
                ))}
            </Accordion>
            <Button
              variant="outline"
              className="add-custom-link-btn"
              onClick={() => setOpenLinkModal(true)}
            >
              Create a Custom Link
            </Button>
            <div className="add-review-site-separator" />
            <CustomLinkModal
              openLinkModal={openLinkModal}
              createModalFxn={() => setShowCurrent(true)}
              closeModalFxn={() => setOpenLinkModal(false)}
            />
          </>
        )}
      </AddReviewSiteContext.Provider>
    </$AddReviewSiteContainer>
  );
};

export default AddAReviewSite;
