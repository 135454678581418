import React, { FunctionComponent } from "react";

import EditableComponent, {
  IEditableComponentProps,
} from "../EditableComponent";
import {
  $EditableAction,
  $EditableActionIcon,
  $EditableActions,
} from "./EditableActionsComponent.sc";

type EditableActionsProps = {
  title: string;
  logo: React.ReactNode;
  onClick: () => void;
  style?: any;
};

export type EditableActionsComponentProps = IEditableComponentProps & {
  actions: Array<EditableActionsProps>;
};

const EditableActionsComponent: FunctionComponent<
  EditableActionsComponentProps
> = (props) => {
  const renderActions = () => {
    const actions = props.actions.map((item) => {
      return (
        <$EditableAction
          key={item.title}
          onClick={item.onClick}
          style={item.style || {}}
        >
          <$EditableActionIcon>{item.logo}</$EditableActionIcon>
          {item.title}
        </$EditableAction>
      );
    });

    return <$EditableActions>{actions}</$EditableActions>;
  };

  return (
    <EditableComponent
      fieldName={props.fieldName}
      tooltip={props.tooltip}
      topChildren={props.topChildren}
      bottomChildren={renderActions()}
      isFocused={props.isFocused}
      isDisabled={props.isDisabled}
      isInProgress={props.isInProgress}
      fullWidth={props.fullWidth}
    />
  );
};

export default EditableActionsComponent;
