import { colors } from "@styles/colors";
import styled from "styled-components";

export const $CustomCSSContainer = styled.div`
  border-radius: 10px;
  @media only screen and (max-width: 1690px) {
    width: 230px;
  }
`;

export const $CSSSyntaxEditor = styled.div`
  display: flex;
  justify-content: center;
  height: 370px;
  overflow: scroll;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid ${colors.baseLight};
  div.w-tc-editor {
    min-height: 370px;
    width: 290px;
    font-size: 16px;
    background-color: #ffffff;
    font-family: "Roboto Mono";
    overflow: visible !important;
    @media only screen and (max-width: 1690px) {
      width: 230px;
    }
    @media only screen and (max-width: 1400px) {
      width: 210px;
    }
    textarea {
      padding: 8px !important;
      line-height: 24px;
    }
    div.w-tc-editor-preview {
      pre {
        width: inherit !important;
        padding: 4px;
        font-size: 16px;
        background-color: #ffffff;
        font-family: "Roboto Mono";
        border: none;
        overflow-wrap: break-word;
        min-height: 370px;

        @media only screen and (max-width: 1690px) {
          width: 230px;
        }
        @media only screen and (max-width: 1400px) {
          width: 210px;
        }
        code {
          word-break: keep-all;
        }
      }
    }
  }
`;

export const $AcknowledgementContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0px;
`;

export const $SaveButtonContainer = styled.div`
  margin: 24px 0px 8px;
`;
