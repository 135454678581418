import Button from "@atoms/Button";
import P from "@atoms/Typography/Text";
import { CardContainer } from "@atoms/CardContainer";
import Icon from "@atoms/Icon";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import {
  IUpdateLinkMutationParams,
  useLinkUpdate,
} from "@components/funnel/hooks/useLinkUpdate";
import {
  $ReviewSiteOptionsContainer,
  $ReviewSiteTitle,
  $ReviewSiteToggleIcon,
} from "@components/funnel/settings/CurrentlyMonitoring/CurrentlyMonitoring.sc";
import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAlert } from "react-alert";
import { useMutation } from "react-query";
import UpdateReviewSite from "@components/funnel/settings/CurrentlyMonitoring/UpdateReviewSite";
import { colors } from "@styles/colors";
import ReactTooltip from "react-tooltip";
import ReviewSiteLogo from "@components/funnel/settings/CurrentlyMonitoring/ReviewSiteLogo";

export interface ICurrentlyMonitoringProps {
  setShowCurrent: (data) => void;
}

const MAX_SELECTED_LINKS = 6;

const CurrentlyMonitoring: FC<ICurrentlyMonitoringProps> = ({
  setShowCurrent,
}) => {
  const alert = useAlert();
  const { funnelData, updateFrontend } = useContext(FunnelContext);
  const toggleMutation = useMutation((params: IUpdateLinkMutationParams) =>
    useLinkUpdate(params.uuid, params)
  );
  const [links, setLinks] = useState(funnelData.backend.links_attributes);
  const [logoErrors, setLogoErrors] = useState({});
  const [editLink, setEditLink] = useState(null);
  const activeReviewSites = links
    .filter((l) => l.switch_show)
    .sort((a, b) => (a.category > b.category ? 1 : -1));
  const inactiveReviewSites = links
    .filter((l) => !l.switch_show)
    .sort((a, b) => (a.category > b.category ? 1 : -1));

  useEffect(() => {
    const newLinks = [...activeReviewSites, ...inactiveReviewSites];
    setLinks(newLinks);
  }, []);

  function popDeletedLink(link) {
    const filteredLinks = links.filter((el) => el.uuid != link.uuid);
    setLinks(filteredLinks);
  }

  function toggleActiveReviewSites(link) {
    if (link.switch_show || activeReviewSites.length < MAX_SELECTED_LINKS) {
      toggleMutation.mutate(
        {
          uuid: link.uuid,
          site_id: funnelData.backend.site_attributes.id,
          page_id: funnelData.backend.page_attributes.id,
          switch_show: !link.switch_show,
        },
        {
          onSuccess: () => {
            link.switch_show = !link.switch_show;
            updateFrontend();
          },
          onError: () => {
            alert.error(
              <>
                <h3>There was an error</h3>
                <p>Please try again</p>
              </>
            );
          },
        }
      );
    } else {
      alert.error(
        <>
          <h3>You have {MAX_SELECTED_LINKS} sites selected.</h3>
          <p>Deselect an option to choose a new one.</p>
        </>
      );
    }
  }

  const addLogoError = (linkUuid, logoError) => {
    setLogoErrors({ ...logoErrors, [linkUuid]: logoError });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <P color="baseDarkest" fontWeight={600}>
          Review Site Listings
        </P>
        <Button
          onClick={() => setShowCurrent(false)}
          shape="text"
          id="AddReviewSiteButton"
        >
          <Icon icon="squarePlus" color="primary2" />
        </Button>
      </div>
      <P color="baseDark" margin="21px 0">
        Review sites that appear on your funnel are indicated with the chosen
        circle. Add up to 6 review sites on your funnel.
      </P>

      {links &&
        links.map((link, idx) => {
          return (
            <div key={link.uuid}>
              <CardContainer
                margin="16px 0"
                key={link.uuid}
                justify="space-between"
              >
                <ReviewSiteLogo
                  link={link}
                  logoError={logoErrors[link.uuid]}
                  addLogoError={addLogoError}
                />
                <$ReviewSiteTitle
                  className={`title-container-${link.uuid}`}
                  data-testid="titleContainer"
                >
                  {link.title}
                </$ReviewSiteTitle>
                <$ReviewSiteOptionsContainer>
                  {editLink != link && (
                    <>
                      {!link.hide_funnel_cta && (
                        <$ReviewSiteToggleIcon
                          onClick={() => toggleActiveReviewSites(link)}
                          data-testid={`toggle-${link.category}-button`}
                        >
                          <Icon
                            icon={
                              link.switch_show
                                ? "radioButtonFilled"
                                : "radioButton"
                            }
                            color="baseDark"
                            viewBox="0 0 20 20"
                            size={20}
                          />
                        </$ReviewSiteToggleIcon>
                      )}
                      <div
                        onClick={() => setEditLink(link)}
                        data-testid={`edit-${link.category}-button`}
                        data-tip="Edit Review Site"
                        data-place="bottom"
                        data-effect="solid"
                        data-background-color={colors.baseDark}
                      >
                        <Icon
                          icon="edit"
                          color="baseDark"
                          viewBox="0 0 20 20"
                          size={20}
                          style={{ verticalAlign: "baseline" }}
                        />
                        <ReactTooltip arrowColor="transparent" />
                      </div>
                    </>
                  )}
                </$ReviewSiteOptionsContainer>
              </CardContainer>
              {editLink == link && (
                <UpdateReviewSite
                  link={link}
                  setEditLink={setEditLink}
                  removeLink={popDeletedLink}
                />
              )}
            </div>
          );
        })}
    </>
  );
};

export default CurrentlyMonitoring;
