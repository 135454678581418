import styled from "styled-components";

export const $Container = styled.div`
  padding: 16px;
  border: 1px solid ${(props) => props.theme.colors.baseLighter};
  border-radius: ${(props) => props.theme.borderRadius.radiusSmall};
  display: flex;
  flex-direction: column;
  gap: 8px;
  button.multi-location-conversion {
    font-size: 12px;
    svg path {
      fill: ${(props) => props.theme.colors.primary1} !important;
    }
  }
`;

export const $TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
