import styled from "styled-components";
import spinner from "@views/builder/sites/assets/spinner.svg";

export const $EditableActionsContainer = styled.div`
  position: absolute;
  visibility: hidden;
  width: fit-content;
  max-width: 100%;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  filter: drop-shadow(0px 16px 32px rgba(33, 33, 33, 0.26));
  &:hover {
    visibility: visible;
  }
`;

export const $EditableActionsSpacer = styled.div`
  height: 16px;
  width: 100%;
  background: transparent;
`;

export const $EditableContentContainer = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  &:ql-editor {
    padding: 12px 16px !important;
  }
`;

export const $EditableContent = styled.div<{
  editMode?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
  fullWidth?: boolean;
}>`
  font-family: "Open Sans";
  width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
  max-width: 100%;
  min-width: 156px;
  margin: 0 auto;
  position: relative;
  border: 1px solid transparent;

  ${(props) =>
    props.isFocused &&
    !props.isDisabled &&
    `
      ~${$EditableActionsContainer} {
        visibility: visible;
      }
    `}

  ${(props) =>
    props.editMode &&
    `
      &:focus, &:active {
      ~${$EditableActionsContainer} {
        visibility: visible;
      }
    }
    `}
`;

export const $EditableOverlay = styled.div<{ isVisible: boolean }>`
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: ${(props) => (props.isVisible ? "block" : "none")};
  background-color: rgba(0, 0, 0, 0.5);
  background-image: URL("${spinner}");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
`;
