import styled from "styled-components";
import iconSearch from "@app/javascript/assets/icons/search-grey.svg";

interface SearchInputProps {
  width: string;
  height: string;
};

export const $SearchInput = styled.input.attrs({ type: "text" })<SearchInputProps>`
  font-family: Open Sans;
  outline: none;
  display: block;
  width: ${({ width }) => width};
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: ${({ height }) => height};
  padding: 10px 10px 10px 44px;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.baseDark};
  background-color: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.baseLighter};
  border-radius: 4px;
  background-image: ${`url(${iconSearch})`};
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position-x: 10px;
  background-position-y: center;

  &:focus {
    border-color: ${(props) => props.theme.colors.primaryBase};
  }

  &::-webkit-input-placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.baseLight};
  }
`;
