import styled from "styled-components";
import { colors } from "@styles/colors";

export const $EditableAction = styled.div`
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px;
  width: 200px;
  text-align: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.grayBorder};
  &:hover {
    background-color: #f4f5f7;
  }
`;

export const $EditableActions = styled.div`
  display: flex;
  justify-content: center;
  font-size: 15px;
  line-height: 15px;
  font-weight: 600;
  color: ${colors.darkBlue};

  border-radius: 4px;
  bottom: -70px;
  cursor: pointer;
  padding: 0px;

  ${$EditableAction}:not(:last-child) {
    border-right: 2px solid ${colors.grayBorder};
  }
`;

export const $EditableActionIcon = styled.div`
  margin-right: 11px;
`;
