import * as React from "react";
import { FunctionComponent as FC } from "react";
import ArrowNext from "@app/javascript/assets/icons/arrow-next.svg";
import nps from "@app/javascript/assets/icons/NPS.svg";
import reviewFirst from "@app/javascript/assets/icons/review-first.svg";
import reviewSites from "@app/javascript/assets/icons/review-sites.svg";
import treeStars from "@app/javascript/assets/icons/tree-stars.svg";
import twoThumbs from "@app/javascript/assets/icons/two-thumbs.svg";
import Button from "@atoms/Button";
import CheckboxPanel, {
  CheckboxType,
} from "@components/elements/CheckBoxPanel";
import { RatingTemplatesTypes } from "@components/funnel/ratingType/RatingTypeTemplates";
import {
  $ButtonContainer,
  $RatingTypesBody,
  $RatingTypesContainer,
  $RatingTypesPanel,
  $RatingTypesTitle,
} from "./RatingTypes.sc";

export interface IRatingTypesProps {
  template: string;
  onTemplateChange: (template: string) => void;
  onConfirm: () => void;
}

const RatingTypes: FC<IRatingTypesProps> = ({
  template,
  onTemplateChange,
  onConfirm,
}) => {
  const checkBoxes: Array<CheckboxType> = [
    { title: "Star", icon: treeStars, value: RatingTemplatesTypes.STARS },
    { title: "Thumbs", icon: twoThumbs, value: RatingTemplatesTypes.THUMBS },
    { title: "NPS", icon: nps, value: RatingTemplatesTypes.NPS },
    {
      title: "Review Sites",
      icon: reviewSites,
      value: RatingTemplatesTypes.REVIEW_SITES_LARGE,
    },
    {
      title: "Review First",
      icon: reviewFirst,
      value: RatingTemplatesTypes.REVIEW_FIRST,
    },
  ];

  return (
    <$RatingTypesContainer>
      <$RatingTypesTitle>Rating Type</$RatingTypesTitle>
      <$RatingTypesBody>
        Please select a rating type from the list below
      </$RatingTypesBody>
      <$RatingTypesPanel>
        <CheckboxPanel
          selectedValue={template}
          data={checkBoxes}
          onSelectionChange={onTemplateChange}
        />
      </$RatingTypesPanel>
      <$ButtonContainer>
        <Button onClick={onConfirm} rightIcon={ArrowNext}>
          Next
        </Button>
      </$ButtonContainer>
    </$RatingTypesContainer>
  );
};

export default RatingTypes;
