import React, { FunctionComponent, useContext, useState, useRef } from "react";
import { FunnelContext } from "../../builder/FunnelContext";
import { IRatingTypeProps } from "../RatingTypeTemplates";
import { $RatingStarButton } from "../Star/Star.sc";
import H from "@components/atoms/Typography/Header";
import { $CenteredContainer } from "./ReviewFirst.sc";
const ratingLabelTo10Scale = {
  rating_1: 2,
  rating_2: 4,
  rating_3: 6,
  rating_4: 8,
  rating_5: 10,
};
const ReviewFirst: FunctionComponent<IRatingTypeProps> = ({
  setStarRating: propsSetStarRating,
}) => {
  const starRef = useRef(null);
  const { funnelData, updateBackend, updateFrontend } =
    useContext(FunnelContext);

  const [hoveredStar, setHoveredStar] = useState<number>(0);
  const [starRating, setStarRating] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const trackDestination = urlParams.has("utm_campaign")
    ? "outbound"
    : "onsite";

  function starClick(key, rating) {
    setStarRating(ratingLabelTo10Scale[key]);
    funnelData.funnelFlow.selectedRatingString = key;
    funnelData.funnelFlow.selectedRatingOn10Scale = starRating;
    setHoveredStar(rating);
    propsSetStarRating(rating);
  }

  const renderStars = () => {
    let stars = [];

    for (let rating = 1; rating <= 5; rating++) {
      const key = `rating_${rating}`;
      stars.push(
        <$RatingStarButton
          ref={starRef}
          data-testid={`funnel-rating-star-${rating}`}
          data-rating={ratingLabelTo10Scale[key]}
          data-track-name="funnel_click"
          data-track-key={key}
          data-track-text={
            funnelData.backend.content_modules_attributes[
              `rating_${rating}_label`
            ]
          }
          data-track-destination={trackDestination}
          data-track-context="rating"
          data-track-group="quinary"
          data-track-sentiment={rating > 3 ? "positive" : "negative"}
          onMouseEnter={() => {
            starRating == null && setHoveredStar(rating);
          }}
          onMouseLeave={() => {
            starRating == null && setHoveredStar(0);
          }}
          onClick={() => {
            starClick(key, rating);
          }}
          active={rating <= hoveredStar}
          key={`funnel-star-rating-${rating}`}
        />
      );
    }

    return stars;
  };

  return (
    <div
      data-testid="funnel-review-first-template"
      className="review-first-template"
    >
      <$CenteredContainer className="header-text-review-first">
        <H
          size="3"
          color={
            funnelData.backend.config_modules_attributes.text_color ||
            "catalinaBlue"
          }
          fontWeight="bold"
        >
          Leave a Rating
        </H>
      </$CenteredContainer>
      <$CenteredContainer className="stars-container">
        {renderStars()}
      </$CenteredContainer>
    </div>
  );
};

export default ReviewFirst;
