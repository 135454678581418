import React, {
  FunctionComponent as FC,
  useState,
  useEffect,
  useContext,
} from "react";
import { FunnelContext } from "../FunnelContext";
import EditableRichText from "@components/elements/EditableRichText";
import { extractTextFromHtml } from "@utils/utils";
import { isEditMode } from "@utils/funnel";
import {
  $FunnelBodyContainer,
  $FunnelPageEmptyPlaceholder,
} from "./FunnelBodyCopy.sc";
import P from "@components/atoms/Typography/Text";
import { useMutation } from "react-query";
import {
  IPageUpdateParams,
  updatePage,
} from "@components/funnel/hooks/usePageUpdate";
import { useAlert } from "react-alert";

export interface IFunnelBodyCopyProps {
  contentKey: string;
}

const FunnelBodyCopy: FC<IFunnelBodyCopyProps> = ({ contentKey }) => {
  const { funnelData, updateFrontend } = useContext(FunnelContext);
  const {
    site_attributes,
    page_attributes,
    content_modules_attributes
  } = funnelData.backend;
  const [updatingBody, setUpdatingBody] = useState<boolean>(false);
  const [plainText, setPlainText] = useState<string>(
    extractTextFromHtml(
      content_modules_attributes[contentKey]
    )
  );
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(
    plainText.trim() === "" && isEditMode(funnelData)
  );
  const [isEditorFocused, setIsEditorFocused] = useState<boolean>(false);
  const alert = useAlert();

  useEffect(() => {
    setPlainText(
      extractTextFromHtml(
        content_modules_attributes[contentKey]
      )
    );
  }, [content_modules_attributes[contentKey]]);

  useEffect(() => {
    setShowPlaceholder(plainText.trim() === "" && isEditMode(funnelData));
  }, [
    plainText,
    funnelData.frontend.previewMode,
    funnelData.frontend.presentMode,
  ]);

  const mutation = useMutation((params: IPageUpdateParams) =>
    updatePage(params)
  );

  const onBodyChange = (text: string) => {
    setUpdatingBody(true);

    mutation.mutate(
      {
        site_uuid: site_attributes.uuid,
        page_uuid: page_attributes.uuid,
        funnel_data: {
          content_modules_attributes: {
            id: content_modules_attributes.id,
            [contentKey]: text
          }
        }
      },
      {
        onSuccess: (responseData) => {
          setUpdatingBody(false);
          setIsEditorFocused(false);
          funnelData.backend = responseData;
          updateFrontend();
          setShowPlaceholder(
            extractTextFromHtml(text).trim() === "" && isEditMode(funnelData)
          );
        },
        onError: (error) => {
          setUpdatingBody(false);
          setIsEditorFocused(false);
          //@ts-ignore
          const errorMessage = error.response?.data?.errors ? error.response.data.errors.join(", ") : "Please try again later.";

          alert.error(
            <>
              <h3>Something went wrong.</h3>
              <p>{errorMessage}</p>
            </>
          );
        },
      }
    );
  };

  return (
    <$FunnelBodyContainer className="funnel-body-copy-container">
      {showPlaceholder ? (
        <$FunnelPageEmptyPlaceholder
          onClick={() => {
            setShowPlaceholder(false);
            setIsEditorFocused(true);
          }}
        >
          <P color="baseDarker" margin="20px 0">
            Here’s a place for you to enter engaging text or ask for feedback.
            Remember to check for typos!
          </P>
        </$FunnelPageEmptyPlaceholder>
      ) : (
        <EditableRichText
          fieldName="Body Copy"
          fullWidth={true}
          isEditable={isEditMode(funnelData)}
          isEditorFocused={isEditorFocused}
          text={content_modules_attributes[contentKey]}
          onChange={onBodyChange}
          updating={updatingBody}
        />
      )}
    </$FunnelBodyContainer>
  );
};

export default FunnelBodyCopy;
