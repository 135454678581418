import React, { FunctionComponent as FC, useState, useContext } from "react";
import {
  FunnelContext,
  ReviewSiteLinkType,
} from "@components/funnel/builder/FunnelContext";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
  DropResult,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import {
  $DroppableContainer,
  $ReviewSiteListWrapper,
} from "./ReviewSiteList.sc";
import { isEditMode } from "@utils/funnel";
import { Tooltip } from "@styles/components/elements/Links";
import EditableActionsComponent from "@components/elements/EditableActionsComponent";
import Icon from "@components/atoms/Icon";
import ReviewSiteDraggableListItem from "@components/funnel/builder/ReviewSiteDraggableListItem";
import ReviewSiteEmptyState from "@components/funnel/builder/ReviewSiteList/ReviewSiteEmptyState";

const ReviewSiteList: FC = () => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { funnelData, updateBackend, updateFrontend } =
    useContext(FunnelContext);
  const links = funnelData.backend.links_attributes;
  const activeFunnelPage = funnelData.frontend.activeFunnelPage;
  const gapSize = links.length > 2 ? 32 : 64;
  const sites = links.length;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reviewSites = reorderReviewSites(
      funnelData.backend.links_attributes,
      result.source.index,
      result.destination.index
    );

    funnelData.backend.links_attributes = reviewSites;
    updateBackend();
  };

  const reorderReviewSites = (
    list: ReviewSiteLinkType[],
    startIndex: number,
    endIndex: number
  ): ReviewSiteLinkType[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((link, index) => (link.sort_order = index + 1));

    return result;
  };

  const handleFocus = (focused: boolean) => {
    setIsFocused(focused);
  };

  const ReviewSiteDraggableList = () => {
    return !links.length ? (
      <ReviewSiteEmptyState />
    ) : (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <$DroppableContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
              gapSize={gapSize}
              sites={sites}
            >
              {links.map(
                (link, index) =>
                  link.switch_show && !link.hide_funnel_cta && (
                    <Draggable
                      key={link.uuid}
                      draggableId={link.uuid}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <ReviewSiteDraggableListItem
                          provided={provided}
                          snapshot={snapshot}
                          link={link}
                          isEditable={isEditMode(funnelData)}
                        />
                      )}
                    </Draggable>
                  )
              )}
              {provided.placeholder}
            </$DroppableContainer>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  function openReviewSiteNavigation() {
    funnelData.frontend.openSettings = true;
    funnelData.frontend.settingsTab = 2;
    updateFrontend();
  }

  const editableReviewSites = () => {
    return (
      <$ReviewSiteListWrapper
        className="review-site-wrapper"
        onMouseOut={() => handleFocus(false)}
        onMouseOver={() => handleFocus(true)}
      >
        <Tooltip
          id="review-site-item-action"
          effect="solid"
          delayShow={100}
          place="top"
          textColor="white"
          backgroundColor="#06517D"
        />

        <EditableActionsComponent
          fieldName="Review Site"
          topChildren={ReviewSiteDraggableList()}
          actions={[
            {
              title: "Add a Review Site",
              logo: <Icon icon="squarePlus" size={24} viewBox="0 0 24 24" />,
              onClick: openReviewSiteNavigation,
            },
          ]}
          isFocused={isFocused && isEditMode(funnelData)}
          isDisabled={!isEditMode(funnelData)}
          fullWidth={true}
        />
      </$ReviewSiteListWrapper>
    );
  };

  return activeFunnelPage == 0
    ? ReviewSiteDraggableList()
    : editableReviewSites();
};

export default ReviewSiteList;
