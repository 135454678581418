import React, { useState, useContext, useRef } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { $RecaptchaStyle } from "@styles/components/elements/Captcha";
import ReCAPTCHA from "react-google-recaptcha";
import { useMutation } from "react-query";
import {
  ImutationParams,
  sendFunnelTest,
} from "@components/funnel/hooks/useSendFunnelTest";
import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import PhoneInput from "react-phone-input-2";
import "../../../styles/reactphoneinput.css"

import P from "../../atoms/Typography/Text";
import Label from "../../atoms/Typography/Label";
import Button from "../../atoms/Button";
import { $SubmitButtonContainer } from "./SendFunnelTestForm.sc";

import { BaseInput } from "@styles/components/elements/Inputs";
import {
  Dropdown,
  Flag,
  phoneInputStyle,
} from "@styles/components/elements/PhoneInput";

export interface ISendFunnelTestForm {
  mode: "email" | "sms";
  siteUuid: string;
  pageUuid: string;
  isWhiteLabeledUser: boolean;
  onSuccessfulSubmit: () => void;
}

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

const errorFields = (value) => {
  return (
    <P size="3" color="darkError">
      {value && value.message}
    </P>
  );
};

const SendFunnelTestForm: React.FC<ISendFunnelTestForm> = ({
  mode,
  siteUuid,
  pageUuid,
  isWhiteLabeledUser,
  onSuccessfulSubmit,
}) => {
  const { funnelData } = useContext(FunnelContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const { handleSubmit, register, errors, formState } = useForm<IFormData>({
    mode: "onChange",
  });
  const mutation = useMutation((params: ImutationParams) =>
    sendFunnelTest(params)
  );
  const [submitErrors, setSubmitErrors] = useState<string>();
  const alert = useAlert();

  const recaptchaRef = useRef();

  const resetRecaptcha = () =>
    // @ts-ignore
    recaptchaRef.current.reset();

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };

  const isRequired = (email) => {
    if (email) {
      return true;
    } else {
      return phoneNumber ? true : false;
    }
  };

  const onSubmit = async (data: IFormData) => {
    // @ts-ignore
    const captchaToken = await recaptchaRef.current.getValue();
    // @ts-ignore
    recaptchaRef.current.reset();

    mutation.mutate(
      {
        site_uuid: siteUuid,
        page_uuid: pageUuid,
        "g-recaptcha-response": captchaToken,
        mail_recipient: {
          phone_number: phoneNumber,
          email_address: data.email,
          first_name: data.firstName,
          last_name: data.lastName,
          is_test: true,
          react: "react"
        },
      },
      {
        onSuccess: () => {
          setSubmitErrors(null);
          setPhoneNumber("");
          alert.success(<h3>We're sending your message.</h3>);
          onSuccessfulSubmit();
        },
        onError: () => {
          alert.error(
            <>
              <h3>Something went wrong.</h3>
              <p>Please try again later.</p>
            </>
          );
        },
      }
    );
  };

  return (
    <form
      className="signUpForm"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      {submitErrors && (
        <div className="row">
          <div className="col-md-12">
            <P color="darkError">{submitErrors}</P>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          {mode == "email" && (
            <>
              <Label>Email*</Label>
              <BaseInput
                name="email"
                placeholder="name@example.com"
                errorMessage={!!errors.email}
                ref={register({
                  validate: isRequired,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
              {errorFields(errors.email)}
            </>
          )}
          {mode == "sms" && (
            <>
              <Label>Phone Number*</Label>
              <PhoneInput
                disableDropdown={false}
                preferredCountries={["us", "au", "nz", "ca", "sw", "de"]}
                inputStyle={phoneInputStyle}
                buttonStyle={Flag}
                dropdownStyle={Dropdown}
                country={"us"}
                onChange={handlePhoneChange}
                inputProps={{
                  name: "phoneNumber",
                  placeholder: "+1 (555) 555-5555",
                }}
              />
            </>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Label>First Name*</Label>
          <BaseInput
            name="firstName"
            placeholder="e.g. John"
            errorMessage={!!errors.firstName}
            ref={register({
              required: "First name required",
            })}
          />
          {errorFields(errors.firstName)}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Label>Last Name</Label>
          <BaseInput
            name="lastName"
            placeholder="e.g. Doe"
            ref={register({})}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <br />
          <$RecaptchaStyle>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={funnelData.backend.recaptcha_site_key}
              onExpired={resetRecaptcha}
              onErrored={resetRecaptcha}
            />
          </$RecaptchaStyle>
        </div>
      </div>
      <$SubmitButtonContainer className="row">
        <div className="col-md-12">
          <Button disabled={!formState.isValid} onClick={handleSubmit(onSubmit)}>Send Test</Button>
        </div>
      </$SubmitButtonContainer>
    </form>
  );
};

export default SendFunnelTestForm;
