import styled from "styled-components";
import { colors } from "@styles/colors";

export const $RoundCheckboxContainer = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
    top: 3px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${(props) => props.theme.colors.primary1};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${(props) => props.theme.colors.primary1};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 17px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const $HorizontalSpacer1 = styled.div`
  display: inline-block;
  margin-left: 15px;
`;

export const $HorizontalSpacer2 = styled.div`
  display: inline-block;
  margin-top: 21px;
`;

export const $Paragraph = styled.p`
  font-family: "Open Sans";
  color: ${colors.hellGray};
`;
