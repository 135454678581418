import React, { FunctionComponent as FC, useContext } from "react";
import { FunnelContext } from "../FunnelContext";
import FunnelBodyCopy from "../FunnelBodyCopy";

import DirectFeedbackTitle from "../DirectFeedbackTitle";
import DirectFeedbackForm from "../DirectFeedbackForm";
import FunnelPageFooterInteractive from "../FunnelPageFooterInteractive";
import { $DirectFeedbackFormContainer } from "./DirectFeedback.sc";
import { $FunnelPageFooter } from "../LandingPage/LandingPage.sc";
import LandingPageTitle from "@components/funnel/builder/LandingPageTitle";
import ProfileLogo from "@components/funnel/builder/ProfileLogo";

const DirectFeedback: FC = () => {
  const { funnelData } = useContext(FunnelContext);

  return (
    <div data-testid="funnel-direct-feedback">
      <ProfileLogo />
      <div style={{ height: "16px" }} />
      <LandingPageTitle />
      <DirectFeedbackTitle />
      <FunnelBodyCopy contentKey="choice_0_prompt" />

      <$DirectFeedbackFormContainer className="direct-feedback-form-container">
        <DirectFeedbackForm
          className="direct-feedback-form funnel-review-first"
          prelude={funnelData.funnelFlow.selectedRatingString}
        />
      </$DirectFeedbackFormContainer>
      <$FunnelPageFooter
        className="direct-feedback-form-footer"
        mobileMode={funnelData.frontend.mobileMode}
      >
        <FunnelPageFooterInteractive
          clickablePrefix={"Change rating"}
          middle={
            " or if you do not wish to address your concerns here and prefer to post a review,\n"
          }
          clickableSuffix={" click here."}
        />
      </$FunnelPageFooter>
    </div>
  );
};

export default DirectFeedback;
