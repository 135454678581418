import React, { FC, useContext } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import FeedbackFormPhoneInput from "./FeedbackFormPhoneInput";
import FeedbackFormEmailInput from "./FeedbackFormEmailInput";
import FeedbackFormToggleButton from "./FeedbackFormToggleButton";

export interface IFeedbackFormFieldsProps {
  editMode: boolean;
  register: any;
  errors: any;
  onToggleChange: (field, show) => void;
}

const FeedbackFormFields: FC<IFeedbackFormFieldsProps> = ({
  editMode,
  register,
  errors,
  onToggleChange,
}) => {
  const { funnelData } = useContext(FunnelContext);

  const { show_direct_feedback_phone_input, show_direct_feedback_email_input } =
    funnelData.backend.config_modules_attributes;

  if (!editMode) {
    return (
      <>
        {show_direct_feedback_phone_input && (
          <FeedbackFormPhoneInput {...{ register, errors }} />
        )}
        {show_direct_feedback_email_input && (
          <FeedbackFormEmailInput {...{ register, errors }} />
        )}
      </>
    );
  }

  return (
    <>
      <FeedbackFormPhoneInput {...{ register, errors }} />
      <FeedbackFormToggleButton
        name="phone"
        showInput={show_direct_feedback_phone_input}
        onToggleChange={onToggleChange}
      />

      <FeedbackFormEmailInput {...{ register, errors }} />
      <FeedbackFormToggleButton
        name="email"
        showInput={show_direct_feedback_email_input}
        onToggleChange={onToggleChange}
      />
    </>
  );
};

export default FeedbackFormFields;
