import styled from "styled-components";
import activeStar from "../../../../assets/icons/funnel/star-active.svg";
import inactiveStar from "../../../../assets/icons/funnel/star-inactive.svg";
type RatingStarProps = {
  active: boolean;
};

export const $RatingStarButton = styled.div<RatingStarProps>`
  width: 40px;
  height: 40px;
  display: inline-block;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &:not(:last-child) {
    margin-right: 15px;
  }
  background-image: ${(props) =>
    props.active ? `URL(${activeStar})` : `URL(${inactiveStar})`};
`;
