import React, { FunctionComponent, useState, useContext } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";

import { FunnelRatingContainer } from "@styles/components/funnel/Builder";
import { IRatingTypeProps } from "../RatingTypeTemplates";
import { $RatingStarButton } from "./Star.sc";
import { $DescriptionText } from "../RatingTypeDescription.sc";

const ratingLabelTo10Scale = {
  rating_1: 2,
  rating_2: 4,
  rating_3: 6,
  rating_4: 8,
  rating_5: 10,
};

const RatingTypeStar: FunctionComponent<IRatingTypeProps> = ({
  handleRatingClick,
}) => {
  const { funnelData } = useContext(FunnelContext);
  const urlParams = new URLSearchParams(window.location.search);
  const trackDestination = urlParams.has("utm_campaign")
    ? "outbound"
    : "onsite";

  const [hoveredRating, setHoveredRating] = useState<number>(0);

  let label = 'Select a Rating'
  if(hoveredRating) {
    label = funnelData.backend.content_modules_attributes[`rating_${hoveredRating}_label`]
  }

  const renderStars = () => {
    let stars = [];

    for (let rating = 1; rating <= 5; rating++) {
      const key = `rating_${rating}`;
      stars.push(
        <$RatingStarButton
          data-testid={`funnel-rating-star-${rating}`}
          data-rating={ratingLabelTo10Scale[key]}
          data-track-name="funnel_click"
          data-track-key={key}
          data-track-text={
            funnelData.backend.content_modules_attributes[
              `rating_${rating}_label`
            ]
          }
          data-track-destination={trackDestination}
          data-track-context="rating"
          data-track-group="quinary"
          data-track-sentiment={rating > 3 ? "positive" : "negative"}
          onClick={handleRatingClick}
          onMouseEnter={() => setHoveredRating(rating)}
          onMouseLeave={() => setHoveredRating(0)}
          active={rating <= hoveredRating}
          key={`funnel-star-rating-${rating}`}
        />
      );
    }

    return stars;
  };

  return (
    <FunnelRatingContainer>
      {renderStars()}
      <$DescriptionText>{label}</$DescriptionText>
    </FunnelRatingContainer>
  )
};

export default RatingTypeStar;
