import React, { FunctionComponent as FC } from "react";
import { $RoundCheckboxContainer } from "./RoundCheckbox.sc";

export interface IRoundCheckbox {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RoundCheckbox: FC<IRoundCheckbox> = ({ checked, onChange }) => {
  return (
    <$RoundCheckboxContainer>
      <label className="switch">
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(e)}
          data-testid="toggle"
        />
        <span className="slider round"></span>
      </label>
    </$RoundCheckboxContainer>
  );
};

export default RoundCheckbox;
