import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useRef,
} from "react";
import AccordionItem from "@traject/reviewspod.molecules.accordion-item";
import { AddReviewSiteContext } from "./index";
import ReviewSiteForm from "./ReviewSiteForm";
import ReviewSiteTile from "./ReviewSiteTile";

export interface IReviewSiteItemProps {
  categoryId: string;
  title: string;
  targets_attributes: any;
}

const AddReviewSiteItem: FC<IReviewSiteItemProps> = ({
  categoryId,
  title,
  targets_attributes,
}) => {
  const { activeTarget, clearActiveTarget, removeSelectedTarget } =
    useContext(AddReviewSiteContext);

  const ref = useRef({});

  useEffect(() => {
    // @ts-ignore
    if (ref.current.resizeItem) ref.current?.resizeItem();
  }, [activeTarget]);

  const onClick = () => {
    if (activeTarget?.categoryId && activeTarget?.categoryId !== categoryId) {
      removeSelectedTarget(activeTarget.categoryId, activeTarget.uuid);
      clearActiveTarget();
    }
  };

  if (targets_attributes.length === 0) return null;

  return (
    <AccordionItem
      key={`review-site-accordion-item-${categoryId}`}
      testId={`review-site-accordion-item-${categoryId}`}
      active="primary2"
      title={title}
      itemId={categoryId}
      ref={ref}
      onClick={onClick}
    >
      {activeTarget && activeTarget.categoryId === categoryId ? (
        <ReviewSiteForm
          key={`review-site-form-active-${categoryId}`}
          categoryId={categoryId}
          target={activeTarget}
        />
      ) : (
        targets_attributes.map((target) => {
          return (
            <ReviewSiteTile
              key={`site-logo-tile-${categoryId}-${target.uuid}`}
              target={{ ...target, categoryId }}
            />
          );
        })
      )}
    </AccordionItem>
  );
};

export default AddReviewSiteItem;
