import { useQuery } from "react-query";
import axios from "axios";

const getUserData = async () => {
  const { data } = await axios.get("/funnel/user_permissions");
  return data;
};

export default function useUserAttributes() {
  return useQuery("user", getUserData);
}
