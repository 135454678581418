import styled from "styled-components";

export const $ColorPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  position: relative;
`;

export const $HexColorPicker = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const $HexColorPickerContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 48px;
`;

export const $ColorInput = styled.div<{
  backgroundColor: string;
  name: string;
  size: string;
}>`
  width: ${(props) => (props.size == "large" ? "48px" : "24px")};
  height: ${(props) => (props.size == "large" ? "48px" : "24px")};
  appearance: none;
  padding: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-sizing: border-box;
  border-radius: 4px;
  border: 2px solid ${(props) => props.theme.colors.baseLighter};
`;

export const $ColorInputLabel = styled.input<{
  error: boolean;
  area: string;
}>`
  height: ${(props) => (props.area == "large" ? "48px" : "24px")};
  left: 32.94%;
  right: 0%;
  top: 0px;
  width: ${(props) => (props.area == "large" ? "114px" : "72px")};
  background: ${(props) => props.theme.colors.baseWhite};
  border: 2px solid
    ${(props) =>
      props.error
        ? props.theme.colors.darkWarning
        : props.theme.colors.baseLighter};
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: ${(props) => (props.area == "large" ? "16px" : "4px")};
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => (props.area == "large" ? "18px" : "12px")};
  line-height: 28px;
  color: #212121;
`;
