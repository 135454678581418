import styled from "styled-components";

type IRadioProps = {};

export const $RadioContainer = styled.div<IRadioProps>`
  display: flex;
  align-items: center;
  flex-basis: 100%;
  flex: 1;
`;

export const $RadioLabel = styled.label<{
  area: string;
}>`
  padding-left: 6px;
  font-weight: normal;
  margin-bottom: 0px;
  margin-top: 4px;
  font-size: ${(props) => (props.area ? props.area : "13")}px;
`;

export const $RadioButton = styled.input.attrs({ type: "radio" })<{
  area: string;
}>`
  height: ${(props) => props.area}px;
  width: ${(props) => props.area}px;
`;
