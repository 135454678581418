import React from "react";
import Modal from "react-modal";
import { modalStyleWithProps } from "@molecules/Modal";
import { $FeedbackFormModalContainer } from "./DirectFeedbackForm.sc";
import Theme from "@traject/reviewspod.base_ui.theme";
import Icon from "@atoms/Icon";

export interface IFeedbackFormModalProps {
  header: string;
  message: string;
  onClose: () => void;
}

const FeedbackFormModal = ({
  header,
  message,
  onClose,
}: IFeedbackFormModalProps) => (
  <Theme theme="gus">
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      style={modalStyleWithProps("656px", "452px")}
    >
      <$FeedbackFormModalContainer className="feedback-form-container">
        <div className="close-button">
          <span onClick={onClose}>
            <Icon icon="close" size={24} color="baseDark" />
          </span>
        </div>
        <div className="header-copy">{header}</div>
        <div className="content-copy">{message}</div>
      </$FeedbackFormModalContainer>
    </Modal>
  </Theme>
);

export default FeedbackFormModal;
