import React, { FunctionComponent as FC, useContext } from "react";
import ReviewSiteList from "@components/funnel/builder/ReviewSiteList";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import ReviewSiteEmptyState from "@components/funnel/builder/ReviewSiteList/ReviewSiteEmptyState";

const RatingTypesReviewSites: FC = () => {
  const { funnelData } = useContext(FunnelContext);
  const links = funnelData.backend.links_attributes;

  return !links.length ? <ReviewSiteEmptyState /> : <ReviewSiteList />;
};

export default RatingTypesReviewSites;
