import React, { useContext } from "react";

import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import {
  $PreviewLink,
  ShortNameInputMaxCharacter,
} from "@components/funnel/builder/FunnelHeader/FunnelHeader.sc";
import { colors } from "@styles/colors";
import ReactTooltip from "react-tooltip";

const PreviewLink = ({ urlBarMaxWidth }) => {
  const { funnelData } = useContext(FunnelContext);

  const { full_url, display_url } = funnelData.backend.site_attributes;

  return (
    <>
      <$PreviewLink
        href={full_url}
        target="_blank"
        style={{ width: urlBarMaxWidth }}
        data-tip={full_url}
        data-place="bottom"
        data-effect="solid"
        data-background-color={colors.baseDarker}
        data-tip-disable={full_url.length < ShortNameInputMaxCharacter}
        data-arrow-color="transparent"
      >
        {display_url}
      </$PreviewLink>
      <ReactTooltip />
    </>
  );
};

export default PreviewLink;
