import React, {
  FunctionComponent as FC,
  useContext,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import {
  ImutationParams,
  updateSite,
} from "@components/funnel/hooks/useSiteUpdate";
import {
  $BaseURLText,
  ShortNameInputMaxCharacter,
  $ShortNameInput,
} from "./FunnelHeader.sc";
import { useAlert } from "react-alert";
import { useMutation } from "react-query";
import { colors } from "@styles/colors";
import HoverTab from "@atoms/HoverTab";

const SlugEditor: FC = ({}) => {
  const alert = useAlert();
  const ref = useRef(null);
  const { funnelData, updateFrontend } = useContext(FunnelContext);
  const mutation = useMutation((params: ImutationParams) => updateSite(params));
  const { site_attributes, page_attributes } = funnelData.backend;

  function handleSlug(e) {
    let slug = e.target.value;
    if (slug !== site_attributes.slug) {
      updateSlug(slug);
    }
  }

  function updateSlug(value) {
    mutation.mutate(
      {
        id: site_attributes.id,
        site_uuid: site_attributes.uuid,
        page_id: page_attributes.id,
        site: { slug: value },
      },
      {
        onSuccess: (responseData) => {
          funnelData.backend = responseData;
          updateFrontend();
          alert.success(<p>Save was successful</p>);
        },
        onError: () => {
          ref.current.value = site_attributes.slug;
          setText(site_attributes.slug);
          alert.error(
            <>
              <h3>Your shortname could not be changed</h3>
              <p>Please enter an available shortname.</p>
            </>
          );
        },
      }
    );
  }

  const calculateSlugWidth = (): string => {
    const baseURLWidth = document.querySelector(
      ".edit-url-wrapper p"
    ).clientWidth;
    const urlBarWidth = document.querySelector(
      ".funnel-header-container"
    ).clientWidth;
    return `${urlBarWidth - baseURLWidth - 210}px`;
  };

  const [slugInputMaxWidth, setSlugInputMaxWidth] = useState<string>("0px");
  const [text, setText] = useState<string>(site_attributes.slug);

  useEffect(() => {
    setSlugInputMaxWidth(calculateSlugWidth);
  }, []);

  useEffect(() => {
    setText(site_attributes.slug);
  }, [site_attributes.slug]);

  useLayoutEffect(() => {
    function updateSize() {
      setSlugInputMaxWidth(calculateSlugWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <>
      <$BaseURLText>{site_attributes.base_url}</$BaseURLText>
      {(!Boolean(site_attributes.domain) || (Boolean(site_attributes.domain) && Boolean(site_attributes.domain_is_shared))) && (
        <HoverTab
          fieldName="Short Name"
          styling={{ display: "inline-block", lineHeight: "36px" }}
        >
          <$ShortNameInput
            id="shortName"
            ref={ref}
            value={text}
            autoComplete="off"
            style={{ width: slugInputMaxWidth }}
            onChange={(e) => setText(e.target.value)}
            onBlur={(e) => handleSlug(e)}
            data-place="bottom"
            data-effect="solid"
            data-background-color={colors.darkBlue}
            data-tip={text}
            data-tip-disable={text.length < ShortNameInputMaxCharacter}
            data-arrow-color="transparent"
          />
        </HoverTab>
      )}
    </>
  );
};

export default SlugEditor;
