import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useState,
} from "react";
import P from "@atoms/Typography/Text";
import CardContainer from "@atoms/CardContainer";
import ColorPicker from "@atoms/ColorInput";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { colors } from "@styles/colors";
import CustomCSSInput from "@components/funnel/settings/CustomCSS";
const Appearance: FC = () => {
  const { funnelData, updateBackend } = useContext(FunnelContext);
  const configModules = funnelData.backend.config_modules_attributes;
  const [textColor, setTextColor] = useState<string>(
    configModules.text_color || colors.darkBlue
  );
  const [backgroundColor, setBackgroundColor] = useState<string>(
    configModules.background_color || colors.baseWhite
  );
  const [buttonColor, setButtonColor] = useState<string>(
    configModules.button_color || colors.primary1
  );

  function updateAppearanceSettings() {
    configModules.text_color = textColor;
    configModules.button_color = buttonColor;
    configModules.background_color = backgroundColor;
    updateBackend();
  }

  useEffect(() => {
    updateAppearanceSettings();
  }, [textColor, buttonColor, backgroundColor]);

  return (
    <>
      <P color="baseDarkest" margin="16px 0" fontWeight={600}>
        Colors
      </P>
      <CardContainer justify="space-between" margin="8px 0">
        <P>Header Text</P>
        <ColorPicker
          color={textColor}
          onColorChange={setTextColor}
          size="small"
        />
      </CardContainer>
      <CardContainer justify="space-between" margin="8px 0">
        <P>Button/Link</P>
        <ColorPicker
          color={buttonColor}
          onColorChange={setButtonColor}
          size="small"
        />
      </CardContainer>
      <CardContainer justify="space-between" margin="8px 0">
        <P>Background</P>
        <ColorPicker
          color={backgroundColor}
          onColorChange={setBackgroundColor}
          size="small"
        />
      </CardContainer>
      <P color="baseDarkest" margin="16px 0" fontWeight={600}>
        CSS
      </P>
      <CustomCSSInput />
    </>
  );
};

export default Appearance;
