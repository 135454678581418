import { useQuery } from "react-query";
import axios from "axios";

const getCategoriesData = async () => {
  const { data } = await axios.get('/builder/categories.json');
  return data;
}

export default function useCategories() {
  return useQuery('categories', getCategoriesData);
}
