import axios from "axios";
import { getCSRFToken } from "@utils/utils";

export interface IPageUpdateParams {
  site_uuid: string;
  page_uuid: string;
  funnel_data: any;
}

export const updatePage = async (params: IPageUpdateParams) => {
  const { data } = await axios.post("/funnel/update_funnel_data", params, {
    headers: { "X-CSRF-TOKEN": getCSRFToken() },
  });
  return data;
};
