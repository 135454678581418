import React, { FunctionComponent as FC, useContext, useEffect } from "react";
import { FunnelPageType } from "@components/funnel/builder/MainContainer";
import {
  $FunnelThresholdColumn,
  $FunnelThresholdGroup,
} from "./FunnelThreshold.sc";
import Radio from "@components/atoms/Radio";
import {
  chooseThresholdLabel,
  handleOnClick,
  isPositiveReview,
  loadDefaultValues,
  thresholdChecked,
} from "./utils";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";

export const POSITIVE_THRESHOLD_KEYS = [9, 4, 8, 3, 7, 2, 6, 1, 5, 0]; // 10..1
export const NEGATIVE_THRESHOLD_KEYS = [8, 3, 7, 2, 6, 1, 5, 0, 4, -1]; // 10..1
export const DEFAULT_POSITIVE_THRESHOLD = "3+";
export const DEFAULT_NEGATIVE_THRESHOLD = "2-";
export const DEFAULT_SEGMENTING_THRESHOLD = 2.0;
const THRESHOLD_UPDATES = {
  "10": "9-",
  "9+": "8-",
  "8+": "7-",
  "7+": "6-",
  "6+": "5-",
  "5+": "4-",
  "4+": "3-",
  "3+": "2-",
  "2+": "1-",
  "1+": "0",
};

export interface INPSFunnelThresholdProps {
  type: FunnelPageType;
  disabled: boolean;
}

const npsThresholdName = (type: FunnelPageType) =>
  `${isPositiveReview(type) ? "positive" : "negative"}NPSThreshold`;

const npsThresholdData = (type: FunnelPageType) => {
  const thresholdKeys = isPositiveReview(type)
    ? POSITIVE_THRESHOLD_KEYS
    : NEGATIVE_THRESHOLD_KEYS;

  const data = thresholdKeys.map((thresholdKey) => {
    let operator;
    let description;
    if ([9, -1].includes(thresholdKey)) {
      operator = "";
      description = (thresholdKey + 1) +  " only";
    } else if (isPositiveReview(type)) {
      operator = "+";
      description = (thresholdKey + 1) +  " or higher";
    } else {
      operator = "-";
      description = (thresholdKey + 1) + " or lower";
    }
    return [
      `${thresholdKey}${operator}`,
      `${description}`,
      `${thresholdKey}`,
    ];
  });
  return data;
};

const NPSFunnelThreshold: FC<INPSFunnelThresholdProps> = ({
  type,
  disabled,
}) => {
  const thresholdName = npsThresholdName(type);
  const { funnelData, updateBackend } = useContext(FunnelContext);

  useEffect(() => {
    loadDefaultValues(
      type,
      funnelData,
      updateBackend,
      DEFAULT_POSITIVE_THRESHOLD,
      DEFAULT_NEGATIVE_THRESHOLD,
      DEFAULT_SEGMENTING_THRESHOLD
    );
  }, []);

  const onClick = (thresholdValue, thresholdKey) => {
    handleOnClick(
      thresholdValue,
      thresholdKey,
      type,
      funnelData,
      updateBackend,
      THRESHOLD_UPDATES
    );
  };

  return (
    <>
      <div>{chooseThresholdLabel(type)}</div>
      <$FunnelThresholdGroup
        key={`nps-funnel-threshold-group-${type}`}
        disabled={disabled}
      >
        {npsThresholdData(type).map(
          ([thresholdValue, thresholdLabel, thresholdKey]) => {
            return (
              <$FunnelThresholdColumn
                key={`nps-funnel-threshold-column-${type}-${thresholdKey}`}
              >
                <Radio
                  key={`nps-radio-${type}-${thresholdKey}`}
                  testId={`nps-radio-${type}-${thresholdKey}`}
                  size="20"
                  labelSize="13"
                  name={thresholdName}
                  value={thresholdValue}
                  label={thresholdLabel}
                  checked={thresholdChecked(type, funnelData, thresholdValue)}
                  onClick={() => onClick(thresholdValue, thresholdKey)}
                />
              </$FunnelThresholdColumn>
            );
          }
        )}
      </$FunnelThresholdGroup>
    </>
  );
};

export default NPSFunnelThreshold;
