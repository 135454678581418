import React, { FunctionComponent as FC, useContext } from "react";
import { FunnelContext } from "../../builder/FunnelContext";
import LandingPage from "../../builder/LandingPage";
import DirectFeedback from "../../builder/DirectFeedback";
import { FunnelPageType } from "../../builder/MainContainer";

import { $FunnelContentContainer, $FunnelFlowContainer } from "./FunnelFlow.sc";
import PositiveReviewPage from "../../builder/PositiveReviewPage";
import NegativeReviewPage from "../../builder/NegativeReviewPage";

const FunnelFlow: FC<any> = () => {
  const { funnelData } = useContext(FunnelContext);

  const renderPage = () => {
    switch (funnelData.frontend.activeFunnelPage) {
      case FunnelPageType.PositiveReviewPage:
        return <PositiveReviewPage />;
      case FunnelPageType.NegativeReviewPage:
        return <NegativeReviewPage />;
      case FunnelPageType.DirectFeedback:
        return <DirectFeedback />;
      default:
        return <LandingPage />;
    }
  };

  return (
    <$FunnelFlowContainer
      className="row"
      mobileMode={funnelData.frontend.mobileMode}
    >
      <$FunnelContentContainer className="col-12">
        {renderPage()}
      </$FunnelContentContainer>
    </$FunnelFlowContainer>
  );
};

export default FunnelFlow;
