import CardContainer from "@components/atoms/CardContainer";
import { $EditReviewSiteContainer } from "@components/funnel/settings/CurrentlyMonitoring/CurrentlyMonitoring.sc";
import Input from "@components/molecules/Input";
import Button from "@atoms/Button";
import React, { FunctionComponent as FC, useContext } from "react";
import { useAlert } from "react-alert";
import OutsideClickHandler from "react-outside-click-handler";
import { useMutation } from "react-query";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import Icon from "@atoms/Icon";
import P from "@atoms/Typography/Text";
import {
  deleteLink,
  IDeleteLinkParams,
  useLinkUpdate,
  IUpdateLinkMutationParams,
} from "@components/funnel/hooks/useLinkUpdate";
import Checkbox from "@atoms/Checkbox";
export interface IUpdateReviewSiteProps {
  setEditLink: (data) => void;
  link: any;
  removeLink: (data) => void;
}
const UpdateReviewSite: FC<IUpdateReviewSiteProps> = ({
  setEditLink,
  link,
  removeLink,
}) => {
  const alert = useAlert();
  const editLinkMutation = useMutation((params: IUpdateLinkMutationParams) =>
    useLinkUpdate(link.uuid, params)
  );
  const { funnelData } = useContext(FunnelContext);
  const deleteLinkMutation = useMutation((params: IDeleteLinkParams) =>
    deleteLink(params)
  );

  function linkUpdate(link) {
    editLinkMutation.mutate(
      {
        site_id: funnelData.backend.site_attributes.id,
        page_id: funnelData.backend.page_attributes.id,
        title: link.title,
        url: link.url,
        monitor_url: link.monitor_url,
        switch_opens_in_new_win: link.switch_opens_in_new_win,
      },
      {
        onSuccess: () => {
          alert.success(
            <>
              <h3>Success!</h3>
              <p>Your Review Site was updated.</p>
            </>
          );
          setEditLink(null);
        },
        onError: () => {
          alert.error(
            <>
              <h3>Something is wrong with your URL</h3>
              <p>Please try again</p>
            </>
          );
        },
      }
    );
  }

  function linkDestroy(link) {
    deleteLinkMutation.mutate(
      {
        site_uuid: funnelData.backend.site_attributes.uuid,
        page_id: funnelData.backend.page_attributes.id,
        uuid: link.uuid,
        id: link.uuid,
      },
      {
        onSuccess: () => {
          alert.success(
            <>
              <p>Review site deleted successfully</p>
            </>
          );
          funnelData.backend.links_attributes =
            funnelData.backend.links_attributes.filter(
              (el) => el.uuid != link.uuid
            );
          setEditLink(null);
          removeLink(link);
        },
        onError: () => {
          alert.error(
            <>
              <h3>Uh oh! Something is wrong</h3>
              <p>Please try again</p>
            </>
          );
        },
      }
    );
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setEditLink(null);
      }}
    >
      <CardContainer margin="-24px 0" key={link.uuid}>
        <$EditReviewSiteContainer>
          <Input
            name="title"
            label="Title"
            defaultValue={link.title}
            onChange={(e) => (link.title = e.target.value)}
          />

          {!link.hide_funnel_cta && (
            <>
              <Input
                name="url"
                label="URL"
                defaultValue={link.url}
                onChange={(e) => (link.url = e.target.value)}
              />

              <div className="review-site-form-checkbox">
                <Checkbox
                  id="switch_opens_in_new_win"
                  name="switch_opens_in_new_win"
                  testId="link-switch_opens_in_new_win"
                  label="Open link in a New Tab?"
                  defaultChecked={link.switch_opens_in_new_win}
                  color="baseDark"
                  size="small"
                  onChange={({ target }) =>
                    (link.switch_opens_in_new_win = target.checked)
                  }
                />
              </div>
            </>
          )}

          {link.category !== "custom" && (
            <Input
              name="monitor_url"
              label="REVIEW MONITOR URL"
              defaultValue={link.monitor_url}
              onChange={(e) => (link.monitor_url = e.target.value)}
            />
          )}
        </$EditReviewSiteContainer>
      </CardContainer>
      <CardContainer margin="-16px 0 16px 0" justify="space-between">
        <Button
          onClick={() => {
            linkUpdate(link);
          }}
        >
          Save
        </Button>
        <Button
          shape="text"
          onClick={() => {
            linkDestroy(link);
          }}
        >
          <Icon icon="trash" color="darkError" size={24} />
          <P color="darkError" fontWeight={700} margin="0 5px">
            Delete
          </P>
        </Button>
      </CardContainer>
    </OutsideClickHandler>
  );
};

export default UpdateReviewSite;
