import React, { FC } from "react";
import { $LogoStyled } from "@app/javascript/components/atoms/Logo/Logo.sc";

export type ILogoProps = {
  logoImage: string;
  size?: "sm" | "md" | "lg";
  alt?: string;
  testId?: string;
  onError?: (target?) => void;
};

const Logo: FC<ILogoProps> = ({ logoImage, size, alt, testId, onError }) => {
  return (
    <$LogoStyled
      data-testid={testId ? testId : "test-logo"}
      src={logoImage}
      size={size}
      alt={alt}
      onError={onError}
    />
  );
};

export default Logo;
