import styled from "styled-components";
import { colors } from "../../../colors";
import { quillFonts, quillFontSizes } from "@utils/funnel";
import boldIcon from "../../../../assets/icons/quill/bold.svg";
import italicIcon from "../../../../assets/icons/quill/italic.svg";
import underlineIcon from "../../../../assets/icons/quill/underline.svg";
import fontFormattingIcon from "../../../../assets/icons/quill/font-formatting.svg";
import alignLeftIcon from "../../../../assets/icons/quill/align-left.svg";
import alignCenterIcon from "../../../../assets/icons/quill/align-center.svg";
import alignRightIcon from "../../../../assets/icons/quill/align-right.svg";
import listBulletIcon from "../../../../assets/icons/quill/list-bullet.svg";
import linkIcon from "../../../../assets/icons/quill/link.svg";

export const QuillEditor = styled.div`
  font-family: "Open Sans";
  border: none;
  box-sizing: border-box;
  height: 100%;
  margin: 0px;
  position: relative;

  .ql-tooltip {
    font-size: 13px;
    z-index: 2;
    border: 2px solid ${colors.grayBorder};
    border-radius: 4px;
  }

  .ql-tooltip a.ql-preview {
    vertical-align: middle;
  }

  ${Object.keys(quillFonts)
    .map((fontKey) => {
      return `.ql-font-${fontKey} {
      font-family: ${quillFonts[fontKey].family};
    }`;
    })
    .join(" ")}

  ${Object.keys(quillFontSizes)
    .map((fontSizeKey) => {
      return `.ql-size-${fontSizeKey} {
      font-size: ${quillFontSizes[fontSizeKey].size};
    }`;
    })
    .join(" ")}
`;

export const QuillToolbar = styled.div`
  font-family: "Open Sans";
  color: ${colors.darkBlue};
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: ${colors.white};

  button {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 46px;
    padding: 0px;
    width: 48px;
    margin: 10px 0px 0px 0px;
    color: ${colors.darkBlue};
    vertical-align: top;
  }

  button.active {
    background-color: #f4f5f7;
    border-radius: 4px 4px 0px 0px;

    i.toolbar-icon {
      background: ${colors.primaryBase};
    }
  }

  button:hover,
  button:focus,
  button.ql-active,
  button.ql-active,
  .ql-picker-label:hover,
  .ql-picker-label.ql-active,
  .ql-picker-item:hover,
  .ql-picker-item.ql-selected {
    color: ${colors.primaryBase};
  }

  // default font
  .ql-picker.ql-font .ql-picker-label::before,
  .ql-picker.ql-font .ql-picker-item::before {
    content: "Open Sans";
    font-family: "Open Sans";
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  i.toolbar-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    mask-size: cover;
    background: ${colors.darkBlue};
  }

  i.toolbar-icon:hover,
  i.toolbar-icon:focus,
  .ql-active i.toolbar-icon {
    background: ${colors.primaryBase};
  }

  i.quill-bold-button {
    mask: URL("${boldIcon}");
  }

  i.quill-italic-button {
    mask: URL("${italicIcon}");
  }

  i.quill-underline-button {
    mask: URL("${underlineIcon}");
  }

  i.quill-link-button {
    mask: URL("${linkIcon}");
  }

  i.quill-color-button {
    mask: URL("${fontFormattingIcon}");
  }

  i.quill-custom-font-formatting-button {
    mask: URL("${fontFormattingIcon}");
  }

  button.ql-align {
    i.toolbar-icon {
      mask: URL("${alignLeftIcon}");
    }
  }

  button.ql-align[value="center"] {
    i.toolbar-icon {
      mask: URL("${alignCenterIcon}");
    }
  }

  button.ql-align[value="right"] {
    i.toolbar-icon {
      mask: URL("${alignRightIcon}");
    }
  }

  button.ql-list[value="bullet"] {
    i.toolbar-icon {
      mask: URL("${listBulletIcon}");
    }
  }

  .ql-picker {
    background-color: ${colors.white};
    color: #053368;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: normal;
    height: 42px;
    position: relative;
    vertical-align: middle;
    border: 2px solid #e6ecf0;
    margin-right: 16px;
    border-radius: 6px;

    &.ql-expanded {
      border-radius: 6px 6px 0px 0px;
    }
  }

  ${Object.keys(quillFonts)
    .map((fontKey) => {
      return `
      .ql-picker.ql-font .ql-picker-label[data-value="${fontKey}"]::before,
      .ql-picker.ql-font .ql-picker-item[data-value="${fontKey}"]::before {
        content: '${quillFonts[fontKey].label}';
        font-family: ${quillFonts[fontKey].family};
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
      .ql-picker.ql-font .ql-picker-item[data-value="${fontKey}"]::before {
        font-family: ${quillFonts[fontKey].family};
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    `;
    })
    .join(" ")}

  // default font size
  .ql-picker.ql-size .ql-picker-label::before,
  .ql-picker.ql-size .ql-picker-item::before {
    content: "Normal";
    font-weight: normal;
  }

  ${Object.keys(quillFontSizes)
    .map((fontSizeKey) => {
      return `
      .ql-picker.ql-size .ql-picker-label[data-value="${fontSizeKey}"]::before,
      .ql-picker.ql-size .ql-picker-item[data-value="${fontSizeKey}"]::before {
        content: '${quillFontSizes[fontSizeKey].label}';
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
      .ql-picker.ql-size .ql-picker-item[data-value="${fontSizeKey}"]::before {
        font-size: ${quillFontSizes[fontSizeKey].size};
        font-weight: normal;
      }
    `;
    })
    .join(" ")}
`;

export const QuillToolbarMain = styled.div`
  min-height: 56px;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;
`;

export const QuillToolbarSub = styled.div`
  padding: 0px 16px 16px 16px;
  min-height: 84px;
  border-radius: 0px 0px 4px 4px;
  background-color: #f4f5f7;

  &:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0px;
  }

  label {
    display: flex;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #657481;
    margin: 12px 0px 4px 0px;
  }
`;
