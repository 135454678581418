import styled from "styled-components";

export const $CheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex: 1;
`;

interface ICheckboxLabel {
  size: "small" | "medium" | "big";
}

export const $CheckboxLabel = styled.label<ICheckboxLabel>`
  padding-left: 6px;
  font-weight: normal;
  font-size: ${({ size }) =>
    size == "small" ? "12px" : size == "medium" ? "16px" : "20px"};
  color: ${({ theme, color }) => theme.colors[color]};
`;

export const $CheckboxButton = styled.input.attrs({ type: "checkbox" })`
  margin: 0 0 5px 0 !important;
`;
