import React, { FunctionComponent, useState } from "react";
import { useRichTextEditor } from "./quill/MinimalModularEditor";
import EditableComponent from "./EditableComponent";

export type EditableRichTextProps = {
  isEditable: boolean;
  fieldName: string;
  updating?: boolean;
  text: string;
  tooltip?: string;
  fullWidth?: boolean;
  isEditorFocused?: boolean;
  formatWholeText?: boolean;
  onChange: (text: string) => void;
};

const EditableRichText: FunctionComponent<EditableRichTextProps> = (props) => {
  const [isFocused, setIsFocused] = useState<boolean>(
    props.isEditorFocused === undefined ? false : props.isEditorFocused
  );

  const submitChanges = (htmlContent: string) => {
    setIsFocused(false);
    if (props.isEditable) {
      props.onChange(htmlContent);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const [Editor, Toolbar] = useRichTextEditor({
    isEditable: props.isEditable,
    isEditorFocused:
      props.isEditorFocused === undefined ? false : props.isEditorFocused,
    initialValue: props.text,
    formatWholeText: props.formatWholeText,
    onFocus: handleFocus,
    onBlur: submitChanges,
  });

  return (
    <EditableComponent
      fieldName={props.fieldName}
      tooltip={props.tooltip}
      fullWidth={props.fullWidth}
      topChildren={Editor}
      bottomChildren={Toolbar}
      isFocused={isFocused}
      isDisabled={!props.isEditable}
      isInProgress={props.updating}
    />
  );
};

export default EditableRichText;
