import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useState,
} from "react";
import Icon from "@components/atoms/Icon";
import H from "@components/atoms/Typography/Header";
import {
  $BackButtonContainer,
  $HeaderButton,
  $SettingButtonContainer,
  $SettingsAnimations,
  $SettingsNavContainer,
  $SubNavContainer,
} from "./SettingsNav.sc";
import Button from "@components/atoms/Button";
import P from "@components/atoms/Typography/Text";
import ReviewMonitoring from "./ReviewMonitoring";
import Appearance from "./AppearanceSettings";
import BusinessSettings from "./BusinessSettings";
import { FunnelContext } from "@app/javascript/components/funnel/builder/FunnelContext";

interface ISettingsProps {
  closeNavigation: () => void;
}
const SettingsNav: FC<ISettingsProps> = ({ closeNavigation }) => {
  const { funnelData } = useContext(FunnelContext);
  const [subNav, setSubNav] = useState(funnelData.frontend.settingsTab);

  function exitSettings() {
    funnelData.frontend.openSettings = false;
    closeNavigation();
  }

  function updateSubNav(value: number) {
    funnelData.frontend.settingsTab = value;
    setSubNav(value);
  }

  useEffect(() => {
    setSubNav(funnelData.frontend.settingsTab);
  }, [funnelData.frontend.settingsTab]);
  return (
    <$SettingsAnimations>
      <$HeaderButton>
        <$BackButtonContainer>
          <Button shape="text" onClick={() => exitSettings()}>
            <Icon
              icon="carrotLeft"
              size={30}
              viewBox="0 0 16 16"
              color="baseDarkest"
            />
          </Button>
        </$BackButtonContainer>

        <H size="3" color="baseDarkest">
          Settings
        </H>
      </$HeaderButton>
      <$SettingsNavContainer>
        <$SettingButtonContainer>
          <Button
            shape="nav"
            active={subNav == 1}
            onClick={() => updateSubNav(1)}
          >
            <Icon size={24} icon="briefCase" />
            <P size="4" margin="5px auto" fontWeight={subNav == 1 ? 700 : 600}>
              BUSINESS
            </P>
          </Button>
          <Button shape="nav" active={subNav == 2} onClick={() => setSubNav(2)}>
            <Icon size={24} icon="siteArrow" />
            <P size="4" margin="5px auto" fontWeight={subNav == 2 ? 700 : 600}>
              REVIEW MONITORING
            </P>
          </Button>
          <Button
            shape="nav"
            active={subNav == 3}
            onClick={() => updateSubNav(3)}
          >
            <Icon size={24} icon="colorPalette" />
            <P size="4" margin="5px auto" fontWeight={subNav == 3 ? 700 : 600}>
              APPEARANCE
            </P>
          </Button>
        </$SettingButtonContainer>
        <$SubNavContainer>
          {subNav == 1 && <BusinessSettings />}
          {subNav == 2 && <ReviewMonitoring />}
          {subNav == 3 && <Appearance />}
        </$SubNavContainer>
      </$SettingsNavContainer>
    </$SettingsAnimations>
  );
};

export default SettingsNav;
