import { colors } from "@styles/colors";
import styled from "styled-components";

export const $ReviewSiteOptionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const $ReviewSiteToggleIcon = styled.div`
  padding-right: 14px;
  cursor: pointer;
`;

export const $EditReviewSiteContainer = styled.div`
  width: 100%;
  input {
    background-color: ${colors.baseWhite};
  }
  p {
    color: ${colors.baseDark};
  }
  .review-site-form-checkbox {
    margin: 0 0 10px 0;
  }
`;

export const $ReviewSiteTitle = styled.div`
  color: ${colors.baseDark};
  flex: 1;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin: 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const $ReviewSiteLogoContainer = styled.div`
  display: flex;
`;
