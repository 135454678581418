import styled from "styled-components";

export const $HoverTab = styled.div<{ fieldName: string; editMode: boolean }>`
  position: relative;
  ${(props) =>
    props.editMode &&
    `
  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${props.theme.colors.primary1};
    cursor: pointer;
    &::before {
      content: "${props.fieldName}";
      background-color: ${props.theme.colors.primary1};
      color: ${props.theme.colors.baseWhite};
      position: absolute;
      padding: 4px;
      font-size: 11px;
      font-weight: bold;
      line-height: 17px;
      top: -25px; // line-height + padding * 2
      left: -1px;
      z-index: 2;
    }
  }`}
`;
