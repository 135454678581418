import React, { FunctionComponent as FC } from "react";
import Logo from "@atoms/Logo";
import { $ReviewSiteLogoContainer } from "@components/funnel/settings/CurrentlyMonitoring/CurrentlyMonitoring.sc";

export interface IReviewSiteLogo {
  link: any;
  logoError: any;
  addLogoError: (linkUuid, logoError) => void;
}

const ReviewSiteLogo: FC<IReviewSiteLogo> = ({
  link,
  logoError,
  addLogoError,
}) => {
  if (!logoError?.icon) {
    return (
      <$ReviewSiteLogoContainer>
        <Logo
          logoImage={link.icon_url}
          size="sm"
          alt={link.category + "-icon"}
          onError={() => addLogoError(link.uuid, { icon: true })}
        />
      </$ReviewSiteLogoContainer>
    );
  } else if (!logoError?.logo) {
    return (
      <$ReviewSiteLogoContainer>
        <Logo
          logoImage={link.logo_url}
          size="sm"
          alt={link.category + "-logo"}
          onError={() => addLogoError(link.uuid, { icon: true, logo: true })}
        />
      </$ReviewSiteLogoContainer>
    );
  }

  return null;
};

export default ReviewSiteLogo;
