import React, { FunctionComponent as FC, useContext, useState } from "react";
import { useMutation } from "react-query";
import { useAlert } from "react-alert";
import P from "@traject/reviewspod.atoms.typography.text";
import InputForm from "@traject/reviewspod.molecules.input-form";
import FileInput from "@traject/reviewspod.molecules.file-input";
import FileUploadPreview from "@traject/reviewspod.molecules.file-upload-preview";
import Transactional from "@traject/reviewspod.molecules.modals.transactional";
import { $TransactionalContainer } from "./AddReviewSite.sc";
import { readableFileSize } from "./utils";
import {
  ICreateLinkMutationParams,
  useLinkCreate,
} from "@components/funnel/hooks/useLinkCreate";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import {
  countShowedLinks,
  errorMessage,
  MAX_SELECTED_LINKS,
} from "@components/funnel/settings/AddAReviewSite/utils";

export interface ICustomLinkModal {
  openLinkModal: boolean;
  closeModalFxn: () => void;
  createModalFxn: () => void;
}

type ILink = {
  title: string;
  url: string;
  switch_opens_in_new_win: boolean;
  imageFile?: File;
  imageUrl?: string;
};

export const EMPTY_LINK: ILink = {
  title: "",
  url: "",
  switch_opens_in_new_win: true,
};

const CustomLinkModal: FC<ICustomLinkModal> = ({
  openLinkModal,
  closeModalFxn,
  createModalFxn,
}) => {
  const { funnelData, updateBackend } = useContext(FunnelContext);

  const [link, setLink] = useState<ILink>(EMPTY_LINK);

  const alert = useAlert();

  const createLinkMutation = useMutation((params: ICreateLinkMutationParams) =>
    useLinkCreate(params)
  );

  const createLinkMutationMutate = async () => {
    createLinkMutation.mutate(
      {
        site_id: funnelData.backend.site_attributes.id,
        page_id: funnelData.backend.page_attributes.id,
        title: link.title,
        url: link.url,
        category: "custom",
        switch_opens_in_new_win: link.switch_opens_in_new_win,
        image: link.imageFile,
        switch_show: countShowedLinks(funnelData) < MAX_SELECTED_LINKS,
      },
      {
        onSuccess: (link) => {
          funnelData.backend.links_attributes.push({ ...link });
          updateBackend();
          alert.success(<p>Custom Link has been created and added.</p>);
          handleCloseModal();
          createModalFxn();
        },
        onError: (error) => {
          alert.error(
            <>
              <h3>Something went wrong.</h3>
              <p>{errorMessage(error)}</p>
            </>
          );
        },
      }
    );
  };

  const handleCreateModal = async () => {
    if (link.title === "" && link.url === "") return;
    await createLinkMutationMutate();
  };

  const handleCloseModal = () => {
    setLink(EMPTY_LINK);
    closeModalFxn();
  };

  const handleImageChange = ({ target }) => {
    const imageFile = target.files[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setLink({ ...link, imageFile, imageUrl });
  };

  const handlePreviewDelete = () => {
    const { title, url, switch_opens_in_new_win } = link;
    setLink({ title, url, switch_opens_in_new_win });
  };

  const handlePreviewClick = () => {
    document.getElementById("link-image").click();
  };

  return (
    <Transactional
      isOpen={openLinkModal}
      closeFxn={handleCloseModal}
      titleText="Create a Custom Link"
      withSecondary={true}
      secondaryText="Cancel"
      secondaryFxn={handleCloseModal}
      primaryText="Create Custom Link"
      primaryFxn={handleCreateModal}
    >
      <$TransactionalContainer>
        <P size="1" color="baseDarkest">
          Add information for the link.
        </P>
        <InputForm
          testId="link-input-form-label"
          inputId="link-label"
          inputValue={link.title}
          label="Link Label"
          requiredLabel="REQUIRED"
          inputPlaceholder="(ie. Business Name)"
          className="custom-link__field"
          onInputChange={({ target }) =>
            setLink({ ...link, title: target.value })
          }
        />
        <InputForm
          testId="link-input-form-url"
          inputId="link-url"
          inputValue={link.url}
          label="Link URL"
          requiredLabel="REQUIRED"
          inputPlaceholder="(ie. https://www.businessname.com)"
          onInputChange={({ target }) =>
            setLink({ ...link, url: target.value })
          }
          hintStatus="Please enter a valid URL."
          showCheckBox={true}
          checkboxId="link-open-new-tab"
          checkBoxDefaultChecked={link.switch_opens_in_new_win}
          onCheckBoxChange={() =>
            setLink({
              ...link,
              switch_opens_in_new_win: !link.switch_opens_in_new_win,
            })
          }
          checkBoxLabel="Open in New Tab"
          className="custom-link__field"
        />
        <div className="custom-link__field">
          <P>Add an image for the link icon. Suggested size is 250 by 100px.</P>
        </div>
        <div className="custom-link__field">
          {link.imageFile && (
            <FileUploadPreview
              testId="link-preview"
              fileName={link.imageFile.name}
              fileSize={readableFileSize(link.imageFile.size)}
              image={<img src={link.imageUrl} />}
              onDelete={handlePreviewDelete}
              onClick={handlePreviewClick}
            />
          )}
          <FileInput
            id="link-image"
            testId="link-image"
            name="link-image"
            accept="image/*"
            onChange={handleImageChange}
            styles={link.imageFile ? { display: "none" } : {}}
          />
        </div>
      </$TransactionalContainer>
    </Transactional>
  );
};

export default CustomLinkModal;
