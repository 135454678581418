import React, { FC, useContext, useState, useRef, useEffect } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { useForm } from "react-hook-form";
import { isEditMode, generateRequestId, trackAhoyEvent } from "@utils/funnel";
import { $RecaptchaStyle } from "@styles/components/elements/Captcha";
import ReCAPTCHA from "react-google-recaptcha";
import ServerErrorModal from "@components/base/modals/ServerErrorModal";
import EditableComponent from "@components/elements/EditableComponent";
import {
  $DirectFeedbackFormActions,
  $DirectFeedbackFormErrors,
} from "./DirectFeedbackForm.sc";
import Button from "@traject/reviewspod.molecules.button";
import { useMutation } from "react-query";
import {
  ISubmitFormParams,
  submitForm,
} from "@components/funnel/hooks/useSubmitForm";
import Input from "@components/molecules/Input";
import FeedbackFormFields from "./FeedbackFormFields";
import FeedbackFormModal from "@components/funnel/builder/DirectFeedbackForm/FeedbackFormModal";

export interface IDirectFeedbackForm {
  className?: string;
  prelude: string;
  requireStarRating?: boolean;
  starRating?: number;
}

type IModalContent = {
  title: string;
  message: string;
};

const DirectFeedbackForm: FC<IDirectFeedbackForm> = ({
  className = "direct-feedback-form",
  prelude,
  requireStarRating = false,
  starRating = null,
}: IDirectFeedbackForm) => {
  const attributeId = "config_modules_attributes_direct_feedback";

  const { funnelData, updateBackend } = useContext(FunnelContext);

  const [updatingFormDisplay, setUpdatingFormDisplay] =
    useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>(
    generateRequestId(attributeId)
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<IModalContent>(null);
  const [recaptchaError, setRecaptchaError] = useState(null);
  const recaptchaRef = useRef();

  const {
    config_modules_attributes,
    site_attributes,
    recaptcha_site_key,
    updated_request_id,
  } = funnelData.backend;

  useEffect(() => {
    if (requireStarRating) {
      if (starRating == null) {
        register("starRating", { required: "You must select a rating" });
      } else {
        unregister("starRating");
      }
    }
  }, [requireStarRating, starRating]);

  useEffect(() => {
    if (updated_request_id == requestId) setUpdatingFormDisplay(false);
  }, [updated_request_id]);

  const refreshPageError = () =>
    setRecaptchaError("Please refresh page and try again!");

  const openFormModal = (title, message) => {
    setModalContent({ title, message });
    setOpenModal(true);
  };

  const closeFormModal = () => {
    setModalContent(null);
    setOpenModal(false);
  };

  const closeErrorsModal = () => setRecaptchaError(null);

  const afterServerErrorModal = () => (document.body.style.overflow = "hidden");

  const mutation = useMutation((params: ISubmitFormParams) =>
    submitForm(params)
  );

  const postForm = (data, captchaToken) => {
    mutation.mutate(
      {
        ...data,
        form_type: "contact",
        prelude: prelude,
        uuid: funnelData.backend.page_attributes.uuid,
        "g-recaptcha-response": captchaToken,
      },
      {
        onSuccess: (responseData) => {
          openFormModal("Thank you!", "Your feedback has been submitted.");
        },
        onError: (error) => {
          // @ts-ignore
          openFormModal("Error", error.message);
        },
      }
    );
  };

  const {
    handleSubmit,
    register,
    unregister,
    errors,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
  });

  const sendMessage = async (data) => {
    if (isEditMode(funnelData)) return;

    if (funnelData.frontend.presentMode) {
      trackAhoyEvent({
        target: document.querySelector(".funnel-review-first"),
      });
    }

    let captchaToken;
    if (site_attributes.switch_use_captcha) {
      // @ts-ignore
      captchaToken = await recaptchaRef.current.getValue();
      // @ts-ignore
      recaptchaRef.current.reset();
    }
    postForm(data, captchaToken);
  };

  const renderReCaptcha = () => {
    return (
      site_attributes.switch_use_captcha && (
        <$RecaptchaStyle>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptcha_site_key}
            onExpired={refreshPageError}
            onErrored={refreshPageError}
          />
        </$RecaptchaStyle>
      )
    );
  };

  const onToggleChange = (field: string, show: boolean) => {
    setUpdatingFormDisplay(true);
    const generatedRequestId = generateRequestId(attributeId);
    setRequestId(generatedRequestId);
    funnelData.backend.config_modules_attributes[
      `show_direct_feedback_${field}_input`
    ] = show;
    funnelData.backend.request_id = generatedRequestId;
    updateBackend();
  };

  return (
    <>
      <form
        className={className}
        data-track="yes"
        data-track-name="funnel_click"
        data-track-key="review"
        data-track-text="Submit Review"
        data-track-destination="onsite"
        data-track-context="private_feedback_submitted"
        onSubmit={handleSubmit(sendMessage)}
      >
        <$DirectFeedbackFormErrors>
          {errors.starRating?.message}
        </$DirectFeedbackFormErrors>
        <Input
          label="Name*"
          name="name"
          placeholder="First & last name"
          errors={errors.name}
          register={register({
            required: "Please enter your full name.",
          })}
        />
        <EditableComponent
          fieldName="Form Fields"
          topChildren={
            <FeedbackFormFields
              editMode={isEditMode(funnelData)}
              register={register}
              errors={errors}
              onToggleChange={onToggleChange}
            />
          }
          fullWidth={true}
          isDisabled={!isEditMode(funnelData)}
          isInProgress={updatingFormDisplay}
        />
        <Input
          name="message"
          label="Message*"
          shape="textarea"
          placeholder="Tell us about your experience"
          errors={errors.message}
          register={register({
            required: "Required please enter message",
            minLength: {
              value: 50,
              message: "Must be longer than 50 characters",
            },
            maxLength: {
              value: 600,
              message: "Must be shorter than 600 characters",
            },
          })}
        />
        {renderReCaptcha()}
        <$DirectFeedbackFormActions
          className="direct-feedback-form-actions"
          color={config_modules_attributes.button_color}
        >
          <Button
            className="direct-feedback-form-button"
            color="primary"
            onClick={handleSubmit(sendMessage)}
            disabled={!isValid || isSubmitting}
          >
            Send Message
          </Button>
        </$DirectFeedbackFormActions>
      </form>

      {openModal && (
        <FeedbackFormModal
          header={modalContent.title}
          message={modalContent.message}
          onClose={closeFormModal}
        />
      )}

      <ServerErrorModal
        isOpen={!!recaptchaError}
        onAfterOpen={afterServerErrorModal}
        onRequestClose={closeErrorsModal}
        errorText={recaptchaError}
      />
    </>
  );
};

export default DirectFeedbackForm;
