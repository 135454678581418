import React from "react";
import { render, fireEvent, screen } from "@testing-library/react";
import FunnelPagePresenter from "..";

import { funnelDataBackend } from "@app/javascript/__tests__/helpers/funnel";
import Theme from "@traject/reviewspod.base_ui.theme";

async function renderPage() {
  render(
    <Theme theme="gus">
      <FunnelPagePresenter funnelData={funnelDataBackend} />
    </Theme>
  );
}

describe("<FunnelPagePresenter />", () => {
  beforeAll(() => {
    Object.defineProperty(window, "ahoy", {
      value: {
        track: jest.fn(),
      },
    });
  });

  describe("click funnel star rating", () => {
    it('shows "positive review page" page after positive rating', async () => {
      await renderPage();

      expect(screen.getByTestId("funnel-landing-page")).toBeInTheDocument();
      fireEvent.click(screen.getByTestId("funnel-rating-star-5"));
      expect(screen.getByTestId("positive-review-page")).toBeInTheDocument();
    });

    it("shows negative review page after negative rating", async () => {
      await renderPage();

      expect(screen.getByTestId("funnel-landing-page")).toBeInTheDocument();
      fireEvent.click(screen.getByTestId("funnel-rating-star-2"));
      expect(screen.getByTestId("negative-review-page")).toBeInTheDocument();
    });
  });
});
