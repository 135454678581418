import React from "react";
import { ErrorFields } from "@styles/components/elements/Paragraphs";

export const errorFields = (value) => {
  return <ErrorFields>{value && value.message}</ErrorFields>;
};

export interface IStarsConfirm {
  rating_1_label: string;
  rating_2_label: string;
  rating_3_label: string;
  rating_4_label: string;
  rating_5_label: string;
}

export const starInputs = [
  { label: "5 Star Label", name: "rating_5_label", holder: "Great" },
  { label: "4 Star Label", name: "rating_4_label", holder: "Good" },
  { label: "3 Star Label", name: "rating_3_label", holder: "Okay" },
  { label: "2 Star Label", name: "rating_2_label", holder: "Subpar" },
  { label: "1 Star Label", name: "rating_1_label", holder: "Bad" },
];

export interface IThumbsConfirm {
  choice_0_label: string;
  choice_1_label: string;
}

export const thumbInputs = [
  {
    label: "Thumbs Up Label",
    name: "choice_1_label",
    holder: "I had a good experience",
  },
  {
    label: "Thumbs Down Label",
    name: "choice_0_label",
    holder: "I had a good experience",
  },
];

export interface INPSConfirm {
  rating_1_label: string;
  rating_2_label: string;
  rating_3_label: string;
  rating_4_label: string;
  rating_5_label: string;
  rating_6_label: string;
  rating_7_label: string;
  rating_8_label: string;
  rating_9_label: string;
  rating_10_label: string;
}
export const npsInputs = [
  {
    label: "1-2",
    name: "rating_1_label",
    holder: "Not at all likely",
    hidden: ["rating_2_label"],
  },
  {
    label: "3-5",
    name: "rating_3_label",
    holder: "Unlikely",
    hidden: ["rating_4_label", "rating_5_label"],
  },
  {
    label: "6-8",
    name: "rating_6_label",
    holder: "Likely",
    hidden: ["rating_7_label", "rating_8_label"],
  },
  {
    label: "9-10",
    name: "rating_9_label",
    holder: "Extremely Likely",
    hidden: ["rating_10_label"],
  },
];
