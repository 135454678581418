import React, { FunctionComponent as FC } from "react";
import {
  $CheckboxButton,
  $CheckboxContainer,
  $CheckboxLabel,
} from "./Checkbox.sc";

export interface ICheckboxProps {
  id: string;
  name: string;
  defaultChecked: boolean;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  testId?: string;
  register?: any;
  size?: "small" | "medium" | "big";
  color?: string;
  styling?: any;
}

export const Checkbox: FC<ICheckboxProps> = ({
  id,
  name,
  defaultChecked,
  label,
  onChange,
  disabled,
  testId,
  register,
  size = "medium",
  color = "baseDark",
  styling = {},
}) => (
  <$CheckboxContainer style={styling}>
    <$CheckboxButton
      id={id}
      name={name}
      ref={register}
      data-testid={testId ? testId : "checkbox-button"}
      defaultChecked={disabled ? null : defaultChecked}
      onChange={(e) => onChange(e)}
    />
    {label && (
      <$CheckboxLabel htmlFor={id} size={size} color={color}>
        {label}
      </$CheckboxLabel>
    )}
  </$CheckboxContainer>
);

export default Checkbox;
