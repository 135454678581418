import axios from "axios";
import { getCSRFToken } from "@utils/utils";

export interface ISiteParams {
  slug?: string;
  domain_status?: "none" | "own" | "cname";
  domain?: string;
  domain_is_shared?: boolean;
  custom_css?: string;
  custom_field1?: string;
  logo?: any;
  logo_width?: string;
  logo_height?: string;
  delete_logo?: string;
  theme_template?: string;
}

export interface ImutationParams {
  id: number;
  site: ISiteParams;
  site_uuid: string;
  page_id: string;
}

export const updateSite = async (params: ImutationParams) => {
  let putParams: any = { ...params, react: true };
  if (putParams.site.logo) {
    const formData = new FormData();
    formData.append("id", putParams.id);
    formData.append("site_uuid", putParams.site_uuid);
    formData.append("page_id", putParams.page_id);
    formData.append("react", "true");
    formData.append("site[logo]", putParams.site.logo);
    formData.append("site[logo_height]", putParams.site.logo_height);
    formData.append("site[logo_width]", putParams.site.logo_width);
    putParams = formData;
  }

  const { data } = await axios.put(`/builder/sites/${params.id}`, putParams, {
    headers: { "X-CSRF-TOKEN": getCSRFToken() },
  });
  return data;
};
