import React, { FunctionComponent as FC, useContext } from "react";
import { FunnelContext } from "../FunnelContext";
import RatingTypeTemplates from "../../ratingType/RatingTypeTemplates";
import ProfileLogo from "../ProfileLogo";
import LandingPageTitle from "../LandingPageTitle";
import FunnelBodyCopy from "../FunnelBodyCopy";
import { $FunnelPageFooter } from "./LandingPage.sc";

const LandingPage: FC = () => {
  const { funnelData, updateBackend } = useContext(FunnelContext);

  return (
    <div data-testid="funnel-landing-page">
      <ProfileLogo />
      <div style={{ height: "16px" }} />
      <LandingPageTitle />
      <FunnelBodyCopy contentKey="body" />
      <RatingTypeTemplates />
      <$FunnelPageFooter
        className="landing-page-footer"
        mobileMode={funnelData.frontend.mobileMode}
        dangerouslySetInnerHTML={{
          __html: funnelData.backend.site_attributes.seal,
        }}
      />
    </div>
  );
};

export default LandingPage;
