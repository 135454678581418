import React from "react";
import { colors } from "@styles/colors";
import { $CardContainer } from "./CardContainer.sc";
import { StoreItem } from "@storybook/client-api";

export interface ICardProps {
  backgroundColor?: string;
  padding?: string;
  justify?: string;
  children: React.ReactNode;
  margin?: string;
}

export const CardContainer = ({
  backgroundColor = colors.baseLightest,
  padding = "16px",
  justify = "center",
  margin = "0",
  children,
}: ICardProps) => (
  <$CardContainer
    backgroundColor={backgroundColor}
    padding={padding}
    justify={justify}
    margin={margin}
  >
    {children}
  </$CardContainer>
);

export default CardContainer;
