import axios from "axios";
import { getCSRFToken } from "@utils/utils";

export type ILinkParams = {
  url?: string;
  monitor_url?: string;
  uuid?: string;
};
export interface IUpdateLinkMutationParams {
  site_id: string;
  page_id: string;
  uuid?: string;
  // single link attributes
  title?: string;
  url?: string;
  monitor_url?: string;
  category?: string;
  custom_header?: string;
  category_id?: string;
  switch_opens_in_new_win?: boolean;
  switch_show?: boolean;
}

export interface IDeleteLinkParams {
  id: string;
  uuid: string;
  site_uuid: string;
  page_id: number;
}

export async function useLinkUpdate(uuid, params: IUpdateLinkMutationParams) {
  const { data } = await axios.put(
    `/builder/links/${uuid}/simple_update.json`,
    params,
    {
      headers: { "X-CSRF-TOKEN": getCSRFToken() },
    }
  );
  return data;
}

export async function deleteLink(params: ILinkParams) {
  const { data } = await axios.delete(`/builder/links/${params.uuid}`, {
    params: params,
    headers: { "X-CSRF-TOKEN": getCSRFToken() },
  });
}
