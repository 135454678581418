import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { $FunnelNavButtonContainer } from "./FunnelNavButton.sc";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { FunnelPageType } from "@components/funnel/builder/MainContainer";
import Icon from "@atoms/Icon";
import P from "@atoms/Typography/Text";
import { colors } from "@styles/colors";

export interface iFunnelButtonProps {
  type: FunnelPageType;
  disabled: boolean;
}

type FunnelNavButtonState = {
  title: string;
  description: string;
  iconClass?: string;
  disabled?: boolean;
};

const FunnelNavButton: FC<iFunnelButtonProps> = ({ type, disabled }) => {
  const [data, setData] = useState<FunnelNavButtonState>(null);
  const { funnelData, updateFrontend } = useContext(FunnelContext);

  useEffect(() => setData(getData()), []);

  const getData = (): FunnelNavButtonState => {
    switch (type) {
      case FunnelPageType.LandingPage:
        return {
          iconClass: "hello",
          title: "Landing Page",
          description: "Reviewers are asked to rate.",
        };
      case FunnelPageType.DirectFeedback:
        return {
          iconClass: "message",
          title: "Direct Feedback",
          description: "Reviewer feedback form.",
        };
    }
  };

  const onClick = () => {
    if (funnelData.frontend.activeFunnelPage == type) return;

    funnelData.frontend.activeFunnelPage = type;
    updateFrontend();
  };

  const active = funnelData.frontend.activeFunnelPage == type && "primary2";
  const titleColor = disabled ? "baseDark" : "baseDarker";
  const descColor = disabled ? "baseDark" : "baseDarker";

  return data ? (
    <$FunnelNavButtonContainer
      className="funnel-nav-button-container"
      active={funnelData.frontend.activeFunnelPage == type}
      onClick={disabled ? null : onClick}
      disabled={disabled}
      data-testid={data.iconClass}
    >
      <Icon
        size={24}
        viewBox="0 0 24 24"
        icon={data.iconClass}
        color={disabled ? "baseDarker" : "primary2"}
      />
      <div style={{ marginLeft: "24px" }}>
        <P color={active || (!active && titleColor)} fontWeight={600}>
          {data.title}
        </P>
        <P size="3" color={descColor}>
          {data.description}
        </P>
      </div>
    </$FunnelNavButtonContainer>
  ) : null;
};

export default FunnelNavButton;
