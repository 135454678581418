import React, { FC, useContext } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import Input from "@molecules/Input";

export interface IEmailInputProps {
  register: any;
  errors: any;
}

const FeedbackFormEmailInput: FC<IEmailInputProps> = ({ register, errors }) => {
  const { funnelData } = useContext(FunnelContext);

  const { recipient_attributes, config_modules_attributes } =
    funnelData.backend;
  const { autofill_and_hide_email, show_direct_feedback_email_input } =
    config_modules_attributes;

  const isHidden = () =>
    autofill_and_hide_email && recipient_attributes?.email_address;

  return isHidden() ? (
    <Input
      id="email"
      name="email"
      type="hidden"
      value={recipient_attributes.email_address}
    />
  ) : (
    <div style={{ position: "relative" }}>
      <Input
        name="email"
        label="Email"
        placeholder="email@example.com"
        errors={errors.email}
        defaultValue={recipient_attributes?.email_address}
        register={register({
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message:
              "There was an error with your email address. Please re-enter.",
          },
        })}
        disabled={!show_direct_feedback_email_input}
      />
    </div>
  );
};

export default FeedbackFormEmailInput;
