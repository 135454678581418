import { FunnelPageType } from "@components/funnel/builder/MainContainer";
import { FunnelDataType } from "@components/funnel/builder/FunnelContext";

export const chooseThresholdLabel = (type: FunnelPageType) =>
  `Choose your ${
    type === FunnelPageType.PositiveReviewPage ? "positive" : "negative"
  } threshold`;

export const isPositiveReview = (type: FunnelPageType) =>
  type === FunnelPageType.PositiveReviewPage;

export const isNegativeReview = (type: FunnelPageType) =>
  type === FunnelPageType.NegativeReviewPage;

export const inPositiveNegativeReview = (type: FunnelPageType) =>
  [
    FunnelPageType.PositiveReviewPage,
    FunnelPageType.NegativeReviewPage,
  ].includes(type);

export const thresholdChecked = (
  type: FunnelPageType,
  funnelData: FunnelDataType,
  thresholdValue: string
) => {
  if (!inPositiveNegativeReview(type)) return;

  const { positive_threshold, negative_threshold } =
    funnelData.backend.config_modules_attributes;
  if (isPositiveReview(type) && Boolean(positive_threshold)) {
    return thresholdValue === positive_threshold;
  } else if (isNegativeReview(type) && Boolean(negative_threshold)) {
    return thresholdValue === negative_threshold;
  }
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const getThresholdKey = (thresholdValue) => {
  const key = thresholdValue.replace(/\d/g);
  return parseInt(key[0]);
};

export const loadDefaultValues = (
  type: FunnelPageType,
  funnelData,
  updateBackend,
  defaultPositiveThreshold,
  defaultNegativeThreshold,
  defaultSegmentingThreshold
) => {
  if (!inPositiveNegativeReview(type)) return;

  // default values
  const config_modules_attributes =
    funnelData.backend.config_modules_attributes;
  const { positive_threshold, negative_threshold } = config_modules_attributes;
  if (!Boolean(positive_threshold) || !Boolean(negative_threshold)) {
    config_modules_attributes.positive_threshold = defaultPositiveThreshold;
    config_modules_attributes.negative_threshold = defaultNegativeThreshold;
    config_modules_attributes.segmenting_threshold = defaultSegmentingThreshold;
    updateBackend();
  }
};

export const handleOnClick = (
  newThresholdValue,
  segmentThreshold,
  type: FunnelPageType,
  funnelData,
  updateBackend,
  thresholdUpdates,
  factor = 1
) => {
  const config_modules_attributes =
    funnelData.backend.config_modules_attributes;
  const { positive_threshold, negative_threshold } = config_modules_attributes;
  if (isPositiveReview(type)) {
    if (positive_threshold === newThresholdValue) return;

    // update positive
    config_modules_attributes.positive_threshold = newThresholdValue;
    config_modules_attributes.negative_threshold =
      thresholdUpdates[newThresholdValue];
  } else if (isNegativeReview(type)) {
    if (negative_threshold === newThresholdValue) return;

    // update negative
    config_modules_attributes.positive_threshold = getKeyByValue(
      thresholdUpdates,
      newThresholdValue
    );
    config_modules_attributes.negative_threshold = newThresholdValue;
  }
  config_modules_attributes.segmenting_threshold = segmentThreshold * factor;
  updateBackend();
};
