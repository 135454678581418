import { FunctionComponent as FC, useContext, useState } from "react";
import * as React from "react";
import Modal from "react-modal";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import RatingTypes from "@components/funnel/ratingType/RatingTypes";
import FadeIn from "react-fade-in";
import { $CloseButton } from "./RatingTypesModal.sc";
import RatingLabels from "@components/funnel/ratingType/RatingLabels";
import { useMediaQuery } from "@app/assets/stylesheets/react/useMediaQuery";
import Icon from "@atoms/Icon";
import { colors } from "@styles/colors";
import { overlayBackground } from "@molecules/Modal";
import { useMutation } from "react-query";
import { useAlert } from "react-alert";
import {
  ImutationParams,
  updateSite,
} from "@components/funnel/hooks/useSiteUpdate";

interface IRatingTypesMainProps {
  onClose: () => void;
}

const customStyles = (maxHeight) => {
  return {
    content: {
      top: maxHeight ? "55%" : "114px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: maxHeight ? "translate(-50%, -50%)" : "translate(-50%, 0%)",
      width: "577px",
      height: maxHeight ? "85%" : "850px",
      overflow: "hidden",
      padding: "64px 118px 42px 118px",
      borderRadius: "20px",
      background: colors.baseWhite,
    },
    overlay: {
      background: overlayBackground,
      zIndex: 99,
    },
  };
};

Modal.setAppElement("body");

const RatingTypesModal: FC<IRatingTypesMainProps> = ({ onClose }) => {
  const { funnelData, updateBackend } = useContext(FunnelContext);
  const mutation = useMutation((params: ImutationParams) => updateSite(params));
  const [template, setTemplate] = useState<string>(
    funnelData.backend.site_attributes.theme_template
  );
  const [showLabels, setShowLabels] = useState<boolean>();

  const maxHeight = useMediaQuery("(max-height: 1050px)");

  const onTemplateChange = (template) => setTemplate(template);
  const onTemplateConfirm = () => setShowLabels(true);

  const { site_attributes, page_attributes } = funnelData.backend;
  const alert = useAlert();
  const onLabelsConfirm = (labels) => {
    const resetReviewThreshold =
      template !== funnelData.backend.site_attributes.theme_template;
    mutation.mutate(
      {
        id: site_attributes.id,
        site_uuid: site_attributes.uuid,
        page_id: page_attributes.id,
        site: { theme_template: template },
      },
      {
        onSuccess: (responseData) => {
          funnelData.backend = responseData;
          // reset positive/negative threshold if is a different template
          if (resetReviewThreshold) {
            funnelData.backend.config_modules_attributes.positive_threshold =
              "";
            funnelData.backend.config_modules_attributes.negative_threshold =
              "";
          }
          for (let key in labels) {
            funnelData.backend.content_modules_attributes[key] = labels[key];
          }
          updateBackend();
          onClose();
        },
        onError: () => {
          alert.error(
            <>
              <h3>Unable to update rating type</h3>
              <p>Please try again later.</p>
            </>
          );
        },
      }
    );
  };

  return (
    <FadeIn delay={125}>
      <Modal isOpen={true} style={customStyles(maxHeight)} ariaHideApp={false}>
        <$CloseButton onClick={() => onClose()}>
          <Icon icon="close" />
        </$CloseButton>
        {showLabels ? (
          <RatingLabels
            template={template}
            onConfirm={onLabelsConfirm}
            backFxn={() => setShowLabels(false)}
          />
        ) : (
          <RatingTypes
            template={template}
            onTemplateChange={onTemplateChange}
            onConfirm={onTemplateConfirm}
          />
        )}
      </Modal>
    </FadeIn>
  );
};

export default RatingTypesModal;
