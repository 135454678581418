import styled from "styled-components";

export const $Label = styled.label`
  padding-top: 16px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 32px;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.baseDark};
  font-family: "Open Sans";
`;
