import * as React from "react";
import { FunctionComponent as FC } from "react";
import { useForm } from "react-hook-form";
import { IConfirmationProps } from ".";
import CheckMarkCircle from "@app/javascript/assets/icons/checkmark/checkmark-fill.svg";
import { BaseInput } from "@styles/components/elements/Inputs";
import BackButton from "@components/elements/BackButton";
import Button from "@components/atoms/Button";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import {
  $BackButtonContainer,
  $ButtonContainer,
  $InputWrapper,
  $LabelText,
  $RatingLabelsContainer,
  $RatingTypesBody,
  $RatingTypesFormWrapper,
  $RatingTypesTitle,
} from "./RatingLabels.sc";
import { npsInputs, INPSConfirm, errorFields } from "./utils";

const NPSConfirm: FC<IConfirmationProps> = ({ onConfirm, backFxn }) => {
  const { funnelData } = React.useContext(FunnelContext);
  const { handleSubmit, register, errors, setValue, getValues } =
    useForm<INPSConfirm>({
      mode: "onChange",
    });

  const onSubmit = (data: INPSConfirm) => {
    onConfirm(data);
  };

  const handleChange = (userInput: string, hiddenRatings) => {
    hiddenRatings.map((rating) => {
      register(rating);
      setValue(rating, userInput);
    });
  };

  const defaultValues = (name, holder) => {
    if (funnelData.backend.config_modules_attributes.template == "nps") {
      return funnelData.backend.content_modules_attributes[name];
    } else {
      return holder;
    }
  };

  return (
    <>
      <$BackButtonContainer>
        <BackButton text="Back to Rating Types" backFxn={() => backFxn()} />
      </$BackButtonContainer>
      <$RatingLabelsContainer>
        <$RatingTypesTitle>NPS Labels</$RatingTypesTitle>
        <$RatingTypesBody>
          Label the NPS ranges. Your customer sees this when hovering over or
          clicking on the number.
        </$RatingTypesBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <$RatingTypesFormWrapper>
            {npsInputs.map(({ label, name, holder, hidden }) => {
              return (
                <$InputWrapper key={name}>
                  <$LabelText>{label}</$LabelText>
                  <BaseInput
                    onChange={(e) => handleChange(e.target.value, hidden)}
                    name={name}
                    type="text"
                    defaultValue={defaultValues(name, holder)}
                    placeholder={holder}
                    errorMessage={!!errors[name]}
                    ref={register({
                      required: "Description Required",
                      maxLength: {
                        value: 26,
                        message: "Cannot exceed 26 characters",
                      },
                    })}
                  />
                  {errorFields(errors[name])}
                </$InputWrapper>
              );
            })}
          </$RatingTypesFormWrapper>
          <$ButtonContainer>
            <Button onClick={() => onSubmit} leftIcon={CheckMarkCircle}>
              Confirm
            </Button>
          </$ButtonContainer>
        </form>
      </$RatingLabelsContainer>
    </>
  );
};

export default NPSConfirm;
