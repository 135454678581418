import React, { FunctionComponent, useState, useEffect } from "react";
import { useQuill } from "react-quilljs";
import { generateQuillToolbarId } from "@utils/utils";

import {
  QuillEditor,
  QuillToolbar,
  QuillToolbarMain,
  QuillToolbarSub,
} from "@styles/components/elements/quill/FunnelEditor";

type ToolbarProps = {
  toolbarId: string;
  formatWholeText: boolean;
};

const Toolbar: FunctionComponent<ToolbarProps> = (props) => {
  const [showFontFormatting, setShowFontFormatting] = useState(false);

  const FontFormattingSub = () => {
    return (
      <>
        {/* <div style={{ float: "left" }}>
          <label>Font</label>
          <select className="ql-font"></select>
        </div> */}
        <div style={{ float: "left" }}>
          <label>Size</label>
          <select className="ql-size"></select>
        </div>
        {/* <div style={{ float: "left" }}>
          <label>Text Color</label>
          <select className="ql-color"></select>
        </div> */}
      </>
    );
  };

  // https://quilljs.com/docs/modules/toolbar/
  // https://quilljs.com/docs/formats/
  return (
    <QuillToolbar id={props.toolbarId}>
      <QuillToolbarMain>
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button
          className="ql-custom-font-formatting"
          onClick={() => setShowFontFormatting(!showFontFormatting)}
        />
        <button className="ql-align" value="" />
        <button className="ql-align" value="center" />
        <button className="ql-align" value="right" />
        {!props.formatWholeText && (
          <>
            <button className="ql-list" value="bullet" />
            <button className="ql-link" />
          </>
        )}
      </QuillToolbarMain>
      <QuillToolbarSub
        className="sub-font-formatting"
        style={{ display: showFontFormatting ? "block" : "none" }}
      >
        {FontFormattingSub()}
      </QuillToolbarSub>
    </QuillToolbar>
  );
};

type EditorProps = {
  toolbarIdCSSSelector: string;
  isEditable: boolean;
  initialValue?: string;
  isEditorFocused: boolean;
  formatWholeText: boolean;
  onFocus: () => void;
  onBlur: (text: string) => void;
};

const Editor: FunctionComponent<EditorProps> = (props) => {
  const [showFontFormatting, setShowFontFormatting] = useState(false);

  const triggerCustomFontFormatting = () => {
    const fontFormattingButton = document.querySelector(
      `${props.toolbarIdCSSSelector} .ql-custom-font-formatting`
    );
    const subFontFormatting: HTMLElement = document.querySelector(
      `${props.toolbarIdCSSSelector} .sub-font-formatting`
    );
    const buttonClassList = fontFormattingButton.classList;

    if (showFontFormatting) {
      if (!buttonClassList.contains("active")) {
        buttonClassList.add("active");
      }
      subFontFormatting.style.display = "block";
    } else {
      if (buttonClassList.contains("active")) {
        buttonClassList.remove("active");
      }
      subFontFormatting.style.display = "none";
    }
  };

  // important. Should be aligned with the Toolbar component
  const toolbarFormats = [
    "bold",
    "italic",
    "underline",
    "font",
    "size",
    "color",
    "align",
    "list",
    "link",
  ];

  // https://github.com/gtgalone/react-quilljs/issues/8
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      toolbar: {
        container: props.toolbarIdCSSSelector,
      },
    },
    formats: toolbarFormats,
    clipboard: {
      matchVisual: false,
    },
    readOnly: props.formatWholeText,
  });

  if (Quill && !quill) {
    // https://github.com/quilljs/quill/issues/1099#issuecomment-258560326
    const icons = Quill.import("ui/icons");
    toolbarFormats.forEach((format) => {
      icons[format] = `<i class="toolbar-icon quill-${format}-button"></i>`;
    });
    icons["custom-font-formatting"] =
      '<i class="toolbar-icon quill-custom-font-formatting-button"></i>';
  }

  useEffect(() => {
    if (quill && !props.formatWholeText) {
      props.isEditable ? quill.enable() : quill.disable();
    }
  }, [props.isEditable]);

  const formatWholeText = (format: string) => {
    const length = quill.getLength();
    const selectedFormats = Object.keys(quill.getFormat(0, length));
    quill.formatText(0, length, format, !selectedFormats.includes(format));
  };

  const formatWholeTextWithValue = (format: string, value: string) => {
    quill.formatText(0, quill.getLength(), format, value);
  };

  const setEditorContent = () => {
    if (quill) {
      // https://github.com/zenoamaro/react-quill/issues/317
      if (props.isEditorFocused) {
        quill.clipboard.dangerouslyPasteHTML(props.initialValue);
      } else {
        quill.root.innerHTML = props.initialValue;
      }
    }
  };

  useEffect(() => {
    setEditorContent();
  }, [props.initialValue]);

  useEffect(() => {
    if (quill) {
      setEditorContent();
      props.isEditable && !props.formatWholeText
        ? quill.enable()
        : quill.disable();

      if (props.formatWholeText) {
        ["bold", "italic", "underline"].forEach((format: string) => {
          quill.getModule("toolbar").addHandler(format, () => {
            formatWholeText(format);
          });
        });
        ["font", "size", "color", "align", "list"].forEach((format: string) => {
          quill.getModule("toolbar").addHandler(format, (value) => {
            formatWholeTextWithValue(format, value);
          });
        });
      }

      quill.on("selection-change", function (range, oldRange, source) {
        if (range) {
          props.onFocus();
          const selectedFormats = Object.keys(quill.getFormat());
          setShowFontFormatting(
            selectedFormats.includes("font") ||
              selectedFormats.includes("size") ||
              selectedFormats.includes("color")
          );
        } else {
          // Cursor not in the editor
          props.onBlur(quill.root.innerHTML);
        }
      });
    }
  }, [quill]);

  useEffect(() => {
    triggerCustomFontFormatting();
  }, [showFontFormatting]);

  return <QuillEditor ref={quillRef} />;
};

export type UseRichTextEditorOptions = {
  isEditable: boolean;
  initialValue?: string;
  isEditorFocused?: boolean;
  formatWholeText?: boolean;
  onFocus: () => void;
  onBlur: (text: string) => void;
};

export const useRichTextEditor = (options: UseRichTextEditorOptions) => {
  const [toolbarId, toolbarIdCSSSelector] = generateQuillToolbarId();

  return [
    <Editor
      toolbarIdCSSSelector={toolbarIdCSSSelector}
      isEditable={options.isEditable}
      initialValue={options.initialValue}
      isEditorFocused={
        options.isEditorFocused === undefined ? false : options.isEditorFocused
      }
      formatWholeText={
        options.formatWholeText === undefined ? false : options.formatWholeText
      }
      onFocus={options.onFocus}
      onBlur={options.onBlur}
    />,
    <Toolbar
      toolbarId={toolbarId}
      formatWholeText={
        options.formatWholeText === undefined ? false : options.formatWholeText
      }
    />,
  ];
};
