import React, { FunctionComponent as FC } from "react";
import { ThemeProvider } from "styled-components";
import { gusTheme } from "@components/Theme";
import P, { IP } from "@components/atoms/Typography/Text";
import { $Input, $TextArea } from "./Input.sc";

export interface IInputProps {
  type?: string;
  name?: string;
  placeholder?: string;
  errors?: string;
  label?: string;
  labelTheme?: "light" | "regular";
  register?: any;
  shape?: string;
  defaultValue?: string;
  id?: any;
  value?: any;
  disabled?: boolean;
  testId?: string;
  onChange?: (data) => void;
  onBlur?: () => void;
}

const errorFields = (value) => {
  return (
    <P size="4" color="darkError" margin="0 0 16px 0">
      {value && value.message}
    </P>
  );
};

const InputWLabel: FC<IInputProps> = ({
  type,
  shape = "input",
  name,
  placeholder,
  errors,
  label,
  labelTheme = "regular",
  register,
  defaultValue,
  id,
  value,
  disabled = false,
  testId,
  onChange,
  onBlur,
}) => {
  let labelProps: IP = { color: disabled ? "baseLight" : "baseDarkest", fontWeight: 600, children: label }
  switch (labelTheme) {
    case "light":
      labelProps = {
        size: "3",
        color: disabled ? "baseLight" : "baseDark",
        fontWeight: 400,
        margin: "0 0 8px 0",
        children: label,
      };
  }

  return (
    <ThemeProvider theme={gusTheme}>
      {label && <P {...labelProps} />}
      {shape == "input" && (
        <$Input
          id={id}
          defaultValue={defaultValue}
          type={type}
          name={name}
          placeholder={placeholder}
          errorMessage={!!errors}
          ref={register}
          value={value}
          disabled={disabled}
          data-testid={testId}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
      {shape == "textarea" && (
        <$TextArea
          id={id}
          name={name}
          placeholder={placeholder}
          errorMessage={!!errors}
          ref={register}
          value={value}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
      {errorFields(errors)}
    </ThemeProvider>
  );
};

export default InputWLabel;
