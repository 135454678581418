export type IAddReviewSiteType = {
  activeTarget: any;
  isTargetSelected: (categoryId, targetUuid) => boolean;
  toggleSelectedTarget: (categoryId, targetUuid, ignore?) => void;
  removeSelectedTarget: (categoryId, targetUuid) => void;
  clearActiveTarget: () => void;
  updateSelectedTarget: (newSelectedTarget: ISelectedTarget) => void;
  currentActiveTarget: (categoryId, targetUuid, linkUuid?) => void;
  addLogoError: (categoryId, targetUuid, logoError) => void;
  getLogoError: (categoryId, targetUuid) => boolean;
};

export type ISelectedTarget = {
  categoryId: string;
  targetUuid: string;
  linkUuid?: string;
};

export function wordCounting(html) {
  if (!html) return 0;

  let cont = html;
  cont = cont.replace(/<[^>]*>/g, " ");
  cont = cont.replace(/\s+/g, " ");
  cont = cont.trim();

  return cont.split(" ").length;
}

export function readableFileSize(size: number, type: "KB" | "MB" = "KB") {
  let factor = 1024;
  if (type === "MB") factor = factor * factor;
  return `${(size / factor).toFixed(2)} ${type}`;
}

export const getLink = (funnelData, categoryId, slug) => {
  let filterLinks = funnelData.backend.links_attributes.filter(
    ({ category_id, category }) =>
      category_id === categoryId && category === slug
  );
  if (filterLinks?.length > 0) {
    return filterLinks[0];
  }
  return null;
};

export const MAX_SELECTED_LINKS = 6;

export const countShowedLinks = (funnelData) => {
  let count = funnelData.backend.links_attributes.reduce(
    (total, { switch_show }) => {
      if (switch_show) total++;
      return total;
    },
    0
  );
  return count;
};

export const errorMessage = (error) => {
  if (error.response?.data) {
    return Object.keys(error.response.data)
      .map(
        (field) =>
          `${field.toUpperCase()}: ${error.response.data[field].join(",")}`
      )
      .join(",");
  }
  return "Please try again later.";
};
