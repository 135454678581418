import axios from "axios";
import { getCSRFToken } from "@utils/utils";

export interface ISubmitFormParams {
  email: string;
  message: string;
  phone: string;
  form_type: string;
  prelude: string;
  uuid: string;
  "g-recaptcha-response": string;
}

export async function submitForm(params) {
  const { data } = await axios.post(
    "/funnel/submit_form.json",
    { review: params },
    {
      headers: { "X-CSRF-TOKEN": getCSRFToken() },
    }
  );
  return data;
}
