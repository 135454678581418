import styled from "styled-components";
import { colors } from "@styles/colors";

export const $FunnelHeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  border-bottom: 2px solid ${colors.grayBorder};
  background-color: ${colors.white};
  position: sticky;
  top: 0px;
  z-index: 3;
`;

export const $FunnelURLEditor = styled.div`
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  padding: 8px 25px 0 25px;
  color: ${colors.baseCopy};
  float: left;
`;

export const $EditURLWrapper = styled.div`
  height: 42px;
  line-height: 46px;
  margin-top: 8px;
`;

export const $FunnelHeaderButton = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  float: right;
  height: 100%;
  padding-right: 16px;
  cursor: pointer;
`;

export const $FunnelHeaderPillButtonContainer = styled.div`
  float: right;
  height: 100%;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const $BaseURLText = styled.p`
  display: inline-block;
  float: left;
`;

export const ShortNameInputMaxCharacter = 36;
export const $ShortNameInput = styled.input`
  border: none;
  height: 36px;
  color: ${colors.baseLight};
  text-overflow: ellipsis;
  :hover,
  :focus,
  :active {
    cursor: pointer;
  }
`;

export const $PreviewLink = styled.a`
  color: ${colors.primary2};
  display: inline-block;
  float: left;
  overflow-y: auto;
  white-space: nowrap;
`;
