import styled from "styled-components";
import { colors } from "@styles/colors";
import "@fontsource/poppins/700.css";
import { lighten } from "polished";

export const $DirectFeedbackFormActions = styled.div<{ color?: string }>`
  display: flex;
  justify-content: center;
  margin: 24px 0;
  button.direct-feedback-form-button {
    ${({ color }) =>
      color &&
      color !== "#006DFF" &&
      `background-color: ${color};
    &:hover {
      background-color: ${lighten(0.1, color)};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.color.baseLight};
      cursor: not-allowed;
    }`}
  }
`;

export const $ToggleVisibilityButton = styled.span<{
  top?: string;
}>`
  position: absolute;
  right: 10px;
  ${({ top }) => top && `top: ${top}px;`}
`;

export const $DirectFeedbackFormErrors = styled.div`
  color: ${({ theme }) => theme.colors["darkError"]};
  font-weight: 400;
  margin: 0;
  width: fit-content;
  line-height: 150%;
  font-family: Open Sans;
  font-size: 12px;
`;

export const $FeedbackFormModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .close-button {
    position: absolute;
    top: 32px;
    right: 34px;
    cursor: pointer;
  }

  .header-copy {
    color: ${colors.catalinaBlue};
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
  }

  .content-copy {
    color: ${colors.baseCopy};
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 0px 0px 0px;
  }
`;
