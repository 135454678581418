import React from "react";
import Modal from "react-modal";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "../../../styles/react-tabs.css";
import "../../../styles/reactphoneinput.css";

import SendFunnelTestForm from "../SendFunnelTestForm";
import { ModalTemplate } from "@molecules/Modal";

import { $ModalBodyTabPanelContainer } from "./SendFunnelTestModal.sc";

export interface ISendFunnelTestModal {
  siteUuid: string;
  pageUuid: string;
  isWhiteLabeledUser: boolean;
  email: boolean;
  sms: boolean;
  onClose: () => void;
}

Modal.setAppElement("body");

const SendFunnelTestModal: React.FC<ISendFunnelTestModal> = ({
  siteUuid,
  pageUuid,
  isWhiteLabeledUser,
  email,
  sms,
  onClose,
}) => {
  return (
    <ModalTemplate header="Send a Test" width="480px" onClose={onClose}>
      {email && sms ? (
        <Tabs data-testid="funnel-test-tabs">
          <TabList>
            <Tab>Email</Tab>
            <Tab>Text</Tab>
          </TabList>

          <$ModalBodyTabPanelContainer>
            <TabPanel>
              <SendFunnelTestForm
                mode="email"
                siteUuid={siteUuid}
                pageUuid={pageUuid}
                isWhiteLabeledUser={isWhiteLabeledUser}
                onSuccessfulSubmit={onClose}
              />
            </TabPanel>
            <TabPanel>
              <SendFunnelTestForm
                mode="sms"
                siteUuid={siteUuid}
                pageUuid={pageUuid}
                isWhiteLabeledUser={isWhiteLabeledUser}
                onSuccessfulSubmit={onClose}
              />
            </TabPanel>
          </$ModalBodyTabPanelContainer>
        </Tabs>
      ) : (
        <$ModalBodyTabPanelContainer>
          <SendFunnelTestForm
            mode={email ? "email" : "sms"}
            siteUuid={siteUuid}
            pageUuid={pageUuid}
            isWhiteLabeledUser={isWhiteLabeledUser}
            onSuccessfulSubmit={onClose}
          />
        </$ModalBodyTabPanelContainer>
      )}
    </ModalTemplate>
  );
};

export default SendFunnelTestModal;
