import styled from "styled-components";
import { colors } from "../../../styles/colors";
import AlertSVG from "../../../views/builder/sites/assets/Alert.svg";

interface IInput {
  errorMessage?: boolean;
  img?: string;
  width?: string;
  disabled?: boolean;
};

export const $Input = styled.input<IInput>`
  box-sizing: border-box;
  width: ${({ width }) => (width ? width : "100%")};
  height: 42px;
  border: 1px solid
    ${({ errorMessage }) => (errorMessage ? colors.darkError : colors.grayBorder)};
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  color: ${colors.baseDarkest};
  background-image: ${({ errorMessage }) => errorMessage ? `url(${AlertSVG})` : ""};
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  ${({ disabled = false }) => disabled ? (`
    outline: none;
    border: 2px solid ${colors.grayBorder};
    background-color: rgba(230, 236, 240, 0.2);
  `) : (`
    :focus {
      outline-color: ${({ errorMessage }) => errorMessage ? colors.darkError : colors.primaryBase};
      border: 2px solid ${({ errorMessage }) => (errorMessage ? colors.darkError : colors.grayBorder)};
      background-color: ${colors.baseWhite};
    }
    :hover {
      border-color: ${colors.primaryBase};
    }
  `)}

  &::-webkit-input-placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    padding-left: 8px;
    color: ${({ disabled = false }) => disabled ? colors.alto : colors.baseLight};
    ${({ disabled = false }) => disabled && `opacity: 0.5` }
  }
`;

export const $TextArea = styled.textarea<IInput>`
  width: 100%;
  box-sizing: border-box;
  height: 144px;
  resize: none;
  border: 1px solid
    ${({ errorMessage }) => (errorMessage ? colors.darkError : colors.grayBorder)};
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  color: ${colors.gray};
  background-image: ${({ errorMessage }) => errorMessage ? `url(${AlertSVG})` : ""};
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  ${({ disabled = false }) => disabled ? (`
    outline: none;
    border: 2px solid ${colors.grayBorder};
    background-color: rgba(230, 236, 240, 0.2);
  `) : (`
    :focus {
      outline-color: ${({ errorMessage }) => errorMessage ? colors.darkError : colors.primaryBase};
      border: 2px solid ${({ errorMessage }) => (errorMessage ? colors.darkError : colors.grayBorder)};
      background-color: ${colors.baseWhite};
      color: ${colors.gray};
    }
    :hover {
      border-color: ${colors.primaryBase};
    }
  `)}
  &::-webkit-input-placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    padding-left: 8px;
    color: ${({ disabled = false }) => disabled ? colors.alto : colors.baseLight};
    ${({ disabled = false }) => disabled && `opacity: 0.5` }
  }
`;
