import React, { FunctionComponent as FC, useContext, useEffect } from "react";
import { FunnelPageType } from "@components/funnel/builder/MainContainer";
import Radio from "@components/atoms/Radio";
import {
  $FunnelThresholdColumn,
  $FunnelThresholdContainer,
  $FunnelThresholdGroup,
} from "./FunnelThreshold.sc";
import {
  chooseThresholdLabel,
  handleOnClick,
  isPositiveReview,
  loadDefaultValues,
  thresholdChecked,
} from "./utils";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";

export const DEFAULT_POSITIVE_THRESHOLD = "10";
export const DEFAULT_NEGATIVE_THRESHOLD = "2";
export const DEFAULT_SEGMENTING_THRESHOLD = 2.0;
const THRESHOLD_UPDATES = {
  "10": "2",
  "2": "10",
  "0": "null",
  "null": "0",
};

export interface IThumbsFunnelThresholdProps {
  type: FunnelPageType;
  disabled?: boolean;
}

const thumbThresholdName = (type: FunnelPageType) =>
  `${isPositiveReview(type) ? "positive" : "negative"}ThumbThreshold`;

const ThumbsFunnelThreshold: FC<IThumbsFunnelThresholdProps> = ({
  type,
  disabled,
}) => {
  const thresholdName = thumbThresholdName(type);
  const { funnelData, updateBackend } = useContext(FunnelContext);

  useEffect(() => {
    loadDefaultValues(
      type,
      funnelData,
      updateBackend,
      DEFAULT_POSITIVE_THRESHOLD,
      DEFAULT_NEGATIVE_THRESHOLD,
      DEFAULT_SEGMENTING_THRESHOLD
    );
  }, []);

  const onClick = (thresholdValue, segmentThreshold) => {
    handleOnClick(
      thresholdValue,
      segmentThreshold,
      type,
      funnelData,
      updateBackend,
      THRESHOLD_UPDATES
    );
  };

  return (
    <$FunnelThresholdContainer>
      <div>{chooseThresholdLabel(type)}</div>
      <$FunnelThresholdGroup
        key={`thumbs-funnel-threshold-group-${type}`}
        disabled={disabled}
      >
        <$FunnelThresholdColumn
          key={`thumbs-funnel-threshold-column-${type}-1`}
        >
          <Radio
            key={`thumbs-radio-${type}-1`}
            testId={`thumbs-radio-${type}-1`}
            name={thresholdName}
            size="20"
            labelSize="13"
            value={DEFAULT_POSITIVE_THRESHOLD}
            label="Thumbs Up"
            checked={thresholdChecked(
              type,
              funnelData,
              DEFAULT_POSITIVE_THRESHOLD
            )}
            onClick={() => onClick(DEFAULT_POSITIVE_THRESHOLD, isPositiveReview(type) ? 2 : 10)}
          />
        </$FunnelThresholdColumn>
        <$FunnelThresholdColumn
          key={`thumbs-funnel-threshold-column-${type}-2`}
        >
          <Radio
            key={`thumbs-radio-${type}-2`}
            testId={`thumbs-radio-${type}-2`}
            name={thresholdName}
            size="20"
            labelSize="13"
            value={DEFAULT_NEGATIVE_THRESHOLD}
            label="Thumbs Down"
            checked={thresholdChecked(
              type,
              funnelData,
              DEFAULT_NEGATIVE_THRESHOLD
            )}
            onClick={() => onClick(DEFAULT_NEGATIVE_THRESHOLD, isPositiveReview(type) ? 0 : 2)}
          />
        </$FunnelThresholdColumn>
        <$FunnelThresholdColumn
          key={`thumbs-funnel-threshold-column-${type}-3`}
        >
          <Radio
            key={`thumbs-radio-${type}-3`}
            testId={`thumbs-radio-${type}-3`}
            name={thresholdName}
            size="20"
            labelSize="13"
            value={"0"}
            label="Up or Down"
            checked={thresholdChecked(
              type,
              funnelData,
              "0"
            )}
            onClick={() => onClick("0", isPositiveReview(type) ? 0 : 10)}
          />
        </$FunnelThresholdColumn>
        <$FunnelThresholdColumn
          key={`thumbs-funnel-threshold-column-${type}-4`}
        >
          <Radio
            key={`thumbs-radio-${type}-4`}
            testId={`thumbs-radio-${type}-4`}
            name={thresholdName}
            size="20"
            labelSize="13"
            value={"null"}
            label="No Thumbs"
            checked={thresholdChecked(
              type,
              funnelData,
              "null"
            )}
            onClick={() => onClick("null", isPositiveReview(type) ? 10 : 0)}
          />
        </$FunnelThresholdColumn>
      </$FunnelThresholdGroup>
    </$FunnelThresholdContainer>
  );
};

export default ThumbsFunnelThreshold;
