import styled from "styled-components";
import { colors } from "@styles/colors";

export const $Banner = styled.p`
  background-color: rgba(252, 238, 96, 0.6);
  padding: 1.5rem 2rem;
  border-radius: 16px;
  font-weight: 400;
`

export const $ParentContainer = styled.div`
  height: 100%;
  width: 100%;
  font-family: "Open Sans";
  background-color: ${colors.white};
  overflow: hidden;
`;

export const $LeftSection = styled.div`
  height: 100vh;
  float: left;
  min-width: 450px;
  width: 30%;
  border-right: 2px solid ${colors.grayBorder};
  overflow-y: scroll;
  overflow-x: hidden;
`;

interface RightSectionProps {
  previewMode: boolean;
  backgroundColor?: string;
  mobileMode: boolean;
}

export const $RightSection = styled.div<RightSectionProps>`
  height: 100vh;
  background-color: ${({ mobileMode, backgroundColor, theme }) =>
    mobileMode
      ? theme.colors.baseWhite
      : backgroundColor || theme.colors.baseLightest};
  overflow-y: scroll;
`;
