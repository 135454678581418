import React, { FunctionComponent as FC, useState, useContext } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";

import P from "@atoms/Typography/Text";
import Button from "@traject/reviewspod.molecules.button";
import Input from "@molecules/Input";

import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { useMutation } from "react-query";
import {
  ImutationParams,
  ISiteParams,
  updateSite,
} from "@components/funnel/hooks/useSiteUpdate";
import SettingCard from "@molecules/SettingCard";

const BusinessShortName: FC = () => {
  const [editing, setEditing] = useState(false);
  const [updatingShortName, setUpdatingShortName] = useState(false);
  const alert = useAlert();
  const { funnelData, updateFrontend } = useContext(FunnelContext);

  const { handleSubmit, register, errors } = useForm({
    mode: "onChange",
  });

  const mutation = useMutation((params: ImutationParams) => updateSite(params));

  const submitSiteUpdates = async (params: ISiteParams) => {
    mutation.mutate(
      {
        id: funnelData.backend.site_attributes.id,
        site_uuid: funnelData.backend.site_attributes.uuid,
        page_id: funnelData.backend.page_attributes.id,
        site: params,
      },
      {
        onSuccess: (responseData) => {
          setEditing(false);
          setUpdatingShortName(false);
          funnelData.backend = responseData;
          updateFrontend();
          alert.success(<p>Save was successful</p>);
        },
        onError: () => {
          setUpdatingShortName(false);
          alert.error(
            <>
              <h3>Your shortname could not be changed</h3>
              <p>Please enter an available shortname.</p>
            </>
          );
        },
      }
    );
  };

  return (
    <SettingCard
      onClick={() => {
        setEditing(true);
      }}
      onOutsideClick={() => {
        setEditing(false);
      }}
    >
      <P size="3" color="baseDark" fontWeight={600}>
        SHORT NAME
      </P>
      {editing ? (
        <form onSubmit={handleSubmit(submitSiteUpdates)}>
          <Input
            name="slug"
            register={register()}
            errors={errors.slug}
            defaultValue={funnelData.backend.site_attributes.slug}
          />
          <Button
            color="primary"
            onClick={() => setUpdatingShortName(true)}
            type="submit"
            leftIcon={updatingShortName ? "spinner" : null}
          >
            Save
          </Button>
        </form>
      ) : (
        funnelData.backend.site_attributes.slug && (
          <P size="2" color="baseDarkest" margin="8px 0px 0px 0px">
            {funnelData.backend.site_attributes.slug}
          </P>
        )
      )}
    </SettingCard>
  );
};

export default BusinessShortName;
