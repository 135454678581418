import * as React from "react";
import { FunctionComponent as FC } from "react";
import CheckMarkCircle from "@app/javascript/assets/icons/checkmark/checkmark-fill.svg";
import BackButton from "@components/elements/BackButton";
import Button from "@components/atoms/Button";
import { IConfirmationProps } from ".";
import {
  $BackButtonContainer,
  $ButtonContainer,
  $RatingLabelsContainer,
  $RatingTypesBody,
  $RatingTypesTitle,
} from "./RatingLabels.sc";

const ReviewSitesConfirm: FC<IConfirmationProps> = ({ onConfirm, backFxn }) => {
  return (
    <>
      <$BackButtonContainer>
        <BackButton text="Back to Rating Types" backFxn={() => backFxn()} />
      </$BackButtonContainer>
      <$RatingLabelsContainer>
        <$RatingTypesTitle>Review Sites</$RatingTypesTitle>
        <$RatingTypesBody>
          You can add up to 6 sites on the landing page.
          <br />
          <br />
          By choosing this option, the landing page displays your public review
          sites. The direct feedback page will be disabled.
        </$RatingTypesBody>
        <$ButtonContainer>
          <Button onClick={() => onConfirm({})} leftIcon={CheckMarkCircle}>
            Confirm
          </Button>
        </$ButtonContainer>
      </$RatingLabelsContainer>
    </>
  );
};

export default ReviewSitesConfirm;
