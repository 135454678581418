export const phoneInputStyle = {
  paddingLeft: "80px",
  height: "48px",
  width: "inherit",
  border: "2px solid #E6ECF1",
  borderRadius: "4px",
  color: "#939393",
  fontSize: "18px",
};

export const Dropdown = {
  width: "400px",
  backgroundColor: "#FFFFFF",
  fontSize: "18px",
};

export const Flag = {
  paddingLeft: "10px",
  backgroundColor: "#FFFFFF",
  justifyContent: "center",
  border: "2px solid #E6ECF1",
  borderRadius: "4px",
  color: "#939393",
  width: "75px",
};
