import styled from "styled-components";

type IFunnelThresholdProps = {
  disabled?: boolean;
}

export const $FunnelThresholdContainer = styled.div`
`;

export const $FunnelThresholdGroup = styled.div<IFunnelThresholdProps>`
  padding-top: 12.5px;
  font-family: "Open Sans";
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  ${(props) => (props.disabled ? "cursor: not-allowed;" : "cursor: pointer;")}
`;

export const $FunnelThresholdColumn = styled.div`
  display: flex;
  width: 50%;
  padding-bottom: 6px;
`;
