import styled from "styled-components";

export const $CustomDomainContainer = styled.div`
  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  .clear-float {
    clear: both;
  }

  label[for=site_domain_is_shared] {
    font-size: 14px;
  }
`;

export const $DomainToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const $Form = styled.form`
  margin-top: 16px;
`;

export const $RadioOptionsContainer = styled.div`
  overflow: hidden;
`;

export const $DomainInputContainer = styled.div`
  margin-top: 16px;
`;
