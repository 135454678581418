import styled from "styled-components";

export const $RatingTypesContainer = styled.div`
  position: relative;
  width: 340px;
  margin: 0px;
  height: 703px;
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-height: 1050px) {
    height: 100%;
  }
`;

export const $RatingTypesBody = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.colors["baseCopy"]};
  margin-bottom: 24px;
`;

export const $RatingTypesTitle = styled.div`
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.colors["darkBlue"]};
  margin: 0px 0px 24px 0px;
`;

export const $RatingTypesPanel = styled.div`
  padding-bottom: 24px;
`;

export const $ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
