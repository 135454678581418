import React, { FunctionComponent as FC, useState, useContext } from "react";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import BaseSelect, { SelectedProps } from "@components/base/selects/BaseSelect";

import P from "@components/atoms/Typography/Text";
import Button from "@traject/reviewspod.molecules.button";

import { useAlert } from "react-alert";
import { useMutation } from "react-query";
import {
  IPageUpdateParams,
  updatePage,
} from "@components/funnel/hooks/usePageUpdate";
import SettingCard from "@molecules/SettingCard";

const TimeZoneSettings: FC = () => {
  const [editing, setEditing] = useState<boolean>(false);
  const [updatingTimeZone, setUpdatingTimeZone] = useState<boolean>(false);
  const alert = useAlert();
  const { funnelData, updateFrontend } = useContext(FunnelContext);
  const [selectedTimeZone, setSelectedTimeZone] = useState<SelectedProps>({
    value: funnelData.backend.page_attributes.share_timezone,
    label: funnelData.backend.page_attributes.share_timezone,
  });

  const timeZoneOptions =
    funnelData.backend.page_attributes.timezone_options.map(
      (timezone: string) => {
        return { value: timezone, label: timezone };
      }
    );

  const mutation = useMutation((params: IPageUpdateParams) =>
    updatePage(params)
  );

  const submitPageUpdates = async () => {
    mutation.mutate(
      {
        site_uuid: funnelData.backend.site_attributes.uuid,
        page_uuid: funnelData.backend.page_attributes.uuid,
        funnel_data: {
          share_timezone: selectedTimeZone.value,
        },
      },
      {
        onSuccess: (responseData) => {
          setEditing(false);
          setUpdatingTimeZone(false);
          funnelData.backend = responseData;
          updateFrontend();
          alert.success(<p>Save was successful</p>);
        },
        onError: () => {
          setUpdatingTimeZone(false);
          alert.error(
            <>
              <h3>Something went wrong.</h3>
              <p>Please try again later.</p>
            </>
          );
        },
      }
    );
  };

  return (
    <SettingCard
      onClick={() => setEditing(true)}
      onOutsideClick={() => setEditing(false)}
    >
      {editing ? (
        <>
          <BaseSelect
            name="share_timezone"
            value={selectedTimeZone}
            options={timeZoneOptions}
            onChange={(option: SelectedProps) => {
              setSelectedTimeZone({ value: option.value, label: option.label });
            }}
            placeholder="Time Zone"
          />
          <br />
          <Button
            color="primary"
            onClick={submitPageUpdates}
            leftIcon={updatingTimeZone ? "spinner" : null}
          >
            Save
          </Button>
        </>
      ) : (
        <P size="2" color="baseDarkest">
          {selectedTimeZone.label}
        </P>
      )}
    </SettingCard>
  );
};

export default TimeZoneSettings;
