import * as React from "react";
import { FunctionComponent as FC, useContext } from "react";
import CheckMarkCircle from "@app/javascript/assets/icons/checkmark/checkmark-fill.svg";
import BackButton from "@components/elements/BackButton";
import Button from "@components/atoms/Button";
import { IConfirmationProps } from ".";
import {
  $BackButtonContainer,
  $RatingLabelsContainer,
  $InputWrapper,
  $LabelText,
  $RatingTypesTitle,
  $RatingTypesBody,
  $ButtonContainer,
  $RatingTypesFormWrapper,
  $StarsConfirmWrapper,
} from "./RatingLabels.sc";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { BaseInput } from "@styles/components/elements/Inputs";
import { useForm } from "react-hook-form";

import { IStarsConfirm, starInputs, errorFields } from "./utils";

const StarsConfirm: FC<IConfirmationProps> = ({ onConfirm, backFxn }) => {
  const { funnelData } = useContext(FunnelContext);
  const { handleSubmit, register, errors } = useForm<IStarsConfirm>({
    mode: "onChange",
  });

  const onSubmit = (data: IStarsConfirm) => {
    onConfirm(data);
  };

  const defaultValues = (name, holder) => {
    if (funnelData.backend.config_modules_attributes.template == "stars") {
      return funnelData.backend.content_modules_attributes[name];
    } else {
      return holder;
    }
  };

  return (
    <>
      <$BackButtonContainer>
        <BackButton text="Back to Rating Types" backFxn={() => backFxn()} />
      </$BackButtonContainer>
      <$RatingLabelsContainer>
        <$RatingTypesTitle>Star Labels</$RatingTypesTitle>
        <$RatingTypesBody>
          Your customers see this when hovering over or clicking on the star.
        </$RatingTypesBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <$RatingTypesFormWrapper>
            <$StarsConfirmWrapper>
              {starInputs.map(({ label, name, holder }) => {
                return (
                  <$InputWrapper key={name}>
                    <$LabelText>{label}</$LabelText>
                    <BaseInput
                      name={name}
                      type="text"
                      defaultValue={defaultValues(name, holder)}
                      placeholder={holder}
                      errorMessage={!!errors[name]}
                      ref={register({
                        required: "Description Required",
                        maxLength: {
                          value: 26,
                          message: "Cannot exceed 26 characters",
                        },
                      })}
                    />
                    {errorFields(errors[name])}
                  </$InputWrapper>
                );
              })}
            </$StarsConfirmWrapper>
          </$RatingTypesFormWrapper>
          <$ButtonContainer>
            <Button onClick={() => onSubmit} leftIcon={CheckMarkCircle}>
              Confirm
            </Button>
          </$ButtonContainer>
        </form>
      </$RatingLabelsContainer>
    </>
  );
};

export default StarsConfirm;
