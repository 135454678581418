import styled from "styled-components";

export const $CloseButton = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
  width: 24px;
  height: 24px;
  background-position: 50%;
  background-size: 24px;
  cursor: pointer;
`;
