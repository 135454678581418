import * as React from "react";
import { FunctionComponent as FC, useContext } from "react";
import CheckMarkCircle from "@app/javascript/assets/icons/checkmark/checkmark-fill.svg";
import BackButton from "@components/elements/BackButton";
import Button from "@components/atoms/Button";
import { IConfirmationProps } from ".";
import {
  $BackButtonContainer,
  $ButtonContainer,
  $InputWrapper,
  $LabelText,
  $RatingLabelsContainer,
  $RatingTypesBody,
  $RatingTypesFormWrapper,
  $RatingTypesTitle,
} from "./RatingLabels.sc";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { BaseInput } from "@styles/components/elements/Inputs";
import { useForm } from "react-hook-form";
import { thumbInputs, IThumbsConfirm, errorFields } from "./utils";

const ThumbsConfirm: FC<IConfirmationProps> = ({ onConfirm, backFxn }) => {
  const { funnelData } = useContext(FunnelContext);
  const { handleSubmit, register, errors } = useForm<IThumbsConfirm>({
    mode: "onChange",
  });

  const onSubmit = (data: IThumbsConfirm) => {
    onConfirm(data);
  };
  return (
    <>
      <$BackButtonContainer>
        <BackButton text="Back to Rating Types" backFxn={() => backFxn()} />
      </$BackButtonContainer>
      <$RatingLabelsContainer>
        <$RatingTypesTitle>Thumb Labels</$RatingTypesTitle>
        <$RatingTypesBody>
          Your customer sees this when hovering over or clicking on the thumb.
        </$RatingTypesBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <$RatingTypesFormWrapper>
            {thumbInputs.map(({ label, name, holder }) => {
              return (
                <$InputWrapper key={name}>
                  <$LabelText>{label}</$LabelText>
                  <BaseInput
                    name={name}
                    type="text"
                    defaultValue={
                      funnelData.backend.content_modules_attributes[name]
                    }
                    placeholder={holder}
                    errorMessage={!!errors[name]}
                    ref={register({
                      required: "Description Required",
                      maxLength: {
                        value: 26,
                        message: "Cannot exceed 26 characters",
                      },
                    })}
                  />
                  {errorFields(errors[name])}
                </$InputWrapper>
              );
            })}
          </$RatingTypesFormWrapper>
          <$ButtonContainer>
            <Button onClick={() => onSubmit} leftIcon={CheckMarkCircle}>
              Confirm
            </Button>
          </$ButtonContainer>
        </form>
      </$RatingLabelsContainer>
    </>
  );
};

export default ThumbsConfirm;
