import React, { FunctionComponent } from "react";
import { GrayBodyCopy } from "@styles/components/elements/Paragraphs";
import leftArrowBlue from "../../../assets/icons/left-arrow-blue.svg";

export interface IButtonProps {
  text: string;
  backFxn: () => void;
}
const BackButton: FunctionComponent<IButtonProps> = ({ text, backFxn }) => {
  return (
    <a
      style={{ display: "flex", marginBottom: "10px", textDecoration: "none" }}
      onClick={backFxn}
      data-testid="back-button"
    >
      <img src={leftArrowBlue} alt="Back Arrow" />
      <GrayBodyCopy style={{ fontSize: "14px", margin: "0 0 0 8px" }}>
        {text}
      </GrayBodyCopy>
    </a>
  );
};

export default BackButton;
