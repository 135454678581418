import { useMutation } from "react-query";
import axios from "axios";
export interface IPageUpdateParams {
  site_uuid: string;
  page_uuid: string;
}

const getConvertMultiLocation = async (params: IPageUpdateParams) => {
  const { data } = await axios.get(
    `/builder/pages/${params.page_uuid}/regress`,
    { params: params }
  );
  return data;
};

export default function useConvertMultiLocation(pageUUID) {
  return useMutation("page", getConvertMultiLocation);
}
