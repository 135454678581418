import styled from "styled-components";

export const $CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;
export const $ReviewFirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 30px auto auto;
`;
