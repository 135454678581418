import React, { FunctionComponent as FC } from "react";
import {
  $CheckboxPanelContainer,
  $CheckboxPanelCheckbox,
  $CheckboxPanelText,
} from "./CheckboxPanel.sc";

export type CheckboxType = {
  title: string;
  icon: any;
  value: string;
};

export interface ICheckboxPanelProps {
  selectedValue: string;
  data: Array<CheckboxType>;
  onSelectionChange: (value: string) => void;
}

const CheckboxPanel: FC<ICheckboxPanelProps> = (props) => {
  const renderCheckBoxes = () => {
    return props.data.map((check, index) => {
      return (
        <$CheckboxPanelCheckbox
          key={check.value}
          isChecked={check.value == props.selectedValue}
          icon={check.icon}
          onClick={() => props.onSelectionChange(check.value)}
        >
          <$CheckboxPanelText>{check.title}</$CheckboxPanelText>
        </$CheckboxPanelCheckbox>
      );
    });
  };

  return (
    <$CheckboxPanelContainer>{renderCheckBoxes()}</$CheckboxPanelContainer>
  );
};

export default CheckboxPanel;
