import styled from "styled-components";
import { colors } from "@styles/colors";

type AlertProps = {
  type?: string;
};

export const $AlertContainer = styled.div<AlertProps>`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 8px 16px rgba(48, 49, 51, 0.1);
  background-color: ${colors.white};
  border: 2px solid
    ${(props) =>
      (props.type && props.type == "error" && colors.darkError) ||
      (props.type == "success" && colors.darkSuccess) ||
      (props.type == "info" && colors.darkInfo)};
`;

export const $MessageContainer = styled.div`
display: flex;
flex-direction: column;
padding 0 16px;`;

export const NotificationAlertStyles = {
  content: {
    bottom: "10%",
    left: "50%",
    right: "auto",
    top: "auto",
    transform: "translate(-50%, -50%)",
    width: "350px",
    height: "350px",
    overflow: "hidden",
    padding: "0px",
    borderRadius: "20px",
    background: "#FFFFFF",
  },
};
