import React, { FunctionComponent as FC, useContext } from "react";
import { FunnelContext } from "../FunnelContext";
import ProfileLogo from "../ProfileLogo";
import LandingPageTitle from "../LandingPageTitle";
import ReviewSiteList from "../ReviewSiteList";
import { CardContainer } from "@components/atoms/CardContainer";
import Button from "@traject/reviewspod.molecules.button";
import P from "@traject/reviewspod.atoms.typography.text";
import HorizontalBreak from "@components/atoms/HorizontalBreak";
import { $FeedbackContainer } from "../PositiveReviewPage/PositiveReviewPage.sc";
import { FunnelPageType } from "../MainContainer";
import Theme from "@traject/reviewspod.base_ui.theme";
import { $FunnelPageFooter } from "../LandingPage/LandingPage.sc";
import FunnelBodyCopy from "../FunnelBodyCopy";

const NegativeReviewPage: FC = () => {
  const { funnelData, updateFrontend } = useContext(FunnelContext);
  const { innerWidth: width, innerHeight: height } = window;
  const mobile = funnelData.frontend.mobileMode || width < 600;
  function handleClick() {
    funnelData.frontend.activeFunnelPage = FunnelPageType.DirectFeedback;
    updateFrontend();
  }
  return (
    <Theme theme="gus">
      <div data-testid="negative-review-page" className="negative-review-page">
        <ProfileLogo />
        <div style={{ height: "16px" }} />
        <LandingPageTitle />
        <CardContainer padding={mobile ? "24px 5px" : "64px"}>
          <$FeedbackContainer
            className="feedback-container"
            color={funnelData.backend.config_modules_attributes.button_color}
          >
            <FunnelBodyCopy contentKey="negative_feedback_body" />
            <Button
              className="feedback-button"
              onClick={handleClick}
              rightIcon="arrowRight"
              color="primary"
            >
              <P size="3" color="white" fontWeight={700}>Share your feedback with us</P>
            </Button>
          </$FeedbackContainer>
        </CardContainer>
        <HorizontalBreak />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FunnelBodyCopy contentKey="negative_feedback_prompt" />
        </div>
        <ReviewSiteList />
        <$FunnelPageFooter
          className="negative-feedback-footer"
          mobileMode={funnelData.frontend.mobileMode}
          dangerouslySetInnerHTML={{
            __html: funnelData.backend.site_attributes.seal,
          }}
        />
      </div>
    </Theme>
  );
};

export default NegativeReviewPage;
