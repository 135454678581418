import React, { FC } from "react";
import { colors } from "@styles/colors";
import Icon from "@atoms/Icon";
import { $ToggleVisibilityButton } from "./DirectFeedbackForm.sc";

export interface IFeedbackFormToggleButtonProps {
  name: "phone" | "email";
  showInput: boolean;
  onToggleChange: (name, showInput) => void;
}

const FeedbackFormToggleButton: FC<IFeedbackFormToggleButtonProps> = ({
  name,
  showInput,
  onToggleChange,
}) => {
  const dataTip = showInput ? "Hide" : "Show";
  const iconName = showInput ? "eye" : "eyeCancel";
  const top = name === "phone" ? "32" : "113";
  return (
    <$ToggleVisibilityButton
      className="feedback-form-toggle"
      onClick={() => onToggleChange(name, !showInput)}
      data-tip={dataTip}
      data-place="top"
      data-effect="solid"
      data-background-color={colors.darkBlue}
      top={top}
    >
      <Icon size={24} icon={iconName} color="baseDark" />
    </$ToggleVisibilityButton>
  );
};

export default FeedbackFormToggleButton;
