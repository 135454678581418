import styled from "styled-components";

export const $FunnelFlowContainer = styled.div<{
  mobileMode?: boolean;
  backgroundColor?: string;
}>`
  padding: 32px 32px;
  min-height: 600px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.baseWhite};
  border-radius: 24px;
  margin-bottom: 64px;
`;

export const $FunnelContentContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;
