import React, { FunctionComponent } from "react";
import Modal from "react-modal";
import FadeIn from "react-fade-in";
import {
  ButtonsContainer,
  CancelButton,
  ConfirmButton,
} from "@styles/components/base/modals/InfoModal";
import { AlertModalStyles } from "@styles/components/base/modals/modals";
import { useDetectClickOutside } from "react-detect-click-outside";
import { $Paragraph } from "@components/elements/RoundCheckbox/RoundCheckbox.sc";

export type ModalProps = {
  isOpen: boolean;
  onAfterOpen?: () => void;
  onRequestClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  title: string;
  content: string;
  cancelBtnTitle?: string;
  confirmBtnTitle?: string;
};

export const AlertModal: FunctionComponent<ModalProps> = (props) => {
  const closeModal = () => {
    props.isOpen && props.onRequestClose && props.onRequestClose();
  };
  const ref = useDetectClickOutside({ onTriggered: closeModal });

  return (
    <div style={{ position: "absolute" }}>
      {props.isOpen ? (
        <FadeIn delay={125}>
          <Modal
            isOpen={props.isOpen}
            onAfterOpen={props.onAfterOpen}
            onRequestClose={props.onRequestClose}
            style={AlertModalStyles}
            ariaHideApp={!props.isOpen}
          >
            <div className="row" ref={ref}>
              <div className="col-md-12">
                <h3>{props.title}</h3>
                <$Paragraph
                  dangerouslySetInnerHTML={{ __html: props.content }}
                />
              </div>
              <ButtonsContainer>
                {props.cancelBtnTitle ? (
                  <CancelButton
                    className={"cancel-button"}
                    onClick={props.onCancel}
                  >
                    {props.cancelBtnTitle}
                  </CancelButton>
                ) : null}
                {props.confirmBtnTitle ? (
                  <ConfirmButton onClick={props.onConfirm}>
                    {props.confirmBtnTitle}
                  </ConfirmButton>
                ) : null}
              </ButtonsContainer>
            </div>
          </Modal>
        </FadeIn>
      ) : null}
    </div>
  );
};
