import styled from "styled-components";
import { colors } from "@styles/colors";

type ICollapsibleProps = {
  active: string;
  disabled?: boolean;
};

export const $Collapsible = styled.div`
  font-family: "Open Sans";
  margin-bottom: 8px;
`;

export const $CollapsibleButton = styled.div<ICollapsibleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  ${(props) => (props.disabled ? "cursor: not-allowed;" : "cursor: pointer;")}

  background-color: ${(props) =>
    props.disabled ? colors.darkGrayBackground : colors.grayBackground};
  padding: 16.5px 20px;

  &:hover {
    background-color: ${(props) =>
      !props.active && !props.disabled && colors.lightBlueButtonHover};
  }

  ${(props) =>
    props.active &&
    `
    {
      background-color: ${colors.blueButtonHoverPale};
    }
  `}

  .toggle-icon {
    display: flex;
    margin-left: auto;
  }
`;

export const $CollapsibleContainer = styled.div<ICollapsibleProps>`
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  background-color: ${(props) =>
    props.disabled ? colors.darkGrayBackground : colors.grayBackground};

  border-radius: 8px;
  .container-ref {
    padding: 16.5px 18px;
    border-radius: 8px;
  }
`;
