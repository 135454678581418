import styled from "styled-components";
import { colors } from "@styles/colors";

export const $FunnelBodyContainer = styled.div<{ width?: string }>`
  min-width: 200px;
  width: ${({ width }) => (width ? width : "100%")};
`;

export const $FunnelPageEmptyPlaceholder = styled.div<{
  width?: string;
}>`
  background: transparent;
  text-align: left;
  width: ${({ width }) => (width ? width : "100%")};
  cursor: pointer;
  padding: 11px;
  margin: 0 auto;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: ${(props) => props.theme.colors.baseDark};
`;
