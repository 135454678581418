import React, { FunctionComponent as FC, useContext, useState } from "react";
import { $RatingTypeTemplateContainer } from "./RatingTypeTemplates.sc";
import { isEditMode, trackAhoyEvent } from "@utils/funnel";
import EditableActionsComponent from "@components/elements/EditableActionsComponent";
import { FunnelContext } from "@components/funnel/builder/FunnelContext";
import { FunnelPageType } from "@components/funnel/builder/MainContainer";
import RatingTypesModal from "@components/funnel/ratingType/RatingTypesModal";
import RatingTypesReviewFirst from "@components/funnel/ratingType/ReviewFirst";
import RatingTypesReviewSites from "@components/funnel/ratingType/ReviewSites";
import RatingTypeNPS from "@components/funnel/ratingType/NPS";
import RatingTypeStar from "@components/funnel/ratingType/Star";
import RatingTypeThumbs from "@components/funnel/ratingType/Thumb";
import HoverTab from "@atoms/HoverTab";
import ReviewFirstForm from "@components/funnel/ratingType/ReviewFirst/ReviewFirstForm";
import Icon from "@atoms/Icon";

export enum RatingTemplatesTypes {
  STARS = "stars",
  THUMBS = "thumbs",
  NPS = "nps",
  REVIEW_FIRST = "review",
  REVIEW_SITES_LARGE = "buttons-lg",
}

export interface IRatingTypeProps {
  handleRatingClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  starRating?: number;
  setStarRating?: (data) => void;
}

const RatingTypeTemplates: FC = () => {
  const { funnelData, updateFrontend } = useContext(FunnelContext);
  const [title, setTitle] = useState<string>(null);
  const [reviewFirstStarRating, setReviewFirstStarRating] = useState(null);
  const [openRatingTypes, setOpenRatingTypes] = useState<boolean>();
  const template = funnelData.backend.site_attributes.theme_template;
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleFocus = (focused: boolean) => setIsFocused(focused);

  const handleRatingClick = (event) => {
    const { funnelFlow, frontend, backend } = funnelData;
    if (frontend.presentMode) {
      trackAhoyEvent(event);
    }

    const { target } = event;
    funnelFlow.selectedRatingString = target.getAttribute("data-track-key");
    const rating = target.getAttribute("data-rating");
    funnelFlow.selectedRatingOn10Scale = rating;

    // Edge case: binary track and segment 2.
    const trackGroup = target.getAttribute("data-track-group");
    const { segmenting_threshold } = backend.config_modules_attributes;
    if (trackGroup === "binary" && segmenting_threshold === 2) {
      if (rating === "10") {
        frontend.activeFunnelPage = FunnelPageType.PositiveReviewPage;
      } else {
        frontend.activeFunnelPage = FunnelPageType.NegativeReviewPage;
      }
      updateFrontend();
      return;
    }

    // General cases
    if (rating >= segmenting_threshold) {
      frontend.activeFunnelPage = FunnelPageType.PositiveReviewPage;
    } else {
      frontend.activeFunnelPage = FunnelPageType.NegativeReviewPage;
    }
    updateFrontend();
  };

  const renderTemplate = () => {
    switch (template) {
      case RatingTemplatesTypes.STARS:
        return <RatingTypeStar handleRatingClick={handleRatingClick} />;
      case RatingTemplatesTypes.THUMBS:
        return <RatingTypeThumbs handleRatingClick={handleRatingClick} />;
      case RatingTemplatesTypes.NPS:
        return <RatingTypeNPS handleRatingClick={handleRatingClick} />;
      case RatingTemplatesTypes.REVIEW_FIRST:
        return (
          <RatingTypesReviewFirst
            handleRatingClick={handleRatingClick}
            setStarRating={setReviewFirstStarRating}
          />
        );
      case RatingTemplatesTypes.REVIEW_SITES_LARGE:
        return <RatingTypesReviewSites />;
    }
  };

  function onEditReviewSites() {
    funnelData.frontend.openSettings = true;
    funnelData.frontend.settingsTab = 2;
    updateFrontend();
  }

  const mobileStyle = { width: "150px", padding: "10px" };

  return (
    <$RatingTypeTemplateContainer>
      <>
        <div
          onMouseOut={() => handleFocus(false)}
          onMouseOver={() => handleFocus(true)}
        >
          {template != RatingTemplatesTypes.REVIEW_SITES_LARGE ? (
            <HoverTab
              fieldName="Rating Block"
              editMode={isEditMode(funnelData)}
            >
              <EditableActionsComponent
                fullWidth={true}
                fieldName="Rating Block"
                isDisabled={!isEditMode(funnelData)}
                isFocused={isFocused}
                topChildren={renderTemplate()}
                actions={[
                  {
                    logo: (
                      <Icon icon="starFill" size={24} viewBox="0 0 24 24" />
                    ),
                    title: "Rating Type",
                    onClick: () => setOpenRatingTypes(true),
                    style: funnelData.frontend.mobileMode && mobileStyle,
                  },
                ]}
              />
            </HoverTab>
          ) : (
            <EditableActionsComponent
              fullWidth={true}
              fieldName="Rating Block"
              isDisabled={!isEditMode(funnelData)}
              isFocused={isFocused}
              topChildren={renderTemplate()}
              actions={[
                {
                  logo: <Icon icon="starFill" size={24} viewBox="0 0 24 24" />,
                  title: "Rating Type",
                  onClick: () => setOpenRatingTypes(true),
                  style: funnelData.frontend.mobileMode && mobileStyle,
                },
                {
                  logo: <Icon icon="edit" size={24} viewBox="0 0 24 24" />,
                  title: "Edit Review Sites",
                  onClick: onEditReviewSites,
                  style: funnelData.frontend.mobileMode && mobileStyle,
                },
              ]}
            />
          )}
        </div>
        {openRatingTypes && (
          <RatingTypesModal onClose={() => setOpenRatingTypes(false)} />
        )}
      </>
      {template == RatingTemplatesTypes.REVIEW_FIRST && (
        <ReviewFirstForm
          handleRatingClick={handleRatingClick}
          starRating={reviewFirstStarRating}
        />
      )}
    </$RatingTypeTemplateContainer>
  );
};

export default RatingTypeTemplates;
